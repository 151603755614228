import {
  DIALOG_DATA,
  Dialog,
  DialogModule,
  DialogRef,
} from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import {
  BehaviorSubject,
  Subject,
  catchError,
  combineLatest,
  filter,
  finalize,
  map,
  of,
  take,
  takeUntil,
  throwError,
} from 'rxjs';

import { ConfirmDialogComponent } from '../../shared/confirm-dialog.component';
import { PageComponent } from '../../shared/layout/page.component';
import { LoadingService } from '../../shared/loading/loading.service';
import { MessageDialogComponent } from '../../shared/message-dialog.component';
import { ProgressComponent } from '../../shared/progress/progress.component';
import { isDefined } from '../../util/common';
import { RiskComponent } from './risk.component';
import { Risk, RiskDialogData } from './risks.models';
import { RisksService } from './risks.service';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './risks.component.html',
  styles: [
    `
      :host {
        display: block;
        min-width: 700px !important ;
        width: 700px;
      }
    `,
  ],
  imports: [CommonModule, PageComponent, DialogModule, ProgressComponent],
})
export class RisksComponent implements OnDestroy {
  loading$ = this.loadingService.isLoading$(this);
  list$ = new BehaviorSubject<Risk[]>([]);
  indicatorId!: number | null;
  private destroy$ = new Subject<void>();

  constructor(
    public cdr: ChangeDetectorRef,
    @Inject(DIALOG_DATA)
    public data: RiskDialogData,
    public dialogRef: DialogRef<number | null>,
    private dialog: Dialog,
    private loadingService: LoadingService,
    private risksService: RisksService
  ) {
    this.loadingService.startLoading(this);

    combineLatest([data.risks$, data.indicator$, data.plan$])
      .pipe(
        takeUntil(this.destroy$),
        filter(
          ([risks, indicator, plan]) =>
            isDefined(risks) && isDefined(indicator) && isDefined(plan)
        ),
        map(([risks, indicator]) => {
          this.list$.next(risks);
          this.indicatorId = indicator.Id;
          this.loadingService.stopLoading(this);
          return risks;
        })
      )
      .subscribe();
  }
  add() {
    this.dialog
      .open(RiskComponent, {
        data: of({ IndicatorId: this.indicatorId }),
      })
      .closed.subscribe((result) => {
        if (result) {
          this.loadingService.startLoading(this);
          this.risksService
            .add$(result as Risk)
            .pipe(
              take(1),
              map((item) => {
                const currentRisks = [...this.list$.getValue(), item];
                this.list$.next(currentRisks);
              }),
              finalize(() => {
                this.loadingService.stopLoading(this);
              })
            )
            .subscribe();
        }
      });
  }

  edit(id: number) {
    this.dialog
      .open(RiskComponent, { data: this.risksService.get$(id) })
      .closed.subscribe((result) => {
        if (result) {
          this.loadingService.startLoading(this);
          this.risksService
            .update$(result as Risk)
            .pipe(
              take(1),
              map((item) => {
                const risks = this.list$.getValue();

                const index = risks.findIndex((risk) => risk.Id === item.Id);
                if (index !== -1) {
                  risks[index] = item;
                }
                this.list$.next(risks);
              }),
              finalize(() => this.loadingService.stopLoading(this))
            )
            .subscribe();
        }
      });
  }
  remove(id: number) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          text: 'Are you sure you want to delete this Risk?',
        },
      })
      .closed.subscribe((result) => {
        if (result as boolean) {
          this.loadingService.startLoading(this);
          this.risksService
            .remove$(id)
            .pipe(
              take(1),
              map(() => {
                const risks = this.list$.getValue();
                const index = risks.findIndex((risk) => risk.Id === id);
                if (index !== -1) {
                  risks.splice(index, 1);
                }
                this.list$.next(risks);
              }),
              catchError((err) => {
                this.dialog.open(MessageDialogComponent, {
                  data: {
                    title: 'Request Denied',
                    text: 'This record is in use and cannot be deleted.',
                  },
                });
                return throwError(() => new Error(`Delete failed [id=${id}]`));
              })
            )
            .pipe(finalize(() => this.loadingService.stopLoading(this)))
            .subscribe();
        }
      });
  }
  done() {
    this.dialogRef.close(this.list$.value.length);
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
