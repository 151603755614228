import { Injectable } from '@angular/core';
import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

type Handler = <T>(t: T) => boolean;
@Injectable()
export class ValidationService {
  getErrors(errors: ValidationErrors | null): string {
    if (!errors) return '';
    const messages: string[] = Object.keys(errors).map((key) => errors[key]);
    return messages.join(', ');
  }

  custom(test: Handler, msg: string, key: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!test(control)) {
        const result = { [key]: msg };
        return result;
      }
      return null;
    };
  }
  max(val: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const validationResult = Validators.max(val)(control);

      if (!validationResult) return null;

      validationResult['max'] = 'Maximum is ' + val;

      return validationResult;
    };
  }
  min(val: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const validationResult = Validators.min(val)(control);

      if (!validationResult) return null;

      validationResult['min'] = 'Minimum is ' + val;

      return validationResult;
    };
  }

  required(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const validationResult = Validators.required(control);

      if (validationResult) validationResult['required'] = 'Required';
      return validationResult;
    };
  }

  requiredTrue(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const validationResult = Validators.requiredTrue(control);
      if (validationResult) validationResult['required'] = 'Required';

      return validationResult;
    };
  }

  requiredIf(test: () => boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (test()) {
        const validationResult = Validators.required(control);
        if (validationResult) validationResult['required'] = 'Required';
        return validationResult;
      }
      return null;
    };
  }
}
