import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Observable, combineLatest, filter, map, of, take } from 'rxjs';

import {
  InputFieldComponent,
  Kvp,
  SelectFieldComponent,
  ValidationService,
} from '@pmslogic/widgets';
import { EmployeesService } from '../employees/employees.service';
import { KpasService } from '../kpas/kpas.service';
import { DictionariesService } from '../shared/dictionaries.service';
import { StrategyPickerComponent } from '../strategy-picker/strategy-picker.component';
import { ProjectInfo } from './project.models';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputFieldComponent,
    SelectFieldComponent,
    StrategyPickerComponent,
  ],
  providers: [ValidationService, KpasService],
  templateUrl: './project.component.html',
  styles: [
    `
      :host {
        display: block;
        width: 800px;
      }
    `,
  ],
})
export class ProjectComponent {
  form: FormGroup;
  kpas$ = new Observable<Kvp[]>();
  gfsFuncs$ = new Observable<Kvp[]>();
  employees$ = new Observable<Kvp[]>();

  constructor(
    public dialogRef: DialogRef<ProjectInfo | null>,
    @Inject(DIALOG_DATA) private data$: Observable<Partial<ProjectInfo>>,
    validation: ValidationService,
    dictSvc: DictionariesService,
    kpasSvc: KpasService,
    employeesSvc: EmployeesService
  ) {
    this.form = new FormGroup({
      Description: new FormControl('', validation.required()),
      DevStrategyId: new FormControl(null),
      EmployeeId: new FormControl(null, validation.required()),
      GFSFuncId: new FormControl(null),
      GrantVote: new FormControl(''),
      Id: new FormControl(null),
      KPAId: new FormControl(null, validation.required()),
      OutputMeasure: new FormControl('', validation.required()),
      ProjectNo: new FormControl(''),
      Proposer: new FormControl(''),
      Title: new FormControl('', validation.required()),
      Verification: new FormControl('', validation.required()),
      Vote: new FormControl(''),
    });

    this.kpas$ = kpasSvc.dictionary$();
    this.employees$ = employeesSvc.dictionary$();

    this.form.disable();

    combineLatest([
      this.kpas$,
      dictSvc.dictionary$('gfsFuncs'),
      this.employees$,
    ])
      .pipe(
        filter(([k, g, f]) => {
          return k.length > 0 && g.length > 0 && f.length > 0;
        }),
        map(([k, g, f]) => {
          this.gfsFuncs$ = of(g);
        })
      )
      .subscribe(() => {
        this.form.enable();
      });

    this.data$.pipe(take(1)).subscribe((data) => {
      this.form.patchValue(data);
    });
  }

  save() {
    this.dialogRef.close(this.form.value);
  }

  cancel() {
    this.dialogRef.close(null);
  }
}
