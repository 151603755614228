import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, switchMap } from 'rxjs';
import {
  AddObjective,
  Objective,
  ObjectivesState,
  RemoveObjective,
  SetObjectives,
  UpdateIsCurrentObjective,
  UpdateObjective,
} from './objectives.state';

@Injectable({
  providedIn: 'root',
})
export class ObjectivesService {
  constructor(private http: HttpClient, private store: Store) {
    this.http
      .get<Objective[]>(`api/objectives`)
      .subscribe((result) => this.store.dispatch(new SetObjectives(result)));
  }

  list$() {
    return this.store.select(ObjectivesState.items);
  }

  get$(id: number) {
    return this.http.get<Objective>(`api/objectives/${id}`);
  }

  add$(item: Partial<Objective>) {
    return this.http
      .post<Objective>(`api/objectives`, item)
      .pipe(
        switchMap((result) =>
          this.store.dispatch(new AddObjective(result)).pipe(map(() => result))
        )
      );
  }

  update$(item: Objective) {
    return this.http
      .put<Objective>(`api/objectives/${item.Id}`, item)
      .pipe(
        switchMap((result) =>
          this.store
            .dispatch(new UpdateObjective(result))
            .pipe(map(() => result))
        )
      );
  }
  updateIsCurrent$(id: number, isCurrent: boolean) {
    return this.http
      .put<boolean>(`api/objectives/${id}/iscurrent`, isCurrent)
      .pipe(
        switchMap((result) =>
          this.store
            .dispatch(new UpdateIsCurrentObjective(id, isCurrent))
            .pipe(map(() => result))
        )
      );
  }

  remove$(id: number) {
    return this.http
      .delete<void>(`api/objectives/${id}`)
      .pipe(switchMap(() => this.store.dispatch(new RemoveObjective(id))));
  }
}
