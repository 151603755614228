import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Planless, PlanlessState, SetPlanless } from './planless.state';

@Injectable({
  providedIn: 'root',
})
export class PlanlessService {
  constructor(private http: HttpClient, private store: Store) {
    this.http
      .get<Planless[]>(`api/projects/planless`)
      .subscribe((items) => this.store.dispatch(new SetPlanless(items)));
  }

  list$() {
    return this.store.select(PlanlessState.items);
  }
}
