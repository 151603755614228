import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LicenceService {
  isValid() {
    console.log('licenceService', new Date(), environment.licenceExpiry);
    return new Date() < environment.licenceExpiry;
  }

  getMessages() {
    const tenDaysFromNow = new Date();
    tenDaysFromNow.setDate(tenDaysFromNow.getDate() + 10);

    if (tenDaysFromNow < environment.licenceExpiry) return [];

    return [
      `System will no longer be available from ${environment.licenceExpiry.toDateString()}.`,
    ];
  }
}
