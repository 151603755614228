import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SelectFieldComponent, ValidationService } from '@pmslogic/widgets';
import { Observable, map, take } from 'rxjs';
import { FinyearsPipe } from '../../util/pipes/finyears.pipe';
import { YearsService } from '../../years/years.service';
import { YearKvp } from '../../years/years.state';
import { Milestone } from './milestones.state';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ValidationService],
  templateUrl: './milestone-year.component.html',
  styles: [
    `
      :host {
        display: block;
        width: 300px;
      }
    `,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SelectFieldComponent,
    FinyearsPipe,
  ],
})
export class MilestoneYearComponent implements OnInit {
  form: FormGroup;
  years$: Observable<YearKvp[]>;
  existingYears: number[] = [];

  constructor(
    public dialogRef: DialogRef<Milestone | null>,
    @Inject(DIALOG_DATA) private data$: Observable<number[]>,
    private yearsService: YearsService
  ) {
    this.form = new FormGroup({
      Year: new FormControl(),
    });

    this.years$ = yearsService
      .getYears$()
      .pipe(
        map((items) => items.filter((f) => !this.existingYears.includes(f.Key)))
      );

    this.form.disable();
  }
  ngOnInit(): void {
    this.data$.pipe(take(1)).subscribe((existingYears) => {
      this.existingYears = existingYears;
      this.form.enable();
    });
  }

  save() {
    this.dialogRef.close(this.form.value);
  }

  cancel() {
    this.dialogRef.close(null);
  }
}
