import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  Observable,
  catchError,
  filter,
  finalize,
  map,
  of,
  take,
  throwError,
} from 'rxjs';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog.component';
import { LoadingService } from '../../shared/loading/loading.service';
import { MessageDialogComponent } from '../../shared/message-dialog.component';
import { isDefined } from '../../util/common';
import { ShowallPipe } from '../../util/pipes/showall.pipe';
import { StrategiesService } from './strategies.service';
import { Strategy } from './strategies.state';
import { StrategyComponent } from './strategy.component';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'pms-strategies',
  imports: [CommonModule, DialogModule, ShowallPipe],
  templateUrl: './strategies.component.html',
})
export class StrategiesComponent {
  @Input() parentId!: number;
  @Input() showAll = false;
  @Input() isCurrent = true;

  loading$ = this.loadingService.isLoading$(this);
  list$ = new Observable<Strategy[] | undefined>();

  constructor(
    private service: StrategiesService,
    private dialog: Dialog,
    private loadingService: LoadingService
  ) {
    this.loadingService.startLoading(this);
    this.list$ = this.service.list$().pipe(
      map((m) => {
        return m
          ?.filter((f) => f.ObjectiveId == this.parentId)
          .map((item) => {
            return { ...item, IsCurrent: item.IsCurrent && this.isCurrent };
          });
      })
    );

    this.service
      .list$()
      .pipe(
        filter((f) => isDefined(f)),
        take(1),
        finalize(() => this.loadingService.stopLoading(this))
      )
      .subscribe();
  }

  add() {
    this.dialog
      .open(StrategyComponent, {
        data: of({ ObjectiveId: this.parentId, IsCurrent: true }),
      })
      .closed.subscribe((result) => {
        if (result) {
          this.loadingService.startLoading(this);
          this.service
            .add$(result as Strategy)
            .pipe(finalize(() => this.loadingService.stopLoading(this)))
            .subscribe();
        }
      });
  }

  edit(id: number) {
    this.dialog
      .open(StrategyComponent, { data: this.service.get$(id) })
      .closed.subscribe((result) => {
        if (result) {
          this.loadingService.startLoading(this);
          this.service
            .update$(result as Strategy)
            .pipe(finalize(() => this.loadingService.stopLoading(this)))
            .subscribe();
        }
      });
  }

  updateIsCurrent(item: Strategy, isCurrent: boolean) {
    this.loadingService.startLoading(this);
    this.service
      .updateIsCurrent$(item.Id, isCurrent)
      .pipe(finalize(() => this.loadingService.stopLoading(this)))
      .subscribe();
  }

  remove(id: number) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          text: 'Are you sure you want to delete this Strategy?',
        },
      })
      .closed.subscribe((result) => {
        if (result as boolean) {
          this.loadingService.startLoading(this);
          this.service
            .remove$(id)
            .pipe(
              catchError(() => {
                this.dialog.open(MessageDialogComponent, {
                  data: {
                    title: 'Request Denied',
                    text: 'This record is in use and cannot be deleted.',
                  },
                });
                return throwError(() => new Error(`Delete failed [id=${id}]`));
              })
            )
            .pipe(finalize(() => this.loadingService.stopLoading(this)))
            .subscribe();
        }
      });
  }
}
