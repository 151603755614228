import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, filter, map, of, switchMap } from 'rxjs';
import { LicenceService } from '../licence/licence.service';
import { Notification, Permissions, User } from './user.models';
import { SetUser, UserState } from './user.state';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private http: HttpClient,
    private licenceService: LicenceService,
    private store: Store
  ) {}

  public load() {
    this.http
      .get<User>(`api/token`)
      .subscribe((user) => this.store.dispatch(new SetUser(user)));
  }

  public getUser$(): Observable<User> {
    return this.store.select(UserState.user).pipe(
      filter((user) => user !== null),
      switchMap((user) => of(user as User))
    );
  }

  public getPermissions$(): Observable<Permissions> {
    return this.store.select(UserState.user).pipe(
      filter((user) => user !== null),
      switchMap((user) => of((user as User).Permissions))
    );
  }

  public getNotifications$(): Observable<Notification[]> {
    const licenceMessages = this.licenceService
      .getMessages()
      .map((message) => ({
        message,
        dismissable: false,
      }));

    return this.http.get<string[]>(`users/me/notifications`).pipe(
      map((messages) =>
        messages.map((message) => ({
          message,
          dismissable: true,
        }))
      ),
      switchMap((notifications) => of([...licenceMessages, ...notifications]))
    );
  }
}
