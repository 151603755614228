import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
// --- Model ---
export interface Ccr {
  Id: number;
  Title: string;
  Description: string;
  Type: string;
}

// --- Actions ---
export class SetCcrs {
  static readonly type = '[Ccrs] SetCcrs';
  constructor(public items: Ccr[]) {}
}

export class AddCcr {
  static readonly type = '[Ccrs] AddCcr';
  constructor(public item: Ccr) {}
}

export class UpdateCcr {
  static readonly type = '[Ccrs] UpdateCcr';
  constructor(public item: Ccr) {}
}

export class RemoveCcr {
  static readonly type = '[Ccrs] RemoveCcr';
  constructor(public id: number) {}
}
// --- State ---
export interface CcrsStateModel {
  items?: Ccr[];
}

@State<CcrsStateModel>({
  name: 'CcrsState',
})
@Injectable()
export class CcrsState {
  @Selector()
  static items(state: CcrsStateModel) {
    return state.items;
  }

  @Action(SetCcrs)
  set(ctx: StateContext<CcrsStateModel>, { items }: SetCcrs) {
    ctx.patchState({
      items: items,
    });
  }

  @Action(AddCcr)
  add(ctx: StateContext<CcrsStateModel>, { item }: AddCcr) {
    ctx.setState(
      patch({
        items: append([item]),
      })
    );
  }

  @Action(UpdateCcr)
  update(ctx: StateContext<CcrsStateModel>, { item }: UpdateCcr) {
    ctx.setState(
      patch({
        items: updateItem<Ccr>((itm) => itm.Id == item.Id, item),
      })
    );
  }

  @Action(RemoveCcr)
  remove(ctx: StateContext<CcrsStateModel>, { id }: RemoveCcr) {
    ctx.setState(
      patch({
        items: removeItem<Ccr>((itm) => itm.Id == id),
      })
    );
  }
}
