import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InputFieldComponent } from '@pmslogic/widgets';
import {
  BehaviorSubject,
  Subject,
  debounceTime,
  filter,
  takeUntil,
} from 'rxjs';
import { PageComponent } from '../../../shared/layout/page.component';
import { AttachmentsComponent } from '../attachments/attachments.component';
import { Claims } from '../review.models';
import { ReviewStarsComponent } from './review-stars/review-stars.component';
import { ScoreHistoryComponent } from './score-history/score-history.component';
import { ScoreStatusComponent } from './score-status/score-status.component';
import { INDICATOR_ATTACHMENTS_PATH, Score } from './scores.models';
import { ScoresService } from './scores.service';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tr[pms-score]',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.less'],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    PageComponent,
    DialogModule,
    InputFieldComponent,
    ReviewStarsComponent,
  ],
})
export class ScoreComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private disableSubscription = false;
  private _claims: Claims | undefined;

  form: FormGroup = this.formGroup();

  complete$ = new BehaviorSubject<boolean>(false);

  @Input() item!: Score;
  @Input() set claims(value: Claims | undefined) {
    if (value) {
      this.disableForm(value);
    }
    this._claims = value;
  }
  get claims() {
    return this._claims;
  }

  @Output() changed = new EventEmitter<Score>();

  constructor(
    private scoresService: ScoresService,
    private dialog: Dialog,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.form.disable();
  }

  ngOnInit(): void {
    this.form.patchValue(this.item);
    this.complete$.next(this.scoresService.isComplete(this.item));

    this.form.valueChanges
      .pipe(
        filter(() => !this.disableSubscription),
        debounceTime(1000),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => {
        this.item = { ...this.item, ...value };
        this.changed.next(this.item);
        this.complete$.next(this.scoresService.isComplete(this.item));
      });
  }

  completionStatus() {
    this.dialog.open(ScoreStatusComponent, { data: this.item });
  }

  history() {
    this.dialog.open(ScoreHistoryComponent, {
      data: this.scoresService.indicatorScores$(this.item.IndicatorId),
    });
  }

  files() {
    console.log(this.claims);
    this.dialog
      .open(AttachmentsComponent, {
        data: {
          parentId: this.item.IndicatorId,
          path: INDICATOR_ATTACHMENTS_PATH,
          claims: this.claims ?? ({} as Claims),
        },
      })
      .closed.subscribe((result) => {
        this.item.NoOfFiles = result as number;
        this.cdr.markForCheck();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private formGroup() {
    return this.fb.group({
      Actual: '',
      UnderPerformanceReason: '',
      MitigatingMeasures: '',
      WhenTargetMet: '',
      Score: 0,
      Comment: '',
      RemedialAction: '',
      FinalScore: 0,
    });
  }

  disableForm(claims: Claims) {
    this.disableSubscription = true;

    this.form.disable();

    if (claims.CanChangePersonalAssessment) {
      this.form.get('Actual')?.enable();
      this.form.get('UnderPerformanceReason')?.enable();
      this.form.get('MitigatingMeasures')?.enable();
      this.form.get('WhenTargetMet')?.enable();
      this.form.get('Score')?.enable();
    }

    if (claims.CanChangeFinalAssessment) {
      this.form.get('Comment')?.enable();
      this.form.get('RemedialAction')?.enable();
      this.form.get('FinalScore')?.enable();
    }

    this.disableSubscription = false;
  }
}
