import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface PerformanceReport {
  Description: string;
  FullName: string;
  IsMunicipalManager: string;
  JobTitle: string;
  Q1Total: number;
  Q2Total: number;
  Q3Total: number;
  Q4Total: number;
  Total: number;
}

@Injectable({
  providedIn: 'root',
})
export class PerformanceService {
  constructor(private http: HttpClient) {}

  // cumulative$(year: number) {
  //   return new Promise<PerformanceReport[]>((resolve) => {
  //     setTimeout(() => {
  //       resolve([]);
  //     }, 1000);
  //   });
  // }
  // overall$(year: number) {
  //   return new Promise<PerformanceReport[]>((resolve) => {
  //     setTimeout(() => {
  //       resolve([]);
  //     }, 1000);
  //   });
  // }

  cumulative$(year: number) {
    return this.http.get<PerformanceReport[]>(
      `api/performance/${year}/cumulative`
    );
  }
  overall$(year: number) {
    return this.http.get<PerformanceReport[]>(
      `api/performance/${year}/overall`
    );
  }
}
