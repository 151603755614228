import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth.service';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, FormsModule],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
})
export class LoginComponent {
  user = '';
  password = '';
  isTestSite = environment.isTestSite;
  attribution = environment.attribution;
  isLoading$ = new BehaviorSubject(false);
  errors$ = new BehaviorSubject<string[]>([]);

  constructor(private authService: AuthService, private router: Router) {
    document.title = `PMS :: ${environment.shortName}`;
  }

  signIn() {
    this.isLoading$.next(true);
    this.authService.signIn(this.user, this.password).subscribe((errors) => {
      this.errors$.next(errors);
      this.isLoading$.next(false);
      if (errors.length == 0) {
        this.router.navigate(['/']);
      }
    });
  }

  logout() {
    this.authService.signOut();
  }
}
