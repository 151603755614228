import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem } from '@ngxs/store/operators';

// --- Model ---
export interface Doc {
  Id: number;
  FileId: string;
  Name: string;
}
export interface File {
  Id: number;
  FileId: string;
  FileName: string;
  Data: any;
}

// --- Actions ---
export class SetDocs {
  static readonly type = '[Docs] SetDocs';
  constructor(public items: Doc[]) {}
}

export class AddDoc {
  static readonly type = '[Docs] AddDoc';
  constructor(public item: Doc) {}
}

export class RemoveDoc {
  static readonly type = '[Docs] RemoveDoc';
  constructor(public id: number) {}
}

// --- State ---
export interface DocsStateModel {
  items: Doc[];
}

@State<DocsStateModel>({
  name: 'DocsState',
  defaults: {
    items: [],
  },
})
@Injectable()
export class DocsState {
  @Selector()
  static items(state: DocsStateModel) {
    return state.items;
  }

  @Action(SetDocs)
  set(ctx: StateContext<DocsStateModel>, { items }: SetDocs) {
    ctx.patchState({
      items: items,
    });
  }

  @Action(AddDoc)
  add(ctx: StateContext<DocsStateModel>, { item }: AddDoc) {
    ctx.setState(
      patch({
        items: append([item]),
      })
    );
  }

  @Action(RemoveDoc)
  remove(ctx: StateContext<DocsStateModel>, { id }: RemoveDoc) {
    ctx.setState(
      patch({
        items: removeItem<Doc>((itm) => itm.Id == id),
      })
    );
  }
}
