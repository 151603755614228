import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'pms-toolbar',
  template: `<div class="flex">
    <i class="text-[2.2rem]/[2.8rem] mr-2 fa fa-{{ icon }}"></i>
    <div>
      <div class="text-[1.3rem]">{{ title }}</div>
      <div class="text-[0.8rem] -mt-1 text-muted opacity-70">
        {{ subTitle }}
      </div>
    </div>
    <div class="grow flex justify-end p-1">
      <ng-content />
    </div>
  </div> `,
})
export class ToolbarComponent {
  @Input() icon?: string;
  @Input() title?: string;
  @Input() subTitle?: string;
}
