<div class="modal-header">
  <h4 class="modal-title">Item Details</h4>
</div>
<div class="modal-body" [formGroup]="form">
  <div class="row">
    <div class="col">
      <div class="rounded-lg bg-slate-200 border-slate-700 px-4 py-2 mb-4">
        <b>NOTE: </b> If you wish to apply to full qualifications, please do not
        fill in this form. Request for full qualifications must follow the study
        assistance route.
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <pms-input-field
        label="Identified Skills Gap"
        ngDefaultControl
        [formControl]="$any(form.get('SkillRequired'))"
        placeholder="add text here"
        [rows]="2"
      ></pms-input-field>

      <pms-input-field
        label="Outcomes Expected / (what will I achieve)"
        ngDefaultControl
        [formControl]="$any(form.get('Outcome'))"
        placeholder="add text here"
        [rows]="2"
      ></pms-input-field>

      <pms-input-field
        label="Identified Training Course"
        ngDefaultControl
        [formControl]="$any(form.get('SuggestedTraining'))"
        placeholder="add text here"
        [rows]="2"
      ></pms-input-field>

      <pms-select-field
        label="Time Frames"
        ngDefaultControl
        [formControl]="$any(form.get('TimeFrame'))"
        [kvp]="timeFrameKvps"
      ></pms-select-field>
    </div>

    <div class="col">
      <pms-input-field
        label="Skill Relevance to Daily Work"
        ngDefaultControl
        [formControl]="$any(form.get('WorkOpportunity'))"
        placeholder="add text here"
        [rows]="2"
      ></pms-input-field>

      <pms-input-field
        label="Support Person"
        ngDefaultControl
        [formControl]="$any(form.get('SupportPerson'))"
        placeholder="add text here"
        [rows]="2"
      ></pms-input-field>

      <pms-input-field
        label="Comments at next Review"
        ngDefaultControl
        [formControl]="$any(form.get('Comment'))"
        placeholder="add text here"
        [rows]="2"
      ></pms-input-field>

      <pms-select-field
        label="Training Type"
        ngDefaultControl
        [formControl]="$any(form.get('TrainingType'))"
        [kvp]="typeKvps"
      ></pms-select-field>

      <pms-select-field
        label="Scheduled For"
        ngDefaultControl
        [formControl]="$any(form.get('ScheduledFor'))"
        [kvp]="forKvps"
      ></pms-select-field>
    </div>
  </div>
</div>
<!--modal-body-->

<div class="modal-footer">
  <button
    class="btn btn-sm btn-primary"
    [disabled]="form.disabled || !form.valid"
    (click)="save()"
  >
    Save
  </button>

  <button class="btn btn-sm btn-default" (click)="cancel()">Cancel</button>
</div>
