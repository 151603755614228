import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Kvp } from '@pmslogic/widgets';
import { map, switchMap } from 'rxjs';
import {
  AddCcr,
  Ccr,
  CcrsState,
  RemoveCcr,
  SetCcrs,
  UpdateCcr,
} from './ccrs.state';

@Injectable({
  providedIn: 'root',
})
export class CcrsService {
  constructor(private http: HttpClient, private store: Store) {
    this.http
      .get<Ccr[]>(`api/ccrs`)
      .subscribe((items) => this.store.dispatch(new SetCcrs(items)));
  }

  list$() {
    return this.store.select(CcrsState.items);
  }

  dictionary$() {
    return this.list$().pipe(
      map((items) =>
        (items ?? []).map((item) => {
          return { Key: item.Id, Value: item.Title } as Kvp;
        })
      )
    );
  }

  get$(id: number) {
    return this.http.get<Ccr>(`api/ccrs/${id}`);
  }

  add$(item: Partial<Ccr>) {
    return this.http
      .post<Ccr>(`api/ccrs`, item)
      .pipe(
        switchMap((result) =>
          this.store.dispatch(new AddCcr(result)).pipe(map(() => result))
        )
      );
  }
  update$(item: Partial<Ccr>) {
    return this.http
      .put<Ccr>(`api/ccrs/${item.Id}`, item)
      .pipe(
        switchMap((result) =>
          this.store.dispatch(new UpdateCcr(result)).pipe(map(() => result))
        )
      );
  }
  remove$(id: number) {
    return this.http
      .delete<void>(`api/ccrs/${id}`)
      .pipe(switchMap(() => this.store.dispatch(new RemoveCcr(id))));
  }
}
