import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'pms-accept-control',
  imports: [CommonModule],
  template: `
    <div class="btn-group btn-group-sm">
      <button
        class="btn btn-sm btn-outline-danger text-nowrap"
        (click)="reject()"
      >
        <i *ngIf="value() === false" class="far fa-check-square"></i>
        <i
          *ngIf="value() === true || value() === null"
          class="far fa-square"
        ></i>
        Rejected
      </button>

      <button
        class="btn btn-sm btn-outline-primary text-nowrap"
        (click)="approve()"
      >
        <i *ngIf="value() === true" class="far fa-check-square"></i>
        <i
          *ngIf="value() === null || value() === false"
          class="far fa-square"
        ></i>
        Approved
      </button>
    </div>
  `,
})
export class AcceptControlComponent {
  value = input<boolean | null>();
  changed = output<boolean | null>();

  approve() {
    if (this.value() === true) {
      this.changed.emit(null);
    } else {
      this.changed.emit(true);
    }
  }

  reject() {
    if (this.value() === false) {
      this.changed.emit(null);
    } else {
      this.changed.emit(false);
    }
    //
  }
}
