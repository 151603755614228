import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { PlanInfo } from '../plans.service';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'pms-plan-summary-bar',
  imports: [],
  template: `<div class="summary-status">
    <div class="done">{{ done }}</div>
    <div class="busy">{{ busy }}</div>
    <div class="notdone">{{ notdone }}</div>
  </div> `,
  styleUrls: ['./summary-bar.component.less'],
})
export class PlanSummaryBarComponent implements OnInit {
  @Input() model: PlanInfo[] = [];
  done?: number;
  busy?: number;
  notdone?: number;

  ngOnInit(): void {
    this.done = this.model.filter((m) => m.IsSignedOff).length;
    this.busy = this.model.filter(
      (m) => m.IsPopulated && !m.IsSignedOff
    ).length;
    this.notdone = this.model.filter((m) => !m.IsPopulated).length;
  }
}
