export interface Score {
  Id: number;
  ReviewId: number;
  IndicatorId: number;
  Type: string;
  Target: string;
  Name?: string;
  CCRTitle?: string;
  CCRDesc?: string;
  ProjectNo?: string;
  Actual: string;
  Score: number;
  Comment?: string;
  FinalScore: number;
  RemedialAction?: string;
  UnderPerformanceReason?: string;
  MitigatingMeasures?: string;
  WhenTargetMet?: string;
  NoOfFiles: number;
  StaffComments: StaffComment[];
}

export interface ScoreHistory {
  Id: number;
  QuarterNo: number;
  Target: string;
  Actual: string;
  Score: number;
  Comment: string;
  FinalScore: number;
  RemedialAction: string;
  UnderPerformanceReason: string;
  MitigatingMeasures: string;
  WhenTargetMet: string;
}

export interface StaffComment {
  StaffName: string;
  Comment: string;
}

//TODO PBH: Move to indicators.models after merged
export const INDICATOR_ATTACHMENTS_PATH = 'indicatorattachments';
