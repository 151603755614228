import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Score } from '../scores.models';
import { ScoresService } from '../scores.service';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  templateUrl: './score-status.component.html',
})
export class ScoreStatusComponent {
  title = 'Completion Status';
  items: { label: string; isComplete: boolean }[] = [];

  constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) private data: Score,
    private svc: ScoresService
  ) {
    this.items.push(
      ...[
        {
          label: 'Personal comments added',
          isComplete: !!data.Actual,
        },
        {
          label: 'Personal score set',
          isComplete: !!(data.Score > 0),
        },
        {
          label: 'Manager comments added',
          isComplete: !!data.Comment,
        },
        {
          label: 'Manager score set',
          isComplete: !!(data.FinalScore > 0),
        },
      ]
    );
  }

  close() {
    this.dialogRef.close(null);
  }
}
