import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  combineLatest,
  filter,
  finalize,
  map,
  of,
  take,
  throwError,
} from 'rxjs';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog.component';
import { LoadingService } from '../../shared/loading/loading.service';
import { MessageDialogComponent } from '../../shared/message-dialog.component';
import { ProgressComponent } from '../../shared/progress/progress.component';
import { isDefined } from '../../util/common';
import { ShowallPipe } from '../../util/pipes/showall.pipe';
import { StrategiesComponent } from '../strategies/strategies.component';
import { ObjectiveComponent } from './objective.component';
import { ObjectivesService } from './objectives.service';
import { Objective } from './objectives.state';

interface ObjectiveListItem extends Objective {
  expanded: boolean;
}
@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'pms-objectives',
  templateUrl: './objectives.component.html',
  imports: [
    CommonModule,
    DialogModule,
    StrategiesComponent,
    ShowallPipe,
    ProgressComponent,
  ],
})
export class ObjectivesComponent {
  @Input() parentId!: number;
  @Input() showAll = false;
  @Input() isCurrent = true;

  loading$ = this.loadingService.isLoading(this);
  expanded$ = new BehaviorSubject<number[]>([]);
  list$: Observable<ObjectiveListItem[] | undefined>;

  constructor(
    private service: ObjectivesService,
    private dialog: Dialog,
    private loadingService: LoadingService
  ) {
    this.loadingService.startLoading(this);
    this.list$ = combineLatest([this.service.list$(), this.expanded$]).pipe(
      map(([items, expanded]) =>
        items
          ?.filter((f) => f.DevPriorityId == this.parentId)
          .map((item) => {
            return {
              ...item,
              expanded: expanded.includes(item.Id),
              IsCurrent: item.IsCurrent && this.isCurrent,
            } as ObjectiveListItem;
          })
      )
    );
    this.service
      .list$()
      .pipe(
        filter((f) => isDefined(f)),
        take(1),
        finalize(() => this.loadingService.stopLoading(this))
      )
      .subscribe();
  }

  expand(id: number) {
    const currentItems = this.expanded$.value;
    if (currentItems.includes(id)) {
      this.expanded$.next(currentItems.filter((item) => item !== id));
    } else {
      this.expanded$.next([...currentItems, id]);
    }
  }

  add() {
    this.dialog
      .open(ObjectiveComponent, {
        data: of({ DevPriorityId: this.parentId, IsCurrent: true }),
      })
      .closed.subscribe((result) => {
        if (result) {
          this.loadingService.startLoading(this);
          this.service
            .add$(result as Objective)
            .pipe(finalize(() => this.loadingService.stopLoading(this)))
            .subscribe();
        }
      });
  }

  edit(id: number) {
    this.dialog
      .open(ObjectiveComponent, { data: this.service.get$(id) })
      .closed.subscribe((result) => {
        if (result) {
          this.loadingService.startLoading(this);
          this.service
            .update$(result as Objective)
            .pipe(finalize(() => this.loadingService.stopLoading(this)))
            .subscribe();
        }
      });
  }

  updateIsCurrent(item: Objective, isCurrent: boolean) {
    this.loadingService.startLoading(this);
    this.service
      .updateIsCurrent$(item.Id, isCurrent)
      .pipe(finalize(() => this.loadingService.stopLoading(this)))
      .subscribe();
  }

  remove(id: number) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          text: 'Are you sure you want to delete this Objective?',
        },
      })
      .closed.subscribe((result) => {
        if (result as boolean) {
          this.loadingService.startLoading(this);
          this.service
            .remove$(id)
            .pipe(
              catchError(() => {
                this.dialog.open(MessageDialogComponent, {
                  data: {
                    title: 'Request Denied',
                    text: 'This record is in use and cannot be deleted.',
                  },
                });
                return throwError(() => new Error(`Delete failed [id=${id}]`));
              })
            )
            .pipe(finalize(() => this.loadingService.stopLoading(this)))
            .subscribe();
        }
      });
  }
}
