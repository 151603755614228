import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { map, Observable, Subject, takeUntil } from 'rxjs';
import { SettingsService } from '../../../../settings/settings.service';
import { RatingPipe } from '../../../../util/pipes/rating.pipe';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'pms-review-stars',
  templateUrl: './review-stars.component.html',
  styleUrls: ['./review-stars.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReviewStarsComponent),
      multi: true,
    },
  ],
  imports: [CommonModule, RatingPipe],
})
export class ReviewStarsComponent implements ControlValueAccessor, OnDestroy {
  @Input() disabled = false;
  value = 0;
  stars: boolean[] = Array(5).fill(false);
  showNumbers$: Observable<boolean>;
  destroy$ = new Subject<void>();

  constructor(
    private settings: SettingsService,
    private ref: ChangeDetectorRef
  ) {
    this.showNumbers$ = this.settings.getSettings$().pipe(
      takeUntil(this.destroy$),
      map((settings) => settings.AddNumbersToReviewStars)
    );
  }

  checked(val: number) {
    return this.value >= val;
  }

  onChange = (rating: number) => {};

  onTouched = () => {};

  writeValue(rating: number): void {
    this.value = rating;
    this.stars = this.stars.map((_, i) => rating > i);
    this.onChange(this.value);
    this.ref.markForCheck();
  }

  registerOnChange(fn: (rating: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  rate(rating: number): void {
    if (!this.disabled) {
      this.writeValue(rating);
    }
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
