import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'sort',
  standalone: true,
})
export class SortPipe implements PipeTransform {
  transform(array: any[], propertyName: string, descending = false): any[] {
    if (!array || !propertyName) {
      return array;
    }

    const sortOrder = descending ? -1 : 1;

    return array.slice().sort((a, b) => {
      const aValue = a[propertyName];
      const bValue = b[propertyName];

      if (aValue === bValue) {
        return 0;
      }

      return (aValue < bValue ? -1 : 1) * sortOrder;
    });
  }
}
