import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { PageComponent } from '../shared/layout/page.component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { YearsService } from '../years/years.service';
import { CommonModule } from '@angular/common';
import { Report, ReportsService } from '../reports/reports.service';
import { SafePipe } from '../util/pipes/safe.pipe';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './scorecard.component.html',
  imports: [CommonModule, PageComponent, ReactiveFormsModule, SafePipe],
})
export class ScorecardComponent implements OnDestroy {
  loading$ = new BehaviorSubject<boolean>(true);

  loadingSub: Subscription;
  yearsSub: Subscription;

  years$ = this.yearsService.getYears$();
  filterForm = new FormGroup({
    year: new FormControl(),
  });
  report?: Report | null;

  constructor(
    private yearsService: YearsService,
    private reportsService: ReportsService
  ) {
    this.yearsSub = this.yearsService.getCurrentYear$().subscribe((year) => {
      this.filterForm.patchValue({ year: year });
      this.load();
    });

    //loading$ doesnt work. Waiting full loading service.
    this.loadingSub = this.loading$.subscribe((loading) => {
      if (loading) {
        this.filterForm.disable();
      } else {
        this.filterForm.enable();
      }
    });
  }

  load() {
    const year = this.filterForm.get('year')?.value;
    if (!year) return;

    this.report = this.reportsService.scorecard(year);
    this.loading$.next(false); //doesnt work
  }

  print() {
    this.report?.print();
  }
  ngOnDestroy() {
    this.yearsSub.unsubscribe();
    this.loadingSub.unsubscribe();
  }
}
