import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

// --- Model ---
export interface Settings {
  TenantId: string;
  UseIDPIndicators: boolean;
  CurrentFinYearEnd: number;
  CurrentQuarter: number;
  AllowCurrentFinYearPlanEdit: boolean;
  AllowNextFinYearPlanEdit: boolean;
  QuickFilterPlans: boolean;
  AddNumbersToReviewStars: boolean;
}

// --- Actions ---
export class SetSettings {
  static readonly type = '[Settings] SetSettings';
  constructor(public settings: Settings) {}
}

// --- State ---
export interface SettingsStateModel {
  settings: Settings | null;
}

@State<SettingsStateModel>({
  name: 'AppState',
  defaults: {
    settings: null,
  },
})
@Injectable()
export class SettingsState {
  @Selector()
  static settings(state: SettingsStateModel) {
    return state.settings;
  }

  @Action(SetSettings)
  setSettings(
    ctx: StateContext<SettingsStateModel>,
    { settings }: SetSettings
  ) {
    ctx.patchState({
      settings: settings,
    });
  }
}
