import { DIALOG_DATA, Dialog, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  CheckboxFieldComponent,
  InputFieldComponent,
  Kvp,
  MultiCheckboxFieldComponent,
  SelectFieldComponent,
  ValidationService,
} from '@pmslogic/widgets';
import {
  Observable,
  Subscription,
  combineLatest,
  filter,
  map,
  of,
  switchMap,
} from 'rxjs';
import { DictionariesService } from '../shared/dictionaries.service';
import { ChangePasswordComponent } from '../user/change-password/change-password.component';
import { isDefined } from '../util/common';
import { EmployeesService } from './employees.service';
import { Employee } from './employees.state';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputFieldComponent,
    SelectFieldComponent,
    CheckboxFieldComponent,
    MultiCheckboxFieldComponent,
  ],
  providers: [ValidationService],
  templateUrl: './employee.component.html',
  styles: [
    `
      :host {
        display: block;
        width: 800px;
      }
    `,
  ],
})
export class EmployeeComponent implements OnInit, OnDestroy {
  form: FormGroup;
  depts$ = this.dictionariesService.dictionary$('depts');
  designations$ = this.dictionariesService.dictionary$('designations');
  groups$ = this.dictionariesService.dictionary$('groups');
  managers$: Observable<Kvp[]>;
  subscriptions: Subscription[] = [];

  constructor(
    public dialogRef: DialogRef<Employee | null>,
    private dialog: Dialog,
    @Inject(DIALOG_DATA) private data$: Observable<Partial<Employee>>,
    private service: EmployeesService,
    private dictionariesService: DictionariesService,
    validation: ValidationService
  ) {
    this.form = new FormGroup({
      Id: new FormControl(),
      FirstName: new FormControl('', [validation.required()]),
      LastName: new FormControl('', [validation.required()]),
      Email: new FormControl(''),
      JobTitle: new FormControl('', [validation.required()]),
      DeptId: new FormControl(null, [validation.required()]),
      Designation: new FormControl('', [validation.required()]),
      ManagerId: new FormControl(null),
      IsDirector: new FormControl(false),
      UserName: new FormControl('', [validation.required()]),
      IsCurrent: new FormControl(true),
      GroupIds: new FormControl([]),
    });

    this.form.disable();

    this.subscriptions.push(
      this.form.valueChanges
        .pipe(
          map((val) => {
            const isEmployee = val.Designation === 'Employee';

            const ctrl = this.form.get('ManagerId');
            if (!ctrl) return;

            if (isEmployee) {
              ctrl.enable({ emitEvent: false });
            } else {
              ctrl.disable({ emitEvent: false });
            }
          })
        )
        .subscribe()
    );

    this.managers$ = combineLatest([data$, this.service.dictionary$()]).pipe(
      filter(([data]) => isDefined(data)),
      switchMap(([data, dic]) => {
        return of(dic.filter((f) => f.Key != data.Id));
      })
    );
  }
  ngOnInit(): void {
    this.subscriptions.push(
      this.data$.subscribe((item) => {
        this.form.patchValue(item);
        this.form.enable();
      })
    );
  }

  changePassword() {
    this.dialog
      .open(ChangePasswordComponent, { data: this.form.get('Id')?.value })
      .closed.subscribe((result) => {
        if (result) console.log(result);
      });
  }

  save() {
    this.dialogRef.close(this.form.value);
  }

  cancel() {
    this.dialogRef.close(null);
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((e) => e.unsubscribe());
  }
}
