import { DIALOG_DATA, Dialog, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  BehaviorSubject,
  Subject,
  catchError,
  take,
  takeUntil,
  throwError,
} from 'rxjs';
import { ConfirmDialogComponent } from '../../../shared/confirm-dialog.component';
import { LoadingService } from '../../../shared/loading/loading.service';
import { MessageDialogComponent } from '../../../shared/message-dialog.component';
import { ProgressComponent } from '../../../shared/progress/progress.component';
import { Claims } from '../review.models';
import { Attachment, AttachmentsComponentData } from './attachments.models';
import { AttachmentsService } from './attachments.service';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './attachments.component.html',
  styles: [
    `
      :host {
        display: block;
        width: 1200px;
      }
    `,
  ],
  imports: [CommonModule, ReactiveFormsModule, ProgressComponent],
})
export class AttachmentsComponent implements OnDestroy {
  fileForm: FormGroup;
  file: File | null = null;
  claims: Claims = {} as Claims;
  disableApprovals = this.data.disableApprovals;

  loading$ = this.loadingService.isLoading$(this);
  list$ = new BehaviorSubject<Attachment[]>([]);
  destroy$ = new Subject<void>();

  constructor(
    public dialogRef: DialogRef<number | null>,
    @Inject(DIALOG_DATA) private data: AttachmentsComponentData,
    private service: AttachmentsService,
    private loadingService: LoadingService,
    private dialog: Dialog
  ) {
    this.claims = data.claims;
    this.loadingService.startLoading(this);

    this.service
      .list$(data.path, data.parentId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((items) => {
        this.list$.next(items);
        this.loadingService.stopLoading(this);
      });

    this.fileForm = new FormGroup({
      file: new FormControl('', Validators.required),
    });
  }

  onFileSelected(event: Event) {
    const target = event?.target as HTMLInputElement;
    if (target.files == null) return;

    this.file = target.files[0];
  }
  upload() {
    if (!this.file) return;
    this.loadingService.startLoading(this);

    this.service
      .upload$(this.data.path, this.data.parentId, this.file)
      .pipe(take(1))
      .subscribe((item) => {
        this.list$.next([...this.list$.value, item]);
        this.loadingService.stopLoading(this);
      });
  }

  download(item: Attachment) {
    this.service
      .download$(item.FileId)
      .pipe(take(1))
      .subscribe((data) => {
        const blob = new Blob([data], { type: data.type });
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = item.Name;
        a.click();
        window.URL.revokeObjectURL(a.href);
      });
  }

  approve(item: Attachment) {
    this.loadingService.startLoading(this);
    if (item.Approved === true) {
      item.Approved = undefined;
    } else {
      item.Approved = true;
    }

    this.service
      .approve$(this.data.path, item.Id, item.Approved)
      .pipe(take(1))
      .subscribe(() => {
        this.loadingService.stopLoading(this);
      });
  }

  reject(item: Attachment) {
    this.loadingService.startLoading(this);
    if (item.Approved === false) {
      item.Approved = undefined;
    } else {
      item.Approved = false;
    }

    this.service
      .approve$(this.data.path, item.Id, item.Approved)
      .pipe(take(1))
      .subscribe(() => {
        this.loadingService.stopLoading(this);
      });
  }

  remove(id: number) {
    this.loadingService.startLoading(this);

    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          text: 'Are you sure you want to delete this attachment?',
        },
      })
      .closed.subscribe((result) => {
        if (result as boolean) {
          this.service
            .remove$(this.data.path, id)
            .pipe(
              take(1),
              catchError(() => {
                this.dialog.open(MessageDialogComponent, {
                  data: {
                    title: 'Delete failed',
                    text: 'This attachment could not be deleted.',
                  },
                });
                return throwError(() => new Error(`Delete failed [id=${id}]`));
              })
            )
            .subscribe(() => {
              this.list$.next(this.list$.value.filter((f) => f.Id != id));
              this.loadingService.stopLoading(this);
            });
        }
      });
  }

  done() {
    this.dialogRef.close(this.list$.value.length);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
