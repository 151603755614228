import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, filter, map, of, switchMap } from 'rxjs';
import { SetSettings, Settings, SettingsState } from './settings.state';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private http: HttpClient, private store: Store) {
    this.http.get<Settings>(`api/settings`).subscribe((settings) => {
      this.store.dispatch(new SetSettings(settings));
    });
  }

  public load() {
    // Hack to get AngularJs service downgrade to work
  }

  getSettings$(): Observable<Settings> {
    return this.store.select(SettingsState.settings).pipe(
      filter((settings) => settings !== null),
      switchMap((settings) => of(settings as Settings))
    );
  }

  saveSettings$(settings: Settings): Observable<Settings> {
    return this.http
      .put<Settings>(`api/settings`, settings)
      .pipe(
        switchMap((settings) =>
          this.store
            .dispatch(new SetSettings(settings))
            .pipe(map(() => settings))
        )
      );
  }

  importPlansFromPreviousYear$() {
    return this.http.get<void>(`api/plans/import/from/previous/year`);
  }
}
