<pms-page
  title="Project List"
  subTitle="Manage projects here"
  icon="snowplow"
  [loading]="(loading$ | async)!"
>
  <span toolbar class="flex items-center gap-1" [formGroup]="filterForm">
    <span class="input-group input-group-sm">
      <span class="input-group-prepend">
        <span class="input-group-text">Year</span>
      </span>
      <select
        class="form-control input-sm nc-select-control"
        formControlName="year"
      >
        <option *ngFor="let t of years$ | async" [ngValue]="t.Key">
          {{ t.Value }}
        </option>
      </select>
    </span>

    <button (click)="add()" class="btn btn-sm btn-warning whitespace-nowrap">
      <i class="fa fa-plus"></i>Add New
    </button>
  </span>

  <div body>
    <table class="nc-table table">
      <thead>
        <tr>
          <td>Code</td>
          <td>Owner</td>
          <td>Title</td>
          <td>Description</td>
          <td>Development Strategy</td>
          <td>Years</td>
          <td></td>
        </tr>
      </thead>

      <tbody>
        <tr
          [pms-scroll-to-view]="item.Id === (currentId$ | async)"
          *ngFor="let item of list$ | async"
        >
          <td>{{ item.No }}</td>
          <td>{{ item.Owner }}</td>
          <td>{{ item.Title }}</td>
          <td>{{ item.Description }}</td>
          <td>{{ item.DevStrategy }}</td>
          <td>{{ (item.Years | finyears).join('\n') }}</td>
          <td>
            <div class="btn-group btn-group-sm w-100">
              <button (click)="edit(item.Id)" class="btn btn-sm btn-primary">
                <i class="fa fa-pen"></i>Edit
              </button>
              <button
                (click)="remove(item.Id)"
                class="btn btn-sm btn-outline-primary"
              >
                <i class="fa fa-trash"></i>
              </button>
            </div>
            <button
              (click)="milestones(item.Id)"
              class="btn btn-sm btn-outline-primary mt-1 whitespace-nowrap w-100"
            >
              <i class="fa fa-map-marker-alt"></i>
              Milestones
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</pms-page>
