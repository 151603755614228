import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { of, switchMap } from 'rxjs';
import { DeptsService } from '../depts/depts.service';
import { ReportsService } from '../reports/reports.service';
import { PageComponent } from '../shared/layout/page.component';
import { UserService } from '../user/user.service';
import { SafePipe } from '../util/pipes/safe.pipe';
import { YearsService } from '../years/years.service';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ReactiveFormsModule, PageComponent, SafePipe],
  templateUrl: './pdps.component.html',
  styleUrls: ['./pdps.component.less'],
})
export class PdpsComponent {
  filterForm: FormGroup = new FormGroup({
    dept: new FormControl(),
    quarter: new FormControl(),
    year: new FormControl(),
  });

  private reportsService = inject(ReportsService);
  private userService = inject(UserService);
  private deptsService = inject(DeptsService);
  private yearsService = inject(YearsService);

  loading = signal(false);
  url = signal('');

  years = toSignal(this.yearsService.getYearsWithAll$());
  currentYear = toSignal(this.yearsService.getCurrentYear$());

  quarters = [
    { Key: 0, Value: 'All' },
    { Key: 1, Value: 'Quarter 1' },
    { Key: 2, Value: 'Quarter 2' },
    { Key: 3, Value: 'Quarter 3' },
    { Key: 4, Value: 'Quarter 4' },
  ];

  depts = toSignal(
    this.deptsService
      .dictionary$()
      .pipe(switchMap((depts) => of([{ Key: 0, Value: 'All' }, ...depts])))
  );

  showDepts = toSignal(
    this.userService
      .getPermissions$()
      .pipe(switchMap((permissions) => of(permissions.ViewAllPDPS)))
  );

  constructor() {
    effect(() => {
      if (this.loading()) {
        this.filterForm.disable();
      } else {
        this.filterForm.enable();
      }
    });

    effect(() => {
      const year = this.currentYear();
      if (year) {
        this.filterForm.patchValue({ year: year });
      }
    });

    this.filterForm.patchValue({ quarter: this.quarters[0].Key });

    effect(() => {
      const depts = this.depts();
      if (!depts) return;
      if (depts.length > 0) {
        this.filterForm.patchValue({ dept: depts[0].Key });
      }
    });
  }

  fetch() {
    this.loading.set(true);
    const values = this.filterForm.value;
    const report = this.reportsService.pdps(
      values.dept,
      values.quarter,
      values.year
    );
    this.url.set(report.url);
    this.loading.set(false);
  }

  print() {
    const values = this.filterForm.value;
    const report = this.reportsService.pdps(
      values.dept,
      values.quarter,
      values.year
    );
    this.url.set(report.url);
    report.print();
  }
}
