<div class="modal-header">
  <h4 class="modal-title">Risk Details</h4>
</div>
<div class="modal-body" [formGroup]="form">
  <pms-input-field
    label="Risk Area"
    ngDefaultControl
    [formControl]="$any(form.get('Area'))"
  ></pms-input-field>

  <pms-input-field
    label="Risk Measures"
    ngDefaultControl
    [formControl]="$any(form.get('Measures'))"
  ></pms-input-field>
</div>

<div class="modal-footer">
  <button
    class="btn btn-sm btn-primary"
    [disabled]="form.disabled || !form.valid"
    (click)="save()"
  >
    Save
  </button>

  <button class="btn btn-sm btn-default" (click)="cancel()">Cancel</button>
</div>
