import { Dialog } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ValidationService } from '@pmslogic/widgets';
import { MessageDialogComponent } from '../../shared/message-dialog.component';

export interface Signature {
  Designation: string;
  Password: string;
}

@Component({
  standalone: true,
  selector: 'pms-signature',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './signature.component.html',
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  providers: [ValidationService],
  styleUrls: ['./signature.component.less'],
})
export class SignatureComponent {
  @Input() text = '';
  @Input() designation = '';
  @Input() isSigned = false;
  @Input() signedDate?: Date | null = null;
  @Input() name = '';
  @Output() signed = new EventEmitter<Signature>();

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private validationService: ValidationService,
    private dialog: Dialog
  ) {
    this.form = this.fb.group({
      password: ['', validationService.required()],
    });
  }

  signoff() {
    if (!this.form.valid) {
      this.dialog.open(MessageDialogComponent, {
        data: {
          title: 'Sign-Off Incomplete',
          text: 'A password is required to complete this signature.',
        },
      });
    } else {
      const model = {
        Designation: this.designation,
        Password: this.form.get('password')?.value,
      };
      this.signed.emit(model);
    }
  }
}
