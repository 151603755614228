import { Dialog } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormArray, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { InputFieldComponent, ValidationService } from '@pmslogic/widgets';
import {
  BehaviorSubject,
  catchError,
  finalize,
  forkJoin,
  map,
  take,
  throwError,
} from 'rxjs';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog.component';
import { LoadingService } from '../../shared/loading/loading.service';
import { MessageDialogComponent } from '../../shared/message-dialog.component';
import { ProgressComponent } from '../../shared/progress/progress.component';
import { MilestonesService } from './milestones.service';
import { Milestone } from './milestones.state';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'pms-milestone',
  providers: [],
  templateUrl: './milestone.component.html',
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputFieldComponent,
    ProgressComponent,
  ],
})
export class MilestoneComponent implements OnInit {
  @Input() projectId: number | null | undefined;
  @Input() year!: number;
  @Input() milestones: Milestone[] = [];

  milestones$ = new BehaviorSubject<Milestone[]>([]);
  loading$ = this.loadingService.isLoading$(this);

  originalValue: Milestone[] = [];
  editing = false;
  form = this.fb.group({
    milestoneForms: this.fb.array([]),
  });

  constructor(
    private fb: FormBuilder,
    private dialog: Dialog,
    private service: MilestonesService,
    private validation: ValidationService,
    private loadingService: LoadingService
  ) {}
  ngOnInit(): void {
    //backup original value for when cancelling the edit
    this.originalValue = JSON.parse(JSON.stringify(this.milestones));
    this.milestones$.next(this.milestones);

    this.milestones$
      .pipe(
        take(1),
        map((milestones) => {
          this.milestoneForms.clear();

          for (let e = 1; e <= 4; e++) {
            const found = milestones.find((f) => f.QtrNo == e);

            const frm = this.fb.group({
              Id: [found?.Id],
              Year: [this.year],
              Description: [
                found?.Description || '',
                this.validation.required(),
              ],
              ProjectId: [this.projectId],
              QtrNo: [found?.QtrNo ?? e],
            });
            this.milestoneForms.push(frm);
          }
        })
      )
      .subscribe();
  }

  save() {
    this.loadingService.startLoading(this);
    const calls$ = this.milestoneForms.controls
      .filter((f) => f.value)
      .map((m) => {
        if (m.value.Id) return this.service.update$(m.value);
        else return this.service.add$(m.value);
      });

    forkJoin(calls$)
      .pipe(
        take(1),
        finalize(() => {
          this.loadingService.stopLoading(this);
        })
      )
      .subscribe(() => {
        //set the new original value
        this.originalValue = JSON.parse(JSON.stringify(this.milestones$.value));
      });
  }

  edit() {
    this.editing = true;
  }

  cancel() {
    this.editing = false;
    this.milestones$.next(this.originalValue);
  }

  remove() {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          text: 'Are you sure you want to delete these milestones?',
        },
      })
      .closed.subscribe((result) => {
        if (result as boolean) {
          this.loadingService.startLoading(this);
          const calls$ = this.milestones$.value
            .filter((f) => f.Id != null)
            .map((m) => {
              return this.service.remove$(m.Id);
            });
          forkJoin(calls$)
            .pipe(
              catchError(() => {
                this.dialog.open(MessageDialogComponent, {
                  data: {
                    title: 'Request Denied',
                    text: 'Could not delete milestones.',
                  },
                });
                return throwError(
                  () =>
                    new Error(
                      `Delete failed [projectId=${this.projectId}] [year=${this.year}]`
                    )
                );
              })
            )
            .pipe(finalize(() => this.loadingService.stopLoading(this)))
            .subscribe();
        }
      });
  }
  get milestoneForms(): FormArray {
    return this.form.get('milestoneForms') as FormArray;
  }
}
