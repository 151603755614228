import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PdpItem } from './pdp-items.models';

@Injectable({
  providedIn: 'root',
})
export class PdpItemsService {
  constructor(private http: HttpClient) {}

  list$(employeeId: number) {
    return this.http.get<PdpItem[]>('api/pdpitems?employeeId=' + employeeId);
  }

  get$(id: number) {
    return this.http.get<PdpItem>('api/pdpitems/' + id);
  }

  add$(item: PdpItem) {
    return this.http.post<PdpItem>('api/pdpitems', item);
  }

  update$(item: PdpItem) {
    return this.http.put<PdpItem>('api/pdpitems/' + item.Id, item);
  }

  remove$(id: number) {
    return this.http.delete<void>('api/pdpitems/' + id);
  }

  timeFramesList(val?: string) {
    const vals = ['July-Sept', 'Oct-Dec', 'Jan-Mar', 'Apr-Jun'];

    if (val) vals.unshift(val);

    return vals.map((val) => {
      return {
        Key: val,
        Value: val,
      };
    });
  }

  scheduledForList(val?: string) {
    const vals = ['Current', 'Future'];

    if (val) vals.unshift(val);

    return vals.map((val) => {
      return {
        Key: val,
        Value: val,
      };
    });
  }

  trainingTypeList(val?: string) {
    const vals = ['Accredited Short Skills Programme', 'On the Job'];

    if (val) vals.unshift(val);

    return vals.map((val) => {
      return {
        Key: val,
        Value: val,
      };
    });
  }
}
