import {
  DIALOG_DATA,
  Dialog,
  DialogModule,
  DialogRef,
} from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoadingService } from '../../../shared/loading/loading.service';
import { ProgressComponent } from '../../../shared/progress/progress.component';
import { Claims, Review } from '../review.models';
import { InterimReviewComponent } from './interim-review.component';
import { InterimReview } from './interim-review.models';
import { InterimReviewsService } from './interim-reviews.service';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, DialogModule, ProgressComponent],
  templateUrl: './interim-reviews.component.html',
  styles: [
    `
      :host {
        display: block;
        width: 800px;
      }
    `,
  ],
})
export class InterimReviewsComponent {
  loading$ = this.loadingService.isLoading$(this);
  items$ = new BehaviorSubject<InterimReview[]>([]);
  claims: Claims = {} as Claims;

  constructor(
    public dialogRef: DialogRef<number>,
    @Inject(DIALOG_DATA) private review: Review,
    private service: InterimReviewsService,
    private loadingService: LoadingService,
    private dialog: Dialog
  ) {
    this.loadingService.startLoading(this);
    this.claims = review.Claims;

    this.service.list$(review.Id).subscribe((items) => {
      this.items$.next(items);
      this.loadingService.stopLoading(this);
    });
  }

  add() {
    this.dialog.open(InterimReviewComponent).closed.subscribe((result) => {
      const item = { ReviewId: this.review.Id, Comment: result as string };

      this.service.add$(item).subscribe((item) => {
        this.items$.next([...this.items$.value, item]);
      });
    });
  }

  done() {
    this.dialogRef.close();
  }
}
