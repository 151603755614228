import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, map, switchMap } from 'rxjs';
import {
  AddDept,
  Dept,
  DeptsState,
  RemoveDept,
  SetDepts,
  UpdateDept,
} from './depts.state';

export interface DeptKvp {
  Key: number;
  Value: string;
}

@Injectable({
  providedIn: 'root',
})
export class DeptsService {
  constructor(private http: HttpClient, private store: Store) {
    this.http
      .get<Dept[]>(`api/depts`)
      .subscribe((items) => this.store.dispatch(new SetDepts(items)));
  }

  public dictionary$(): Observable<DeptKvp[]> {
    return this.http.get<DeptKvp[]>(`api/depts/dictionary`);
  }

  list$() {
    return this.store.select(DeptsState.items);
  }

  get$(id: number) {
    return this.http.get<Dept>(`api/depts/${id}`);
  }

  add$(item: Partial<Dept>) {
    return this.http
      .post<Dept>(`api/depts`, item)
      .pipe(
        switchMap((result) =>
          this.store.dispatch(new AddDept(result)).pipe(map(() => result))
        )
      );
  }
  update$(item: Partial<Dept>) {
    return this.http
      .put<Dept>(`api/depts/${item.Id}`, item)
      .pipe(
        switchMap((result) =>
          this.store.dispatch(new UpdateDept(result)).pipe(map(() => result))
        )
      );
  }
  remove$(id: number) {
    return this.http
      .delete<void>(`api/depts/${id}`)
      .pipe(switchMap(() => this.store.dispatch(new RemoveDept(id))));
  }
}
