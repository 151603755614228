import { Injectable } from '@angular/core';
import { Observable, asyncScheduler, defer, finalize, observeOn } from 'rxjs';
import {
  LoadingContext,
  LoadingKey,
  LoadingMap,
  LoadingState,
} from './loading.models';

// https://github.com/radekbusa/angular-loading-indicators-the-right-way/blob/master/README.md

const DEFAULT_LOADING_KEY: LoadingKey = 'DEFAULT_LOADING_KEY';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private contextMap = new WeakMap<LoadingContext, LoadingMap>();

  loadFrom<T>(
    source: Observable<T>,
    context: LoadingContext,
    key?: LoadingKey
  ): Observable<T> {
    return defer(() => {
      this.startLoading(context, key);
      return source.pipe(
        observeOn(asyncScheduler),
        finalize(() => this.stopLoading(context, key))
      );
    });
  }

  startLoading(context: LoadingContext, key?: LoadingKey): void {
    this.setLoadingState(context, this.getLoadingKey(key), true);
  }

  stopLoading(context: LoadingContext, key?: LoadingKey): void {
    this.setLoadingState(context, this.getLoadingKey(key), false);
  }

  clearLoading(context: LoadingContext, key?: LoadingKey): void {
    key = this.getLoadingKey(key);
    const loadingMap = this.contextMap.get(context);
    loadingMap?.get(key)?.complete();
    loadingMap?.delete(key);
  }

  clearAll(): void {
    this.contextMap = new WeakMap<LoadingContext, LoadingMap>();
  }

  isLoading(context: LoadingContext, key?: LoadingKey): boolean {
    const loadingMap = this.contextMap.get(context);
    if (!loadingMap) return false;

    key = this.getLoadingKey(key);

    return loadingMap.get(key)?.loading ?? false;
  }

  isLoading$(context: LoadingContext, key?: LoadingKey): Observable<boolean> {
    key = this.getLoadingKey(key);
    const hasMap = this.hasLoadingMap(context);
    const hasState = this.hasLoadingState(context, key);

    if (!(hasMap && hasState)) {
      this.setLoadingState(context, key, false);
    }

    const loading = this.contextMap.get(context)?.get(key)?.loading$;

    return loading as Observable<boolean>;
  }

  private getLoadingKey(loadingKey?: LoadingKey): LoadingKey {
    return loadingKey ?? DEFAULT_LOADING_KEY;
  }

  private setLoadingState(
    context: LoadingContext,
    key: LoadingKey,
    state: boolean
  ): LoadingState {
    const loadingMap = this.getOrAddLoadingMap(context);
    const loadingState = this.getOrAddLoadingState(loadingMap, key);
    loadingState.set(state);
    return loadingState;
  }

  private getOrAddLoadingMap(context: LoadingContext): LoadingMap {
    if (!this.hasLoadingMap(context)) {
      this.contextMap.set(context, new LoadingMap());
    }
    return this.contextMap.get(context) as LoadingMap;
  }

  private getOrAddLoadingState(
    loadingMap: LoadingMap,
    key: LoadingKey
  ): LoadingState {
    if (!loadingMap.has(key)) {
      loadingMap.set(key, new LoadingState());
    }
    return loadingMap.get(key) as LoadingState;
  }

  private hasLoadingMap(context: LoadingContext): boolean {
    return this.contextMap.has(context);
  }

  private hasLoadingState(context: LoadingContext, key: LoadingKey) {
    return this.contextMap.get(context)?.has(key) ?? false;
  }
}
