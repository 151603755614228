import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InputFieldComponent, ValidationService } from '@pmslogic/widgets';
import { Observable } from 'rxjs';
import { Priority } from './priorities.state';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ReactiveFormsModule, InputFieldComponent],
  providers: [ValidationService],
  templateUrl: './priority.component.html',
})
export class PriorityComponent implements OnInit {
  form: FormGroup;

  constructor(
    public dialogRef: DialogRef<Priority | null>,
    @Inject(DIALOG_DATA) private data$: Observable<Partial<Priority>>,
    validation: ValidationService
  ) {
    this.form = new FormGroup({
      Id: new FormControl(), //Not shown on form
      IsCurrent: new FormControl(), //not shown on form
      Description: new FormControl('', [validation.required()]),
    });

    this.form.disable();
  }
  ngOnInit(): void {
    this.data$.subscribe((item) => {
      this.form.patchValue(item);
      this.form.enable();
    });
  }

  save() {
    this.dialogRef.close(this.form.value);
  }

  cancel() {
    this.dialogRef.close(null);
  }
}
