<pms-page
  [title]="'System Settings'"
  [subTitle]="'Change settings here to configure system'"
  [icon]="'tools'"
  [loading]="(loading$ | async)!"
>
  <!-- body -->
  <div body id="settings" [formGroup]="form">
    <div class="list-group-header">System Settings</div>

    <div class="list-group-item">
      <span>
        <span class="mr-1">Current financial year-end</span>
        <span
          *ngIf="check('CurrentFinYearEnd', 'required')"
          class="badge badge-danger"
          >Required</span
        >
        <span
          *ngIf="check('CurrentFinYearEnd', 'min')"
          class="badge badge-danger"
          >Invalid</span
        >
      </span>
      <input
        pms-number-control
        [mask]="'9999'"
        formControlName="CurrentFinYearEnd"
      />
    </div>

    <div class="list-group-item">
      <span>
        <span class="mr-1">Current financial quarter</span>
        <span
          *ngIf="check('CurrentQuarter', 'required')"
          class="badge badge-danger"
          >Required</span
        >
        <span *ngIf="check('CurrentQuarter', 'min')" class="badge badge-danger"
          >Minimum of 1</span
        >
        <span *ngIf="check('CurrentQuarter', 'max')" class="badge badge-danger"
          >Maximum of 4</span
        >
      </span>
      <input pms-number-control [mask]="'9'" formControlName="CurrentQuarter" />
    </div>

    <div class="list-group-item">
      <span>Allow users to edit current financial-year's plans</span>
      <input type="checkbox" formControlName="AllowCurrentFinYearPlanEdit" />
    </div>

    <div class="list-group-item">
      <span>Allow users to edit next financial-year's plans</span>
      <input type="checkbox" formControlName="AllowNextFinYearPlanEdit" />
    </div>

    <div class="list-group-item" *ngIf="canImportPlans$ | async">
      <span>Import plans from previous year</span>
      <button
        class="btn btn-sm btn-outline-primary"
        (click)="importPlansFromPreviousYear()"
      >
        <i class="fa fa-file-import"></i>
        Import
      </button>
    </div>

    <div class="list-group-divider"></div>

    <div class="list-group-header">Interface Settings</div>

    <div class="list-group-item">
      <span>Quick filter on plans page</span>
      <input type="checkbox" formControlName="QuickFilterPlans" />
    </div>

    <div class="list-group-item">
      <span>Show numbers on stars in reviews</span>
      <input type="checkbox" formControlName="AddNumbersToReviewStars" />
    </div>

    <div class="footer" style="padding: 15px">
      <button class="btn btn btn-danger m-auto" (click)="save()">
        <i class="fa fa-save"></i>Save
      </button>
    </div>

    <div class="footer">
      <div
        *ngIf="showAlert"
        class="alert alert-success w-100 mt-3"
        role="alert"
      >
        {{ alertText }}
      </div>
    </div>
  </div>
  <!-- /body -->
</pms-page>
