import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Claims, Review } from './review.models';

@Injectable({
  providedIn: 'root',
})
export class ReviewService {
  constructor(private http: HttpClient) {}

  get$(id: number) {
    return this.http.get<Review>(`api/reviews/${id}`);
  }

  assessment$(id: number) {
    return this.http.get<any>(`api/reviews/${id}/assessment`);
  }

  update$(item: Review) {
    return this.http.put<Review>(`api/reviews/${item.Id}`, item);
  }

  signAsEmployee$(id: number, password: string) {
    return this.http.put<Claims>(`api/reviews/${id}/employee/sign`, {
      Password: password,
    });
  }

  signAsManager$(id: number, password: string) {
    return this.http.put<Claims>(`api/reviews/${id}/manager/sign`, {
      Password: password,
    });
  }

  reset$(id: number) {
    return this.http.put<void>(`api/reviews/${id}/reset`, {});
  }
}
