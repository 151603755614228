import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, filter, map, of, switchMap } from 'rxjs';
import { SetCurrentYear, SetYears, YearKvp, YearsState } from './years.state';

@Injectable({
  providedIn: 'root',
})
export class YearsService {
  constructor(private http: HttpClient, private store: Store) {
    this.http
      .get<YearKvp[]>(`api/years/dictionary`)
      .pipe(map((years) => years.filter((year) => year.Key > 2010)))
      .subscribe((years) => {
        this.store.dispatch(new SetYears(years));
        this.store.dispatch(new SetCurrentYear(years[years.length - 1].Key));
      });
  }

  public getYears$(): Observable<YearKvp[]> {
    return this.store.select(YearsState.years).pipe(
      filter((years) => years.length > 0),
      switchMap((years) => of(years as YearKvp[]))
    );
  }

  public getYearsWithAll$(): Observable<YearKvp[]> {
    return this.store.select(YearsState.years).pipe(
      filter((years) => years.length > 0),
      switchMap((years) => {
        years = [{ Key: 0, Value: 'All' }, ...years];
        return of(years as YearKvp[]);
      })
    );
  }

  public getCurrentYear$(): Observable<number> {
    return this.store.select(YearsState.currentYear).pipe(
      filter((year) => year !== null),
      switchMap((year) => of(year as number))
    );
  }
}
