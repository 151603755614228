<tr *ngIf="(loading$ | async) === false">
  <td>{{ year }}</td>
  <td *ngFor="let ctrl of milestoneForms.controls">
    <span *ngIf="!editing">{{ ctrl.value.Description }}</span>
    <span [hidden]="!editing" [formGroup]="$any(ctrl)">
      <fieldset class="pms-fieldset form-group nc-field mb-0">
        <textarea rows="1" formControlName="Description"></textarea>
      </fieldset>
    </span>
  </td>
  <td>
    <div class="btn-group btn-group-sm">
      <ng-container *ngIf="!editing">
        <button class="btn btn-sm btn-primary" (click)="edit()">
          <i class="fa fa-pen"></i>
        </button>
        <button (click)="remove()" class="btn btn-sm btn-outline-primary">
          <i class="fa fa-trash"></i>
        </button>
      </ng-container>
      <ng-container *ngIf="editing">
        <button
          [disabled]="form.disabled || !form.valid"
          (click)="save()"
          class="btn btn-sm btn-primary"
        >
          Save
        </button>
        <button (click)="cancel()" class="btn btn-sm btn-outline-primary">
          Cancel
        </button>
      </ng-container>
    </div>
  </td>
</tr>
<tr *ngIf="loading$ | async">
  <td colspan="5">
    <pms-progress
      class="block"
      [text]="'Loading'"
      [barWidth]="'60%'"
    ></pms-progress>
  </td>
</tr>
