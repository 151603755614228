<pms-page
  title="{{ title || 'Performance Plan' }}"
  [subTitle]="'Performance Plan Details'"
  [icon]="'table'"
  [loading]="(loading$ | async)!"
>
  <!-- toolbar -->
  <span toolbar class="flex items-center gap-1">
    <button
      class="whitespace-nowrap btn btn-sm btn-outline-success"
      (click)="print()"
    >
      <i class="fa fa-print"></i>Print Plan
    </button>
    <button
      class="whitespace-nowrap btn btn-sm btn-outline-success"
      (click)="back()"
    >
      Save and Return
    </button>
  </span>
  <!-- /toolbar -->
  <div body id="plans" *ngIf="plan$ | async as plan">
    <div class="alert alert-danger" role="alert" *ngIf="plan.IsSignedEmployee">
      This plan has been signed by the employee and cannot be edited.
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="plan.IsSignedManager">
      This plan has been signed by the manager and cannot be edited.
    </div>
    <div *ngFor="let type of types$ | async">
      <div class="nav nav-bar table-toolbar !mb-[10px]">
        <h5>{{ type.Value }}</h5>
        <div *ngIf="plan.CanEdit" class="nav inline-form ml-auto">
          <button class="btn btn-sm btn-danger" (click)="add(type.Key)">
            <i class="fa fa-plus"></i>
            Add
          </button>

          <div class="dropdown ml-2">
            <button
              class="btn btn-sm btn-outline-danger dropdown-toggle"
              data-toggle="dropdown"
            >
              <i class="fa fa-file-import"></i>
              Import
            </button>
            <div class="dropdown-menu">
              <button
                *ngIf="type.Key === 'IDP'"
                (click)="importFromProjects()"
                class="dropdown-item"
              >
                Copy indicators from <b>project list</b>
              </button>
              <button
                *ngIf="type.Key !== 'IDP'"
                (click)="import('year', type.Key)"
                class="dropdown-item"
              >
                Copy indicators from <b>previous year</b>
              </button>
              <button
                *ngIf="type.Key !== 'IDP'"
                (click)="import('employee', type.Key)"
                class="dropdown-item"
              >
                Copy indicators from a <b>colleague's plan</b>
              </button>
            </div>
          </div>
        </div>
      </div>
      <table class="nc-table table mb-5">
        <thead>
          <tr>
            <td class="id">Id</td>
            <td class="kpa" *ngIf="type.Key !== 'CCR'">
              KPA / <i>Strategy</i>
            </td>
            <td class="kpi" *ngIf="type.Key !== 'CCR'">
              <span>KPI</span>
              <span *ngIf="type.Key === 'OP'"> / <i>Annual Target</i></span>
              <span *ngIf="type.Key === 'IDP'"> / <i>Project</i></span>
            </td>
            <td class="kpa" *ngIf="type.Key === 'CCR'">CCR</td>
            <td class="kpi" *ngIf="type.Key === 'CCR'">Annual Target</td>
            <td class="weight">Weight</td>
            <td class="weight">Adjusted Weight</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="indicators$ | async as data">
            <ng-container *ngIf="hasIndicators(type.Key, data); else noItems">
              <tr *ngFor="let ind of data">
                <ng-container *ngIf="ind.IndicatorType === type.Key">
                  <td>{{ ind.Id }}</td>
                  <td *ngIf="type.Key !== 'CCR'">
                    <div>{{ ind.KPA }}</div>
                    <div>
                      <i class="text-xs">{{ ind.DevStrategy }}</i>
                    </div>
                  </td>
                  <td *ngIf="type.Key !== 'CCR'">
                    <div>{{ ind.Name }}</div>
                    <div *ngIf="type.Key === 'IDP'">
                      <i class="text-xs">{{ ind.Project }}</i>
                    </div>
                    <div *ngIf="type.Key === 'OP'">
                      <i class="text-xs">{{ ind.TargetAnnual }}</i>
                    </div>
                  </td>
                  <td *ngIf="type.Key === 'CCR'">
                    <div>{{ ind.CCR }}</div>
                    <div>
                      <i class="text-xs">{{ ind.CCRDesc }}</i>
                    </div>
                  </td>
                  <td *ngIf="type.Key === 'CCR'">
                    <div>{{ ind.TargetAnnual }}</div>
                  </td>
                  <td>{{ ind.Weight * 100 | number : '1.2-2' }}%</td>
                  <td>{{ adjusted(ind) * 100 | number : '1.2-2' }}%</td>
                  <td>
                    <div class="whitespace-nowrap btn-group btn-group-sm">
                      <button
                        *ngIf="plan.CanEdit"
                        (click)="edit(ind)"
                        class="btn btn-sm btn-primary"
                      >
                        <i class="fa fa-pen"></i>Edit
                      </button>
                      <button
                        (click)="risks(ind)"
                        class="whitespace-nowrap btn btn-sm btn-outline-primary"
                      >
                        <i class="fa fa-bolt"></i>Risks ({{
                          ind.NoOfRisks | number : '1.0-0'
                        }})
                      </button>
                      <button
                        *ngIf="plan.CanEdit"
                        (click)="remove(ind)"
                        class="btn btn-sm btn-outline-primary"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </div></td
                ></ng-container>
              </tr>
            </ng-container>
            <ng-template #noItems>
              <tr>
                <td colspan="7" class="text-right">No items</td>
              </tr>
            </ng-template>
          </ng-container>
        </tbody>
      </table>
      <br />
    </div>

    <h3>Agreement</h3>

    <div class="agreement">
      <div class="agreement-text">
        <h1 id="introduction">INTRODUCTION</h1>

        <ul>
          <li>
            The Employer has entered into a contract of employment with the
            Employee.
          </li>

          <li>
            The Employer requires that the parties conclude an annual
            performance agreement.
          </li>

          <li>
            The parties wish to ensure that they are clear about the goals to be
            achieved, and secure the commitment of the Employee to a set of
            outcomes that will achieve the Entity's policy goals.
          </li>
        </ul>

        <h1 id="purpose">PURPOSE</h1>

        <ul>
          <li>
            Specify objectives and targets defined and agreed with the Employee
            and to communicate to the Employee the Employer’s expectations
            regarding his performance and accountabilities in alignment with the
            5-year Strategic Plan, the Annual Business Plan, the Service Level
            Agreements, and the Budget;
          </li>

          <li>monitor and measure performance against set targeted outputs;</li>

          <li>
            use this Performance Agreement as the basis for assessing whether
            the Employee has met the performance expectations applicable to this
            position;
          </li>

          <li>
            appropriately reward the Employee in the event of outstanding
            performance; and
          </li>

          <li>
            give effect to the Employer's commitment to a performance-orientated
            relationship with its employee.
          </li>
        </ul>

        <h1 id="commencementandduration">COMMENCEMENT AND DURATION</h1>

        <ul>
          <li>
            This Agreement shall commence on and shall remain in force until the
            end of the financial year; thereafter a new Performance Agreement
            shall be concluded between the parties annually.
          </li>

          <li>
            This Agreement shall terminate on the termination of the Employee's
            contract of employment, for any reason.
          </li>

          <li>
            The content of this Agreement may be revised at any time during the
            above-mentioned period, to determine the applicability of the
            matters agreed upon.
          </li>

          <li>
            If at any time during the validity of this Agreement the work
            environment alters (whether as a result of the local government or
            board decisions, or otherwise) to the extent that the content of
            this Agreement is no longer appropriate, the content shall be
            revised.
          </li>
        </ul>

        <h1 id="personaldevelopmentplan">Personal Development Plan</h1>

        <p>
          Personal growth and development needs identified during any
          performance review discussion must be documented in a Personal
          Development Plan, as well as the actions agreed to, and implementation
          must take place within set timeframes.
        </p>

        <h1 id="belowstandardperformance">Below standard Performance</h1>

        <p>
          The Performance Management Process allows for constant coaching and
          performance conversations between manager and employee. This allows
          for early identification of performance gaps and intervening
          proactively. Consistent Poor Performance identified will be documented
          in the Poor Performance Plan, as well as interventions to be applied,
          standard of and timeframes for the interventions.
        </p>
      </div>
    </div>

    <h3>Sign-off</h3>

    <div
      class="alert alert-danger"
      role="alert"
      *ngIf="(indicators$ | async)?.length === 0"
    >
      This plan has no indicators and cannot be signed off.
    </div>

    <div *ngIf="((indicators$ | async)?.length ?? 0) > 0">
      <pms-signature
        [text]="
          'By entering my password, I acknowledge that I have read and understood what is outlined in this Performance Agreement. I understand the Employer’s expectations regarding my performance and accountabilities as outlined in the Agreement.'
        "
        [isSigned]="plan.IsSignedEmployee"
        [signedDate]="plan.SignedDateEmployee"
        [name]="plan.FullName"
        designation="Employee"
        (signed)="signOffEmployee($event)"
      ></pms-signature>

      <pms-signature
        [text]="
          'By entering my password, I acknowledge that I have read and understood what is outlined in this Performance Agreement. I understand the Employer’s expectations regarding my performance and accountabilities as outlined in the Agreement.'
        "
        [isSigned]="plan.IsSignedManager"
        [signedDate]="plan.SignedDateManager"
        [name]="plan.ManagerFullName"
        designation="Manager"
        (signed)="signoffManager($event)"
      ></pms-signature>
    </div>
  </div>
</pms-page>
