import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';

import {
  InputFieldComponent,
  Kvp,
  SelectFieldComponent,
  ValidationService,
} from '@pmslogic/widgets';
import { CcrTypesService } from './ccr-types/ccr-types.service';
import { Ccr } from './ccrs.state';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputFieldComponent,
    SelectFieldComponent,
  ],
  providers: [ValidationService],
  templateUrl: './ccr.component.html',
  styles: [
    `
      :host {
        display: block;
        width: 300px;
      }
    `,
  ],
})
export class CcrComponent implements OnInit {
  form: FormGroup;
  types$: Observable<Kvp[]>;

  constructor(
    public dialogRef: DialogRef<Ccr | null>,
    @Inject(DIALOG_DATA) private data$: Observable<Partial<Ccr>>,
    private ccrTypesService: CcrTypesService,
    validation: ValidationService
  ) {
    this.form = new FormGroup({
      Id: new FormControl(),
      Title: new FormControl('', [validation.required()]),
      Description: new FormControl(''),
      CCRTypeId: new FormControl<number | null>(null, [validation.required()]),
    });

    this.types$ = this.ccrTypesService.dictionary$();

    this.form.disable();
  }
  ngOnInit(): void {
    this.data$.subscribe((item) => {
      this.form.patchValue(item);
      this.form.enable();
    });
  }
  save() {
    this.dialogRef.close(this.form.value);
  }

  cancel() {
    this.dialogRef.close(null);
  }
}
