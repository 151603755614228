<ng-container [formGroup]="form">
  <td class="indicator-score">
    {{ item.IndicatorId }}
  </td>

  <td class="indicator-score">
    <!--      non-ccr      -->
    <ng-container *ngIf="item.Type !== 'CCR'">
      <div>
        <b>
          <span>Indicator</span>
          <span *ngIf="item.Type === 'IDP'"> for {{ item.ProjectNo }} </span>
        </b>
      </div>
      <div>{{ item.Name }}</div>
    </ng-container>

    <!--      ccr      -->
    <ng-container *ngIf="item.Type === 'CCR'">
      <div><b>Core Competency Requirement</b></div>
      <div>{{ item.CCRTitle }}</div>
      <div>
        <i>{{ item.CCRDesc }}</i>
      </div>
    </ng-container>
    <br />
    <div><b>Target</b></div>
    <div>{{ item.Target }}</div>

    <div class="nav form-inline indicator-score-buttons">
      <span class="is-complete">
        <button
          (click)="completionStatus()"
          *ngIf="(complete$ | async) === true"
          class="btn btn-outline-success"
        >
          <i class="fa fa-check"></i> Complete
        </button>
        <button
          (click)="completionStatus()"
          *ngIf="(complete$ | async) === false"
          class="btn btn-outline-warning"
        >
          <i class="fa fa-times"></i> Incomplete
        </button>
      </span>

      <button (click)="history()" class="btn btn-outline-success ml-auto">
        <i class="fa fa-history"></i>History
      </button>
       
      <button (click)="files()" class="btn btn-outline-success ml-auto">
        <i class="fa fa-paperclip"></i>Files ({{
          item.NoOfFiles | number : '1.0-0'
        }})
      </button>
    </div>
  </td>

  <td class="indicator-score">
    <div
      class="staff-comments"
      *ngIf="item.StaffComments && item.StaffComments.length > 0"
    >
      <div class="nc-field-label">Staff Comments</div>
      <table>
        <tr *ngFor="let s of item.StaffComments">
          <td>{{ s.StaffName }}:</td>
          <td>{{ s.Comment || 'No comment yet' }}</td>
        </tr>
      </table>
    </div>

    <pms-input-field
      ngDefaultControl
      [readonly]="form.get('Actual')?.disabled === true"
      label="Personal Comments"
      [rows]="3"
      [formControl]="$any(form.get('Actual'))"
      [placeholder]="'add your comments here'"
    ></pms-input-field>

    <pms-input-field
      ngDefaultControl
      [readonly]="form.get('UnderPerformanceReason')?.disabled === true"
      label="Reason for Underperformance"
      [rows]="2"
      [formControl]="$any(form.get('UnderPerformanceReason'))"
      [placeholder]="'add your comments here'"
    ></pms-input-field>

    <pms-input-field
      ngDefaultControl
      [readonly]="form.get('MitigatingMeasures')?.disabled === true"
      label="Mitigating Measures Put in Place"
      [rows]="2"
      [formControl]="$any(form.get('MitigatingMeasures'))"
      [placeholder]="'add your comments here'"
    ></pms-input-field>

    <pms-input-field
      ngDefaultControl
      [readonly]="form.get('WhenTargetMet')?.disabled === true"
      label="Envisioned Time when Targets will be Met"
      [rows]="2"
      [formControl]="$any(form.get('WhenTargetMet'))"
      [placeholder]="'add your comments here'"
    ></pms-input-field>

    <pms-review-stars
      *ngIf="form.get('Score')"
      [formControl]="$any(form.get('Score'))"
    ></pms-review-stars>
  </td>

  <td class="indicator-score">
    <pms-input-field
      ngDefaultControl
      [readonly]="form.get('Comment')?.disabled === true"
      label="Manager Comments"
      [rows]="2"
      [formControl]="$any(form.get('Comment'))"
      [placeholder]="'add your comments here'"
    ></pms-input-field>

    <pms-input-field
      ngDefaultControl
      [readonly]="form.get('RemedialAction')?.disabled === true"
      label="Performance Improvement Actions"
      [rows]="2"
      [formControl]="$any(form.get('RemedialAction'))"
      [placeholder]="'add your comments here'"
    ></pms-input-field>

    <pms-review-stars
      *ngIf="form.get('FinalScore')"
      [formControl]="$any(form.get('FinalScore'))"
    ></pms-review-stars>
  </td>
</ng-container>
