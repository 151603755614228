import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';

// --- Model ---
export interface Kpa {
  Id: number;
  Description: string;
}

// --- Actions ---
export class SetKpas {
  static readonly type = '[Kpas] SetKpas';
  constructor(public items: Kpa[]) {}
}

export class AddKpa {
  static readonly type = '[Kpas] AddKpa';
  constructor(public item: Kpa) {}
}

export class UpdateKpa {
  static readonly type = '[Kpas] UpdateKpa';
  constructor(public item: Kpa) {}
}

export class RemoveKpa {
  static readonly type = '[Kpas] RemoveKpa';
  constructor(public id: number) {}
}

// --- State ---
export interface KpasStateModel {
  items?: Kpa[];
}

@State<KpasStateModel>({
  name: 'KpasState',
})
@Injectable()
export class KpasState {
  @Selector()
  static items(state: KpasStateModel) {
    return state.items;
  }

  @Action(SetKpas)
  set(ctx: StateContext<KpasStateModel>, { items }: SetKpas) {
    ctx.patchState({
      items: items,
    });
  }

  @Action(AddKpa)
  add(ctx: StateContext<KpasStateModel>, { item }: AddKpa) {
    ctx.setState(
      patch({
        items: append([item]),
      })
    );
  }

  @Action(UpdateKpa)
  update(ctx: StateContext<KpasStateModel>, { item }: UpdateKpa) {
    ctx.setState(
      patch({
        items: updateItem<Kpa>((itm) => itm.Id == item.Id, item),
      })
    );
  }

  @Action(RemoveKpa)
  remove(ctx: StateContext<KpasStateModel>, { id }: RemoveKpa) {
    ctx.setState(
      patch({
        items: removeItem<Kpa>((itm) => itm.Id == id),
      })
    );
  }
}
