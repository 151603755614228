<fieldset class="pms-fieldset form-group nc-field">
  <label>
    <span> {{ label }}</span>
    <small class="p-error" *ngIf="errorMessage$ | async">
      *{{ errorMessage$ | async }}</small
    >
  </label>
  <select
    class="form-control input-sm nc-select-control"
    [formControl]="formControl!"
  >
    <option *ngFor="let t of kvp" [ngValue]="t.Key">
      {{ t.Value }}
    </option>
  </select>
</fieldset>
