<pms-page
  id="review-form"
  title="{{ title }}"
  subTitle="Review Details"
  icon="clipboard-check"
  [loading]="(loading$ | async)!"
>
  <span toolbar>
    <button (click)="print()" class="btn btn-sm btn-outline-success">
      <i class="fa fa-print"></i>
      Print
    </button>

    &nbsp;

    <button (click)="diary()" class="btn btn-sm btn-outline-success">
      <i class="fa fa-calendar"></i>
      Review Diary
    </button>

    &nbsp;

    <button (click)="back()" class="btn btn-sm btn-outline-success">
      Save and Return
    </button>
  </span>

  <div body>
    <div id="indicator-tables" *ngFor="let type of types$ | async">
      <h4>{{ type.Value }}</h4>

      <table class="nc-table table">
        <thead>
          <tr>
            <td>Id</td>
            <td>Indicator/Target</td>
            <td>Personal Assessment</td>
            <td>Final Assessment</td>
          </tr>
        </thead>

        <tbody>
          <ng-container *ngFor="let score of scores$ | async">
            <tr
              pms-score
              *ngIf="score.Type === type.Key"
              [item]="score"
              [claims]="(item$ | async)?.Claims"
              (changed)="onScoreChanged($event)"
              state="state"
            ></tr>
          </ng-container>
        </tbody>
      </table>
    </div>

    <h4>Comments</h4>

    <pms-input-field
      label="Were any activities undertaken outside of the plan?"
      ngDefaultControl
      [readonly]="form.get('OutOfPlanActivities')?.disabled === true"
      [formControl]="$any(form.get('OutOfPlanActivities'))"
      [rows]="4"
      placeholder="add comments here"
    ></pms-input-field>

    <pms-input-field
      label="Was there enough time to achieve the activities?"
      ngDefaultControl
      [readonly]="form.get('EnoughTime')?.disabled === true"
      [formControl]="$any(form.get('EnoughTime'))"
      [rows]="4"
      placeholder="add comments here"
    ></pms-input-field>

    <pms-input-field
      label="Manager Comments"
      ngDefaultControl
      [readonly]="form.get('ManagerComment')?.disabled === true"
      [formControl]="$any(form.get('ManagerComment'))"
      [rows]="4"
      placeholder="add comments here"
    ></pms-input-field>

    <pms-input-field
      label="Employee Comments"
      ngDefaultControl
      [readonly]="form.get('EmployeeComment')?.disabled === true"
      [formControl]="$any(form.get('EmployeeComment'))"
      [rows]="4"
      placeholder="add comments here"
    ></pms-input-field>

    <h4>Personal Development Plan</h4>

    <pms-pdp-items
      [employeeId]="(item$ | async)?.EmployeeId"
      [claims]="(item$ | async)?.Claims"
    ></pms-pdp-items>

    <h3>Sign-off</h3>

    <div
      class="alert alert-danger"
      role="alert"
      *ngIf="(isComplete$ | async) === false"
    >
      This review cannot be signed off until all indicators have been completed.
    </div>

    <div *ngIf="isComplete$ | async">
      <pms-signature
        [text]="
          'I agree that this review has been conducted in a satisfactory manner.'
        "
        [isSigned]="(item$ | async)?.IsSignedEmployee === true"
        [signedDate]="(item$ | async)?.SignedDateEmployee"
        [name]="(item$ | async)?.FullName ?? '...'"
        designation="Employee"
        (signed)="signOffEmployee($event)"
      ></pms-signature>

      <pms-signature
        [text]="
          'I agree that this review has been conducted in a satisfactory manner.'
        "
        [isSigned]="(item$ | async)?.IsSignedManager === true"
        [signedDate]="(item$ | async)?.SignedDateManager"
        [name]="(item$ | async)?.ManagerFullName ?? '...'"
        designation="Manager"
        (signed)="signOffManager($event)"
      ></pms-signature>
    </div>

    <div class="notify-save" *ngIf="saving$ | async">
      <i class="fa fa-save"></i>
    </div>
  </div>
</pms-page>
