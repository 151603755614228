import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

// -- Models ---
export interface YearKvp {
  Key: number;
  Value: string;
}

// --- Actions ---
export class SetCurrentYear {
  static readonly type = '[Years] SetCurrentYear';
  constructor(public currentYear: number) {}
}

export class SetYears {
  static readonly type = '[Years] SetYears';
  constructor(public years: YearKvp[]) {}
}

// --- State ---
export interface YearsStateModel {
  currentYear: number | null;
  years: YearKvp[];
}

@State<YearsStateModel>({
  name: 'years',
  defaults: {
    currentYear: null,
    years: [],
  },
})
@Injectable()
export class YearsState {
  @Selector()
  static currentYear(state: YearsStateModel) {
    return state.currentYear;
  }

  @Selector()
  static years(state: YearsStateModel) {
    return state.years;
  }

  @Action(SetCurrentYear)
  setCurrentYear(
    ctx: StateContext<YearsStateModel>,
    { currentYear }: SetCurrentYear
  ) {
    ctx.patchState({
      currentYear: currentYear,
    });
  }

  @Action(SetYears)
  setYears(ctx: StateContext<YearsStateModel>, { years }: SetYears) {
    ctx.patchState({
      years: years,
    });
  }
}
