import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, switchMap } from 'rxjs';
import {
  AddMilestone,
  Milestone,
  MilestonesState,
  RemoveMilestone,
  SetMilestones,
  UpdateMilestone,
} from './milestones.state';

@Injectable({
  providedIn: 'root',
})
export class MilestonesService {
  constructor(private http: HttpClient, private store: Store) {
    this.http
      .get<Milestone[]>(`api/milestones`)
      .subscribe((items) => this.store.dispatch(new SetMilestones(items)));
  }

  list$() {
    return this.store.select(MilestonesState.items);
  }

  get$(id: number) {
    return this.http.get<Milestone>(`api/milestones/${id}`);
  }

  add$(item: Partial<Milestone>) {
    return this.http
      .post<Milestone>(`api/milestones`, item)
      .pipe(
        switchMap((result) =>
          this.store.dispatch(new AddMilestone(result)).pipe(map(() => result))
        )
      );
  }
  update$(item: Partial<Milestone>) {
    return this.http
      .put<Milestone>(`api/milestones/${item.Id}`, item)
      .pipe(
        switchMap((result) =>
          this.store
            .dispatch(new UpdateMilestone(result))
            .pipe(map(() => result))
        )
      );
  }
  remove$(id: number) {
    return this.http
      .delete<void>(`api/milestones/${id}`)
      .pipe(switchMap(() => this.store.dispatch(new RemoveMilestone(id))));
  }
}
