<div class="signature">
  <div class="row">
    <div class="col-2"></div>

    <div class="col-8">
      <div>{{ text }}</div>
    </div>

    <div class="col-2"></div>
  </div>

  <div class="row">
    <div class="col-3"></div>

    <form class="col-6" [formGroup]="form">
      <div *ngIf="!isSigned">
        <input
          #passwordInput
          [formControl]="$any(form.controls['password'])"
          readonly
          (focus)="passwordInput.removeAttribute('readonly')"
          type="password"
          name="password"
          autocomplete="off"
        />
        <button class="btn btn-danger" (click)="signoff()">
          <i class="fa fa-pen-nib"></i>
          Sign-off
        </button>
      </div>
      <div *ngIf="isSigned" class="flourish">
        <span>{{ name }}</span
        >       <i class="fa fa-check"></i>
      </div>
    </form>

    <div class="col-3"></div>
  </div>

  <div class="row">
    <div class="col-3"></div>

    <div class="col-5 details">
      <div>{{ name }} ({{ designation }})</div>
      <div *ngIf="signedDate">{{ signedDate | date : 'dd MMM yyyy' }}</div>
    </div>

    <div class="col-3"></div>
  </div>
</div>
