import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  Observable,
  catchError,
  filter,
  finalize,
  of,
  take,
  throwError,
} from 'rxjs';
import { ConfirmDialogComponent } from '../shared/confirm-dialog.component';
import { PageComponent } from '../shared/layout/page.component';
import { LoadingService } from '../shared/loading/loading.service';
import { MessageDialogComponent } from '../shared/message-dialog.component';
import { isDefined } from '../util/common';
import { CcrComponent } from './ccr.component';
import { CcrsService } from './ccrs.service';
import { Ccr } from './ccrs.state';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, PageComponent, DialogModule],
  templateUrl: './ccrs.component.html',
})
export class CcrsComponent {
  loading$ = this.loadingService.isLoading$(this);
  list$: Observable<Ccr[] | undefined>;

  constructor(
    private service: CcrsService,
    private dialog: Dialog,
    private loadingService: LoadingService
  ) {
    this.loadingService.startLoading(this);

    this.list$ = this.service.list$();

    this.service
      .list$()
      .pipe(
        filter((f) => isDefined(f)),
        take(1),
        finalize(() => this.loadingService.stopLoading(this))
      )
      .subscribe();
  }

  add() {
    this.dialog
      .open(CcrComponent, { data: of({}) })
      .closed.subscribe((result) => {
        if (result) {
          this.loadingService.startLoading(this);
          this.service
            .add$(result as Ccr)
            .pipe(finalize(() => this.loadingService.stopLoading(this)))
            .subscribe();
        }
      });
  }

  edit(id: number) {
    this.dialog
      .open(CcrComponent, { data: this.service.get$(id), autoFocus: false })
      .closed.subscribe((result) => {
        if (result) {
          this.loadingService.startLoading(this);
          this.service
            .update$(result as Ccr)
            .pipe(finalize(() => this.loadingService.stopLoading(this)))
            .subscribe();
        }
      });
  }
  remove(id: number) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          text: 'Are you sure you want to delete this CCR?',
        },
      })
      .closed.subscribe((result) => {
        if (result as boolean) {
          this.loadingService.startLoading(this);
          this.service
            .remove$(id)
            .pipe(
              catchError(() => {
                this.dialog.open(MessageDialogComponent, {
                  data: {
                    title: 'Request Denied',
                    text: 'This record is in use and cannot be deleted.',
                  },
                });
                return throwError(() => new Error(`Delete failed [id=${id}]`));
              })
            )
            .pipe(finalize(() => this.loadingService.stopLoading(this)))
            .subscribe();
        }
      });
  }
}
