import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Attachment } from './attachments.models';

@Injectable({
  providedIn: 'root',
})
export class AttachmentsService {
  constructor(private http: HttpClient, private store: Store) {}

  list$(path: string, parentId: number) {
    return this.http.get<Attachment[]>(`api/${path}?parentId=${parentId}`);
  }

  upload$(path: string, parentId: number, file: File) {
    const data = new FormData();
    data.append('file', file);
    data.append('data', JSON.stringify({ ParentId: parentId }));
    return this.http.post<Attachment>(`api/${path}`, data);
  }

  download$(id: string) {
    return this.http.get<Blob>(`api/files/${id}`, {
      responseType: 'arraybuffer' as 'json',
    });
  }

  approve$(path: string, id: number, val?: boolean) {
    return this.http.put<Attachment>(`api/${path}/${id}/approve`, val);
  }

  remove$(path: string, id: number) {
    return this.http.delete<void>(`api/${path}/${id}`);
  }
}
