import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FinyearPipe } from '../../util/pipes/finyear.pipe';
import { ReviewService } from '../review/review.service';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, FinyearPipe],
  templateUrl: './assessment-dialog.component.html',
  styles: [
    `
      :host {
        display: block;
        width: 800px;
      }
    `,
  ],
})
export class AssessmentDialogComponent {
  model: any;

  constructor(
    public dialogRef: DialogRef<boolean>,
    reviewService: ReviewService,
    @Inject(DIALOG_DATA) id: number
  ) {
    reviewService.assessment$(id).subscribe((model) => (this.model = model));
  }
}
