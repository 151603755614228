import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Kvp } from '@pmslogic/widgets';
import { map, switchMap } from 'rxjs';
import {
  AddStrategy,
  RemoveStrategy,
  SetStrategies,
  StrategiesState,
  Strategy,
  UpdateIsCurrentStrategy,
  UpdateStrategy,
} from './strategies.state';

@Injectable({
  providedIn: 'root',
})
export class StrategiesService {
  constructor(private http: HttpClient, private store: Store) {
    this.http
      .get<Strategy[]>(`api/strategies`)
      .subscribe((items) => this.store.dispatch(new SetStrategies(items)));
  }

  list$() {
    return this.store.select(StrategiesState.items);
  }

  dictionary$() {
    return this.list$().pipe(
      map((items) =>
        (items ?? []).map((item) => {
          return { Key: item.Id, Value: item.Description } as Kvp;
        })
      )
    );
  }

  get$(id: number) {
    return this.http.get<Strategy>(`api/strategies/${id}`);
  }

  add$(item: Partial<Strategy>) {
    return this.http
      .post<Strategy>(`api/strategies`, item)
      .pipe(
        switchMap((result) =>
          this.store.dispatch(new AddStrategy(result)).pipe(map(() => result))
        )
      );
  }

  update$(item: Strategy) {
    return this.http
      .put<Strategy>(`api/strategies/${item.Id}`, item)
      .pipe(
        switchMap((result) =>
          this.store
            .dispatch(new UpdateStrategy(result))
            .pipe(map(() => result))
        )
      );
  }
  updateIsCurrent$(id: number, isCurrent: boolean) {
    return this.http
      .put<boolean>(`api/strategies/${id}/iscurrent`, isCurrent)
      .pipe(
        switchMap((result) =>
          this.store
            .dispatch(new UpdateIsCurrentStrategy(id, isCurrent))
            .pipe(map(() => result))
        )
      );
  }

  remove$(id: number) {
    return this.http
      .delete<void>(`api/strategies/${id}`)
      .pipe(switchMap(() => this.store.dispatch(new RemoveStrategy(id))));
  }
}
