import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { ProjectInfo } from './project.models';

// --- Actions ---
export class SetProjects {
  static readonly type = '[Projects] SetProjects';
  constructor(public items: ProjectInfo[]) {}
}

export class AddProject {
  static readonly type = '[Projects] AddProject';
  constructor(public item: ProjectInfo) {}
}

export class UpdateProject {
  static readonly type = '[Projects] UpdateProject';
  constructor(public item: ProjectInfo) {}
}

export class RemoveProject {
  static readonly type = '[Projects] RemoveProject';
  constructor(public id: number) {}
}
// --- State ---
export interface ProjectsStateModel {
  items?: ProjectInfo[];
}

@State<ProjectsStateModel>({
  name: 'ProjectsState',
})
@Injectable()
export class ProjectsState {
  @Selector()
  static items(state: ProjectsStateModel) {
    return state.items;
  }

  @Action(SetProjects)
  set(ctx: StateContext<ProjectsStateModel>, { items }: SetProjects) {
    ctx.patchState({
      items: items,
    });
  }

  @Action(AddProject)
  add(ctx: StateContext<ProjectsStateModel>, { item }: AddProject) {
    ctx.setState(
      patch({
        items: append([item]),
      })
    );
  }

  @Action(UpdateProject)
  update(ctx: StateContext<ProjectsStateModel>, { item }: UpdateProject) {
    ctx.setState(
      patch({
        items: updateItem<ProjectInfo>((itm) => itm.Id == item.Id, item),
      })
    );
  }

  @Action(RemoveProject)
  remove(ctx: StateContext<ProjectsStateModel>, { id }: RemoveProject) {
    ctx.setState(
      patch({
        items: removeItem<ProjectInfo>((itm) => itm.Id == id),
      })
    );
  }
}
export { ProjectInfo };
