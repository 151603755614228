import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

export interface DialogData {
  title: string;
  text: string;
  okText: string;
  cancelText: string;
}

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="modal-header">
      <h4 class="modal-title">{{ data.title }}</h4>
    </div>
    <div class="modal-body">
      <div>{{ data.text }}</div>
      <br />
    </div>
    <div class="modal-footer">
      <button
        class="btn btn-primary"
        type="button"
        (click)="dialogRef.close(true)"
      >
        {{ data.okText }}
      </button>
      <button
        class="btn btn-default"
        type="button"
        (click)="dialogRef.close(false)"
      >
        {{ data.cancelText }}
      </button>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 300px;
      }
    `,
  ],
})
export class ConfirmDialogComponent {
  data: DialogData = {
    title: 'Please Confirm',
    text: '',
    okText: 'Yes',
    cancelText: 'No',
  };

  constructor(
    public dialogRef: DialogRef<boolean>,
    @Inject(DIALOG_DATA) inputData: Partial<DialogData>
  ) {
    console.log('inputData', inputData);
    this.data = { ...this.data, ...inputData };
  }
}
