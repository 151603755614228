<table class="table nc-inner-table objectives !bg-neutral-100">
  <thead>
    <tr>
      <td class="w-[70px]">└──</td>
      <td>Objectives</td>
      <td class="w-[140px]">
        <button
          (click)="add()"
          class="btn btn-sm btn-outline-warning float-right"
        >
          <i class="fa fa-plus"></i>Add Objective
        </button>
      </td>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let item of (list$ | async) ?? [] | showall : showAll">
      <td></td>

      <td>
        <div class="flex grow justify-between">
          <div
            class="mr-auto"
            [style.text-decoration]="item.IsCurrent ? 'none' : 'line-through'"
          >
            {{ item.Description }}
          </div>

          <button (click)="expand(item.Id)" class="btn btn-sm bg-white italic">
            Strategies&nbsp;&nbsp;
            <i
              class="fa"
              [class.fa-angle-down]="item.expanded"
              [class.fa-angle-right]="!item.expanded"
            ></i>
          </button>
        </div>
        <div>
          <div *ngIf="item.expanded">
            <br />
            <pms-strategies
              [parentId]="item.Id"
              [showAll]="showAll"
              [isCurrent]="item.IsCurrent"
            ></pms-strategies>
          </div>
        </div>
      </td>

      <td class="w-[110px]">
        <div class="btn-group btn-group-sm">
          <button
            title="Edit"
            (click)="edit(item.Id)"
            class="btn btn-sm btn-primary"
          >
            <i class="fa fa-pen"></i>
          </button>
          <button
            title="Hide"
            *ngIf="item.IsCurrent"
            (click)="updateIsCurrent(item, false)"
            class="btn btn-sm btn-outline-primary"
          >
            <i class="fa fa-eye-slash"></i>
          </button>
          <button
            title="Show"
            *ngIf="!item.IsCurrent"
            (click)="updateIsCurrent(item, true)"
            class="btn btn-sm btn-outline-primary"
          >
            <i class="fa fa-eye"></i>
          </button>
          <button
            title="Delete"
            (click)="remove(item.Id)"
            class="btn btn-sm btn-outline-primary"
          >
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </td>
    </tr>
  </tbody>
</table>
