import { DIALOG_DATA, Dialog, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  InputFieldComponent,
  SelectFieldComponent,
  ValidationService,
} from '@pmslogic/widgets';
import { BehaviorSubject, Observable, map, of, switchMap, tap } from 'rxjs';
import { LoadingService } from '../../shared/loading/loading.service';
import { ProgressComponent } from '../../shared/progress/progress.component';
import { FinyearPipe } from '../../util/pipes/finyear.pipe';
import { GroupByPipe } from '../../util/pipes/group-by.pipe';
import { SortPipe } from '../../util/pipes/sort.pipe';
import { ProjectInfo } from '../project.models';
import { AddMilestoneComponent } from './add-milestone.component';
import { MilestoneYearComponent } from './milestone-year.component';
import { MilestoneComponent } from './milestone.component';
import { MilestonesService } from './milestones.service';
import { Milestone, MilestoneYear } from './milestones.state';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ValidationService],
  templateUrl: './milestones.component.html',
  styles: [
    `
      :host {
        display: block;
        width: 800px;
      }
    `,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputFieldComponent,
    SelectFieldComponent,
    FinyearPipe,
    GroupByPipe,
    SortPipe,
    MilestoneComponent,
    AddMilestoneComponent,
    ProgressComponent,
  ],
})
export class MilestonesComponent {
  loading$ = this.loadingService.isLoading$(this);
  list$ = new Observable<Milestone[]>();
  newYear$ = new BehaviorSubject<number | null>(null);

  existingYears: number[] = [];
  projectId: number | null = null;

  constructor(
    public dialogRef: DialogRef<number | null>,
    @Inject(DIALOG_DATA) private data$: Observable<Partial<ProjectInfo>>,
    private service: MilestonesService,
    private dialog: Dialog,
    private loadingService: LoadingService
  ) {
    this.loadingService.startLoading(this);

    //when the project changes, set this list$ to a filtered version of it
    this.list$ = this.data$.pipe(
      tap((project) => {
        if (!project.Id) return;
        this.projectId = project.Id;
      }),
      switchMap((project) => {
        return service.list$().pipe(
          map((m) => {
            return m.filter((f) => f.ProjectId == project.Id);
          })
        );
      }),
      tap((milestones) => {
        this.existingYears = [...new Set(milestones.map((m) => m.Year))];
        this.loadingService.stopLoading(this);
      })
    );
  }
  add() {
    this.dialog
      .open(MilestoneYearComponent, { data: of(this.existingYears) })
      .closed.subscribe((result) => {
        if (result) {
          this.newYear$.next((result as MilestoneYear).Year);
        } else {
          this.newYear$.next(null);
        }
      });
  }

  done() {
    this.dialogRef.close();
  }
}
