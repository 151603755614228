import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Kvp } from '@pmslogic/widgets';
import { Observable, map, switchMap } from 'rxjs';
import {
  AddKpa,
  Kpa,
  KpasState,
  RemoveKpa,
  SetKpas,
  UpdateKpa,
} from './kpas.state';
@Injectable({
  providedIn: 'root',
})
export class KpasService {
  constructor(private http: HttpClient, private store: Store) {
    this.http
      .get<Kpa[]>(`api/kpas`)
      .subscribe((items) => this.store.dispatch(new SetKpas(items)));
  }

  dictionary$(): Observable<Kvp[]> {
    return this.store.select(KpasState.items).pipe(
      map((items) =>
        (items || [])
          .map((item) => ({
            Key: item.Id,
            Value: item.Description,
          }))
          .sort((a, b) => a.Value.localeCompare(b.Value))
      )
    );
  }
  list$() {
    return this.store.select(KpasState.items);
  }

  get$(id: number) {
    return this.http.get<Kpa>(`api/kpas/${id}`);
  }

  add$(item: Partial<Kpa>) {
    return this.http
      .post<Kpa>(`api/kpas`, item)
      .pipe(
        switchMap((result) =>
          this.store.dispatch(new AddKpa(result)).pipe(map(() => result))
        )
      );
  }

  update$(item: Kpa) {
    return this.http
      .put<Kpa>(`api/kpas/${item.Id}`, item)
      .pipe(
        switchMap((result) =>
          this.store.dispatch(new UpdateKpa(result)).pipe(map(() => result))
        )
      );
  }

  remove$(id: number) {
    return this.http
      .delete<void>(`api/kpas/${id}`)
      .pipe(switchMap(() => this.store.dispatch(new RemoveKpa(id))));
  }
}
