<pms-page
  [title]="'Performance Plan(s)'"
  [subTitle]="'Manage your performance plan and those of your staff'"
  [icon]="'table'"
  [loading]="(loading$ | async)!"
>
  <!-- toolbar -->
  <span toolbar class="flex items-center" [formGroup]="filterForm">
    <span class="input-group input-group-sm w-auto mr-2">
      <span class="input-group-prepend">
        <span class="input-group-text">Year</span>
      </span>
      <select class="form-control" formControlName="year">
        <option *ngFor="let year of years$ | async" [ngValue]="year.Key">
          {{ year.Value }}
        </option>
      </select>
    </span>

    <div
      *ngIf="(permissions$ | async)?.CanFilterPlansByDept"
      class="input-group input-group-sm w-auto mr-2"
    >
      <span class="input-group-prepend">
        <span class="input-group-text">Department</span>
      </span>
      <select class="form-control max-w-[220px]" formControlName="dept">
        <option *ngFor="let dept of depts$ | async" [ngValue]="dept.Key">
          {{ dept.Value }}
        </option>
      </select>
    </div>
  </span>
  <!-- /toolbar -->

  <!-- body -->
  <div body id="plans">
    <h4 class="finyear-banner">{{ finyearTitle }}</h4>

    <div>
      <h5 *ngIf="(planSections$ | async)?.length === 0">
        <br /><br /><br />No Plans Found...
      </h5>

      <div *ngFor="let section of planSections$ | async">
        <h4>{{ section.key }}</h4>

        <div *ngFor="let dept of groupByDept(section.items)">
          <div>{{ dept.key }}</div>

          <table class="nc-table table">
            <thead>
              <tr>
                <td colspan="2">Name</td>
                <td>Updated</td>
                <td>Plan</td>
                <td>Qtr 1</td>
                <td>Qtr 2</td>
                <td>Qtr 3</td>
                <td>Qtr 4</td>
              </tr>
            </thead>

            <tbody>
              <tr *ngIf="dept.items.length > 1" class="summary-row name">
                <td class="name" colspan="3">
                  <b>{{ dept.key }}</b>
                </td>
                <td><pms-plan-summary-bar [model]="dept.items" /></td>
                <td>
                  <pms-review-summary-bar [model]="dept.items" [qtr]="1" />
                </td>
                <td>
                  <pms-review-summary-bar [model]="dept.items" [qtr]="2" />
                </td>
                <td>
                  <pms-review-summary-bar [model]="dept.items" [qtr]="3" />
                </td>
                <td>
                  <pms-review-summary-bar [model]="dept.items" [qtr]="4" />
                </td>
              </tr>

              <tr *ngFor="let plan of dept.items">
                <td colspan="2" class="name">
                  <div>{{ plan.FullName }}</div>
                  <div>{{ plan.JobTitle }}</div>
                </td>

                <td class="updated">{{ plan.LastUpdated }}</td>

                <td>
                  <pms-plan-bar
                    [model]="plan"
                    [permissions]="(permissions$ | async)!"
                    (planClicked)="gotoPlan(plan.Id)"
                    (resetClicked)="resetPlan(plan.Id)"
                    navigate="$ctrl.navigate({route:route})"
                  />
                </td>
                <td *ngFor="let review of plan.Reviews">
                  <pms-review-bar
                    [model]="review"
                    [permissions]="(permissions$ | async)!"
                    (reviewClicked)="gotoReview(review)"
                    (assessmentClicked)="showAssessment(review)"
                    (resetClicked)="resetReview(review)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- /body -->
</pms-page>
