<div class="modal-header">
  <h4 class="modal-title">
    <i class="fa fa-file-import"></i>Import Indicators from Projects
  </h4>
</div>
<div id="indicators-import" class="modal-body">
  <pms-progress
    *ngIf="loading$ | async"
    class="block pb-4"
    [text]="'Loading'"
    [barWidth]="'400px'"
  ></pms-progress>

  <div *ngIf="(loading$ | async) === false">
    <table class="nc-table table">
      <thead>
        <tr>
          <td></td>
          <td>KPA / <i>Strategy</i></td>
          <td>Name</td>
          <td>Annual Target</td>
          <td class="min-w-[50px]">Weight (%)</td>
          <td></td>
        </tr>
      </thead>
      <ng-container [formGroup]="form">
        <tbody formArrayName="indicators">
          <tr
            *ngFor="let item of indicators.controls; let i = index"
            [formGroupName]="i"
          >
            <td>
              <div>{{ item.get('ProjectId')?.value }}</div>
            </td>
            <td>
              <div>{{ item.get('KPA')?.value }}</div>
              <div>
                <i>{{ item.get('DevStrategy')?.value }}</i>
              </div>
            </td>
            <td>
              <div>{{ item.get('Name')?.value }}</div>
            </td>
            <td>
              <div>{{ item.get('TargetAnnual')?.value }}</div>
            </td>
            <td>
              <input
                class="form-control w-20"
                type="number"
                formControlName="Weight"
              />
            </td>
            <td>
              <input
                type="checkbox"
                class="from-check-input"
                formControlName="Selected"
              />
            </td>
          </tr></tbody
      ></ng-container>
    </table>
  </div>
</div>

<div class="modal-footer">
  <div class="nav mr-auto">
    <button class="btn btn-sm btn-outline-primary" (click)="selectAll(true)">
      Select All
    </button>

     

    <button class="btn btn-sm btn-outline-primary" (click)="selectAll(false)">
      Clear All
    </button>
  </div>

  <div class="nav">
    <button class="btn btn-primary" (click)="import()">Import</button>

    <button class="btn btn-default" (click)="cancel()">Cancel</button>
  </div>
</div>
