<pms-page
  title="Employee List"
  subTitle="Manage employees"
  icon="users"
  [loading]="(loading$ | async)!"
>
  <span toolbar>
    <button (click)="add()" class="btn btn-sm btn-warning">
      <i class="fa fa-plus"></i>Add
    </button>
  </span>
  <div body>
    <table class="nc-table table">
      <thead>
        <tr>
          <td>Employee</td>
          <td>User Name</td>
          <td>Manager</td>
          <td>Job Title</td>
          <td>Department</td>
          <td>
            <button (click)="toggleShowAll()" class="btn btn-sm btn-dark">
              <i
                class="fa"
                [class.fa-eye]="showAll"
                [class.fa-eye-slash]="!showAll"
              ></i>
            </button>
          </td>
        </tr>
      </thead>

      <tbody>
        <tr
          *ngFor="let item of (list$ | async) ?? [] | showall : showAll"
          [ngClass]="{
            italic: !item.IsCurrent,
            'bg-neutral-100': !item.IsCurrent
          }"
        >
          <td>
            <i
              class="fa"
              [class.fa-user]="item.IsCurrent"
              [class.fa-user-slash]="!item.IsCurrent"
            ></i>
            &nbsp;{{ item.FullName }}
          </td>
          <td>{{ item.UserName }}</td>
          <td>{{ item.Manager }}</td>
          <td>{{ item.JobTitle }}</td>
          <td>{{ item.Dept }}</td>
          <td class="w-[160px]">
            <div class="btn-group btn-group-sm w-100">
              <button (click)="edit(item.Id)" class="btn btn-sm btn-primary">
                <i class="fa fa-pen"></i>Edit
              </button>
              <button
                title="Hide"
                *ngIf="item.IsCurrent"
                (click)="updateIsCurrent(item, false)"
                class="btn btn-sm btn-outline-primary"
              >
                <i class="fa fa-user-slash"></i>Disable
              </button>
              <button
                title="Show"
                *ngIf="!item.IsCurrent"
                (click)="updateIsCurrent(item, true)"
                class="btn btn-sm btn-outline-primary"
              >
                <i class="fa fa-user"></i>Enable
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</pms-page>
