<div class="modal-header">
  <h4 class="modal-title">Attachments</h4>
</div>
<div>
  <pms-progress
    *ngIf="loading$ | async"
    class="block pb-4"
    [barWidth]="'60%'"
  ></pms-progress>

  <div toolbar [hidden]="loading$ | async" class="modal-header">
    <div
      *ngIf="claims.CanChangeFiles"
      [formGroup]="fileForm"
      class="nav form-inline border p-1 ml-auto"
    >
      <input
        type="file"
        class="file-upload"
        (change)="onFileSelected($event)"
      />
      <button class="btn btn-danger" [disabled]="!file" (click)="upload()">
        <i class="fa fa-upload"></i>Upload
      </button>
    </div>
  </div>

  <div class="modal-body" [hidden]="loading$ | async">
    <table class="nc-table table" ts-wrapper>
      <thead>
        <tr>
          <td ts-criteria="Name">File Name</td>
          <td ts-criteria="CreatedDate">Created Date</td>
          <td></td>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let item of list$ | async">
          <td class="w-full">{{ item.Name }}</td>
          <td class="text-nowrap px-3">
            {{ item.CreatedDate | date : 'yyyy-MM-dd' }}
          </td>
          <td class="text-nowrap pl-3" style="text-align: right">
            <div class="btn-group btn-group-sm">
              <button
                *ngIf="claims.CanApproveFiles && !disableApprovals"
                class="btn btn-sm btn-outline-danger"
                (click)="reject(item)"
              >
                <i
                  *ngIf="item.Approved === false"
                  class="far fa-check-square"
                ></i>
                <i
                  *ngIf="
                    item.Approved === true ||
                    item.Approved === undefined ||
                    item.Approved === null
                  "
                  class="far fa-square"
                ></i>
                Rejected
              </button>

              <button
                *ngIf="claims.CanApproveFiles && !disableApprovals"
                class="btn btn-sm btn-outline-primary"
                (click)="approve(item)"
              >
                <i
                  *ngIf="item.Approved === true"
                  class="far fa-check-square"
                ></i>
                <i
                  *ngIf="
                    item.Approved === null ||
                    item.Approved === undefined ||
                    item.Approved === false
                  "
                  class="far fa-square"
                ></i>
                Approved
              </button>
            </div>
            &nbsp;
            <div class="btn-group btn-group-sm">
              <button class="btn btn-sm btn-primary" (click)="download(item)">
                <i class="fa fa-download"></i>
              </button>

              <button
                *ngIf="claims.CanChangeFiles"
                class="btn btn-sm btn-outline-primary"
                (click)="remove(item.Id)"
              >
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="modal-footer">
    <button class="btn btn-outline-success" (click)="done()">Done</button>
  </div>
</div>
