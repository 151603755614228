import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  Observable,
  debounceTime,
  filter,
  forkJoin,
  map,
  switchMap,
  take,
} from 'rxjs';

import {
  InputFieldComponent,
  Kvp,
  SelectFieldComponent,
} from '@pmslogic/widgets';
import { EmployeesService } from '../../../employees/employees.service';
import { LoadingService } from '../../../shared/loading/loading.service';
import { ProgressComponent } from '../../../shared/progress/progress.component';
import {
  ImportDialogData,
  ImportIndicator,
  Indicator,
  Score,
} from '../indicator.models';
import { IndicatorsService } from '../indicators.service';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.less'],
  styles: [
    `
      :host {
        display: block;
        width: 900px;
        max-height: 90%;
      }
    `,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputFieldComponent,
    SelectFieldComponent,
    ProgressComponent,
  ],
})
export class ImportComponent {
  loading$ = this.loadingService.isLoading$(this);
  form: FormGroup;
  employees$: Observable<Kvp[]>;
  source: string;
  type: string;
  planId!: number;

  constructor(
    public dialogRef: DialogRef<Indicator[] | null>,
    @Inject(DIALOG_DATA) private data: ImportDialogData,
    private loadingService: LoadingService,
    private fb: FormBuilder,
    private employeesService: EmployeesService,
    private indicatorsService: IndicatorsService
  ) {
    this.source = data.source;
    this.type = data.type;

    this.employees$ = this.employeesService.dictionary$();

    this.form = new FormGroup({
      employeeId: new FormControl(''),
      indicators: this.fb.array([]),
    });
    const control = this.form.get('employeeId') as FormControl;

    this.data.plan$
      .pipe(
        take(1),
        switchMap((plan) => {
          this.planId = plan.Id;
          if (data.source == 'year') {
            this.loadingService.startLoading(this);
            return indicatorsService.import$(
              plan.EmployeeId,
              plan.PlanYear - 1,
              data.type
            );
          } else {
            // If source type is 'employee', wait until employee is selected before doing API call
            return control.valueChanges.pipe(
              debounceTime(200),
              filter((e) => !!e), // Ignore if employeeId is null or undefined
              switchMap((employeeId) => {
                this.loadingService.startLoading(this);
                return indicatorsService.import$(
                  employeeId,
                  plan.PlanYear,
                  data.type
                );
              })
            );
          }
        }),
        map((items) => {
          this.form.setControl(
            'indicators',
            this.fb.array(
              items.map((indicator) => {
                const group = this.fb.group({
                  ...indicator,
                  Selected: false,
                });
                return group;
              })
            )
          );
          this.loadingService.stopLoading(this);
        })
      )
      .subscribe();
  }

  selectAll(value: boolean) {
    const indicatorsControlArray = this.form.get('indicators') as FormArray;
    indicatorsControlArray.controls.forEach((control: AbstractControl) => {
      const formGroup = control as FormGroup;
      formGroup.patchValue({ Selected: value });
    });
  }

  import() {
    const indicators$ = (
      this.form.getRawValue().indicators as ImportIndicator[]
    )
      .filter((f) => f.Selected)
      .map((m) => this.indicatorsService.get$(m.Id!));

    forkJoin(indicators$)
      .pipe(
        take(1),
        map((indicators) => {
          return indicators.map((indicator) => {
            const ind = {
              ...indicator,
              ScoreList: Array(4)
                .fill({} as Score)
                .map((e: Score, i) => {
                  return {
                    Target: indicator.ScoreList[i]?.Target || '(not set)',
                  } as Score;
                }),
            } as Indicator;
            delete ind.Id;
            return ind;
          });
        })
      )
      .subscribe((indicators) => this.dialogRef.close(indicators));
  }
  cancel() {
    this.dialogRef.close(null);
  }

  get indicators(): FormArray {
    return this.form.get('indicators') as FormArray;
  }
}
