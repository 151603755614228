<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
</div>
<div class="modal-body">
  <table class="nc-table table">
    <tbody>
      <tr *ngFor="let item of items">
        <td>{{ item.label }}</td>
        <td>
          <i *ngIf="!item.isComplete" class="fa fa-times text-danger"></i>
          <i *ngIf="item.isComplete" class="fa fa-check text-success"></i>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" (click)="close()">Close</button>
</div>
