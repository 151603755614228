<pms-page
  [title]="'Performance Reports'"
  [subTitle]="'Performance for the institution'"
  [icon]="'chart-pie'"
  [loading]="(loading$ | async)!"
>
  <!-- toolbar -->
  <span toolbar class="flex items-center gap-1" [formGroup]="filterForm">
    <span class="input-group input-group-sm">
      <span class="input-group-prepend">
        <span class="input-group-text">Year</span>
      </span>
      <select class="form-control input-sm" formControlName="year">
        <option *ngFor="let t of years$ | async" [ngValue]="t.Key">
          {{ t.Value }}
        </option>
      </select>
    </span>
  </span>

  <!-- /toolbar -->

  <!-- body -->
  <div body class="h-full" *ngIf="reports$ | async as reports">
    <h4>Cumulative Performance Scores</h4>

    <table class="nc-table table">
      <thead>
        <tr>
          <td rowspan="2">Score Card</td>
          <td rowspan="2">Official</td>
          <td colspan="4">% Performance Scores per Quarter</td>
          <td rowspan="2">% Performance Scores</td>
        </tr>
        <tr>
          <td>1</td>
          <td>2</td>
          <td>3</td>
          <td>4</td>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let item of reports[0]" ts-repeat>
          <td>{{ item.JobTitle }}</td>
          <td>{{ item.FullName }}</td>
          <td>{{ item.Q1Total * 100 | number : '1.1-1' }}%</td>
          <td>{{ item.Q2Total * 100 | number : '1.1-1' }}%</td>
          <td>{{ item.Q3Total * 100 | number : '1.1-1' }}%</td>
          <td>{{ item.Q4Total * 100 | number : '1.1-1' }}%</td>
          <td>{{ item.Total * 100 | number : '1.1-1' }}%</td>
        </tr>
      </tbody>
    </table>

    <h4>Overall Municipal Performance</h4>

    <table class="nc-table table">
      <thead>
        <tr>
          <td rowspan="2">KPA</td>
          <td colspan="4">% Performance Scores per Quarter</td>
          <td rowspan="2">% Performance Scores</td>
        </tr>
        <tr>
          <td>1</td>
          <td>2</td>
          <td>3</td>
          <td>4</td>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let item of reports[1]" ts-repeat>
          <td>{{ item.Description }}</td>
          <td>{{ item.Q1Total * 100 | number : '1.1-1' }}%</td>
          <td>{{ item.Q2Total * 100 | number : '1.1-1' }}%</td>
          <td>{{ item.Q3Total * 100 | number : '1.1-1' }}%</td>
          <td>{{ item.Q4Total * 100 | number : '1.1-1' }}%</td>
          <td>{{ item.Total * 100 | number : '1.1-1' }}%</td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- /body -->
</pms-page>
