import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import Inputmask from 'inputmask';

@Directive({
  standalone: true,
  selector: 'input[pms-number-control]',
})
export class NumberControlDirective implements OnInit {
  private focus = false;

  @Input() mask?: string;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    if (this.mask) {
      const inputmask = new Inputmask(this.mask);
      inputmask.mask(this.el.nativeElement);
    } else {
      console.log('No mask defined for pms-number-control');
    }
  }

  @HostListener('click') onClick() {
    if (!this.focus) {
      this.el.nativeElement.select();
    }
    this.focus = true;
  }

  @HostListener('blur') onBlur() {
    this.focus = false;
  }

  @HostBinding('class')
  className = 'form-control input-sm text-right';
}
