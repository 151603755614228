import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject, Subscription, map, startWith } from 'rxjs';
import { ValidationService } from '../shared/validation.service';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'pms-checkbox-field',
  templateUrl: './checkbox-field.component.html',
  imports: [ReactiveFormsModule, CommonModule],
  providers: [ValidationService],
  styleUrls: ['checkbox.component.less'],
})
export class CheckboxFieldComponent implements OnInit, OnDestroy {
  @Input() label = '';
  @Input() formControl!: FormControl | undefined;
  errorMessage$ = new BehaviorSubject<string>('');
  private valueChangesSubscription: Subscription | undefined;

  constructor(public validation: ValidationService) {}
  ngOnInit(): void {
    if (this.formControl) {
      this.valueChangesSubscription = this.formControl.valueChanges
        .pipe(
          startWith(this.formControl.value),
          map(() => this.validation.getErrors(this.formControl?.errors ?? []))
        )
        .subscribe((errorMessage) => {
          this.errorMessage$.next(errorMessage);
        });
    }
  }
  ngOnDestroy() {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
  }
}
