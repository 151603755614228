import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';

export interface Milestone {
  Description: string;
  Id: number;
  ProjectId: number;
  QtrNo: number;
  Year: number;
}

export interface MilestoneYear {
  Year: number;
}

// --- Actions ---
export class SetMilestones {
  static readonly type = '[Milestones] SetMilestones';
  constructor(public items: Milestone[]) {}
}

export class AddMilestone {
  static readonly type = '[Milestones] AddMilestone';
  constructor(public item: Milestone) {}
}

export class UpdateMilestone {
  static readonly type = '[Milestones] UpdateMilestone';
  constructor(public item: Milestone) {}
}

export class RemoveMilestone {
  static readonly type = '[Milestones] RemoveMilestone';
  constructor(public id: number) {}
}

// --- State ---
export interface MilestonesStateModel {
  items: Milestone[];
}

@State<MilestonesStateModel>({
  name: 'MilestonesState',
  defaults: {
    items: [],
  },
})
@Injectable()
export class MilestonesState {
  @Selector()
  static items(state: MilestonesStateModel) {
    return state.items;
  }

  @Action(SetMilestones)
  set(ctx: StateContext<MilestonesStateModel>, { items }: SetMilestones) {
    ctx.patchState({
      items: items,
    });
  }

  @Action(AddMilestone)
  add(ctx: StateContext<MilestonesStateModel>, { item }: AddMilestone) {
    ctx.setState(
      patch({
        items: append([item]),
      })
    );
  }

  @Action(UpdateMilestone)
  update(ctx: StateContext<MilestonesStateModel>, { item }: UpdateMilestone) {
    ctx.setState(
      patch({
        items: updateItem<Milestone>((itm) => itm.Id == item.Id, item),
      })
    );
  }

  @Action(RemoveMilestone)
  remove(ctx: StateContext<MilestonesStateModel>, { id }: RemoveMilestone) {
    ctx.setState(
      patch({
        items: removeItem<Milestone>((itm) => itm.Id == id),
      })
    );
  }
}
