<table id="pdp-items" class="nc-table table">
  <thead>
    <tr>
      <td>Id</td>
      <td>Date of Entry</td>
      <td>Identified Skills Gap</td>
      <td>Outcomes Expected <br />(what will I achieve)</td>
      <td>
        <div>Identified Training Course</div>
        <div><i>Suggested Time Frames/ completion date</i></div>
      </td>
      <td>Skill Relevance to Daily Work</td>
      <td>Support Person</td>
      <td>Comments at next Review</td>
      <td>Training Type</td>
      <td>Scheduled For</td>
      <td class="text-right">
        <button (click)="add()" class="btn btn-sm btn-danger">
          <i class="fa fa-plus"></i>Add
        </button>
      </td>
    </tr>
  </thead>

  <tbody>
    @for (item of items(); track item.Id) {
    <tr>
      <td>{{ item.Id }}</td>
      <td>{{ item.EntryDate | date : 'yyyy/MM/dd' }}</td>
      <td>{{ item.SkillRequired }}</td>
      <td>{{ item.Outcome }}</td>
      <td>
        <div>{{ item.SuggestedTraining }}</div>
        <div>
          <i>{{ item.TimeFrame }}</i>
        </div>
      </td>
      <td>{{ item.WorkOpportunity }}</td>
      <td>{{ item.SupportPerson }}</td>
      <td>{{ item.Comment }}</td>
      <td>{{ item.TrainingType }}</td>
      <td>{{ item.ScheduledFor }}</td>
      <td>
        <div class="flex flex-col gap-[6px] justify-end">
          <div class="btn-group btn-group-sm">
            <button (click)="edit(item)" class="btn btn-sm btn-primary">
              <i class="fa fa-pen"></i>Edit
            </button>
            <button
              (click)="files(item.Id)"
              class="btn btn-sm btn-outline-primary"
            >
              <i class="fa fa-paperclip"></i> {{ item.NoOfFiles }}
            </button>
            <button
              (click)="remove(item.Id)"
              class="btn btn-sm btn-outline-primary"
            >
              <i class="fa fa-trash"></i>
            </button>
          </div>
          @if(showAcceptControl()){
          <div>
            <pms-accept-control
              [value]="item.Approved"
              (changed)="approve(item, $event)"
            />
          </div>
          } @if(item.Approved === null){
          <div class="flex items-center gap-1">
            <div class="rounded-xl w-3 h-3 bg-pms-yellow"></div>
            <div>Not Yet Approved</div>
          </div>
          } @else { @if(item.Approved) {
          <div class="flex items-center gap-1">
            <div class="rounded-xl w-3 h-3 bg-pms-green"></div>
            <div>Approved</div>
          </div>
          } @else {
          <div class="flex items-center gap-1">
            <div class="rounded-xl w-3 h-3 bg-pms-red"></div>
            <div>Rejected</div>
          </div>
          } }
        </div>
      </td>
    </tr>
    }
  </tbody>
</table>
