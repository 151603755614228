import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Kvp } from '@pmslogic/widgets';

// --- Actions ---
export class SetIndicatorTypes {
  static readonly type = '[IndicatorTypes] SetIndicatorTypes';
  constructor(public items: Kvp[]) {}
}

// --- State ---
export interface IndicatorTypesStateModel {
  items: Kvp[];
}

@State<IndicatorTypesStateModel>({
  name: 'IndicatorTypesState',
  defaults: {
    items: [],
  },
})
@Injectable()
export class IndicatorTypesState {
  @Selector()
  static items(state: IndicatorTypesStateModel) {
    return state.items;
  }
  @Action(SetIndicatorTypes)
  set(
    ctx: StateContext<IndicatorTypesStateModel>,
    { items }: SetIndicatorTypes
  ) {
    ctx.patchState({
      items: items,
    });
  }
}
