import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InterimReview } from './interim-review.models';

@Injectable({
  providedIn: 'root',
})
export class InterimReviewsService {
  constructor(private http: HttpClient) {}

  list$(reviewId: number) {
    return this.http.get<InterimReview[]>(
      'api/interimreviews?reviewId=' + reviewId
    );
  }

  get$(id: number) {
    return this.http.get<InterimReview>('api/interimreviews/' + id);
  }

  add$(item: Partial<InterimReview>) {
    return this.http.post<InterimReview>('api/interimreviews', item);
  }
}
