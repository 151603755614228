import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

// --- Model ---
export interface Planless {
  Id: number;
  No: string;
  Title: string;
  Description: string;
  DevStrategy: number | null;
  Years: number[];
}

// --- Actions ---
export class SetPlanless {
  static readonly type = '[Planless] Planless';
  constructor(public items: Planless[]) {}
}

// --- State ---
export interface PlanlessStateModel {
  items?: Planless[];
}

@State<PlanlessStateModel>({
  name: 'PlanlessState',
})
@Injectable()
export class PlanlessState {
  @Selector()
  static items(state: PlanlessStateModel) {
    return state.items;
  }

  @Action(SetPlanless)
  set(ctx: StateContext<PlanlessStateModel>, { items }: SetPlanless) {
    ctx.patchState({
      items: items,
    });
  }
}
