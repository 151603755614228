import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { take } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { SettingsService } from '../settings/settings.service';
import { ChangePasswordComponent } from '../user/change-password/change-password.component';
import { UserService } from '../user/user.service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MenuComponent } from './menu/menu.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NotificationsComponent } from './notifications/notifications.component';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,
    MenuComponent,
    NavbarComponent,
    NotificationsComponent,
    DialogModule,
  ],
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.less'],
})
export class MainComponent implements OnInit {
  user$ = this.userService.getUser$();
  attribution =
    '©2023 | All Rights Reserved ' +
    (environment.attribution ? `| ${environment.attribution}` : '');

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private settingsService: SettingsService,
    private dialog: Dialog
  ) {}

  ngOnInit(): void {
    this.userService.load();
    this.settingsService.load(); // Hack to get AngularJs service downgrade to work
  }

  changePassword() {
    this.user$
      .pipe(take(1))
      .subscribe((user) =>
        this.dialog.open(ChangePasswordComponent, { data: user.Id })
      );
  }

  logout() {
    this.authService.signOut();
  }
}
