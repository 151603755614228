import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[pms-scroll-to-view]',
  standalone: true,
})
export class ScrollerDirective implements OnChanges {
  @Input('pms-scroll-to-view') shouldScroll?: boolean;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['shouldScroll'] && changes['shouldScroll'].currentValue) {
      setTimeout(() => {
        this.el.nativeElement.scrollIntoView({ behavior: 'smooth' });
        this.flashBackground();
      }, 100);
    }
  }
  flashBackground() {
    this.renderer.setStyle(
      this.el.nativeElement,
      'backgroundColor',
      'lightgrey'
    );
    this.renderer.setStyle(
      this.el.nativeElement,
      'transition',
      'background-color 0.5s ease'
    );
    setTimeout(() => {
      this.renderer.setStyle(this.el.nativeElement, 'backgroundColor', '');
    }, 700);
  }
}
