import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  combineLatest,
  filter,
  finalize,
  map,
  of,
  take,
  throwError,
} from 'rxjs';
import { ConfirmDialogComponent } from '../shared/confirm-dialog.component';
import { PageComponent } from '../shared/layout/page.component';
import { LoadingService } from '../shared/loading/loading.service';
import { MessageDialogComponent } from '../shared/message-dialog.component';
import { isDefined } from '../util/common';
import { ShowallPipe } from '../util/pipes/showall.pipe';
import { ObjectivesComponent } from './objectives/objectives.component';
import { PrioritiesService } from './priorities.service';
import { Priority } from './priorities.state';
import { PriorityComponent } from './priority.component';

interface PriorityListItem extends Priority {
  expanded: boolean;
}

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    PageComponent,
    DialogModule,
    ObjectivesComponent,
    ShowallPipe,
  ],
  templateUrl: './priorities.component.html',
})
export class PrioritiesComponent {
  loading$ = this.loadingService.isLoading$(this);
  expanded$ = new BehaviorSubject<number[]>([]);
  list$: Observable<PriorityListItem[] | undefined>;
  showAll = false;

  constructor(
    private service: PrioritiesService,
    private dialog: Dialog,
    private loadingService: LoadingService
  ) {
    this.loadingService.startLoading(this);

    this.list$ = combineLatest([this.service.list$(), this.expanded$]).pipe(
      map(([items, expanded]) => {
        return items?.map((item) => {
          return {
            ...item,
            expanded: expanded.includes(item.Id),
          } as PriorityListItem;
        });
      })
    );
    this.service
      .list$()
      .pipe(
        filter((f) => isDefined(f)),
        take(1),
        finalize(() => this.loadingService.stopLoading(this))
      )
      .subscribe();
  }
  expand(id: number) {
    const currentItems = this.expanded$.value;
    if (currentItems.includes(id)) {
      this.expanded$.next(currentItems.filter((item) => item !== id));
    } else {
      this.expanded$.next([...currentItems, id]);
    }
  }

  toggleShowAll() {
    this.showAll = !this.showAll;
  }

  add() {
    this.dialog
      .open(PriorityComponent, { data: of({ IsCurrent: true }) })
      .closed.subscribe((result) => {
        if (result) {
          this.loadingService.startLoading(this);
          this.service
            .add$(result as Priority)
            .pipe(finalize(() => this.loadingService.stopLoading(this)))
            .subscribe();
        }
      });
  }

  edit(id: number) {
    this.dialog
      .open(PriorityComponent, { data: this.service.get$(id) })
      .closed.subscribe((result) => {
        if (result) {
          this.loadingService.startLoading(this);
          this.service
            .update$(result as Priority)
            .pipe(finalize(() => this.loadingService.stopLoading(this)))
            .subscribe();
        }
      });
  }

  updateIsCurrent(item: Priority, isCurrent: boolean) {
    this.loadingService.startLoading(this);
    this.service
      .updateIsCurrent$(item.Id, isCurrent)
      .pipe(finalize(() => this.loadingService.stopLoading(this)))
      .subscribe();
  }

  remove(id: number) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          text: 'Are you sure you want to delete this Priority?',
        },
      })
      .closed.subscribe((result) => {
        if (result as boolean) {
          this.loadingService.startLoading(this);
          this.service
            .remove$(id)
            .pipe(
              catchError(() => {
                this.dialog.open(MessageDialogComponent, {
                  data: {
                    title: 'Request Denied',
                    text: 'This record is in use and cannot be deleted.',
                  },
                });
                return throwError(() => new Error(`Delete failed [id=${id}]`));
              })
            )
            .pipe(finalize(() => this.loadingService.stopLoading(this)))
            .subscribe();
        }
      });
  }
}
