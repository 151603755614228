import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InputFieldComponent, ValidationService } from '@pmslogic/widgets';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ReactiveFormsModule, InputFieldComponent],
  providers: [ValidationService],
  templateUrl: './interim-review.component.html',
})
export class InterimReviewComponent {
  form: FormGroup;

  constructor(
    public dialogRef: DialogRef<void>,
    validationService: ValidationService
  ) {
    this.form = new FormGroup({
      Comment: new FormControl('', [validationService.required()]),
    });
  }

  save() {
    this.dialogRef.close(this.form.value.Comment);
  }

  cancel() {
    this.dialogRef.close();
  }
}
