import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';

// --- Model ---
export interface Dept {
  Id: number;
  Description: string;
  Code: string;
}

// --- Actions ---
export class SetDepts {
  static readonly type = '[Depts] SetDepts';
  constructor(public items: Dept[]) {}
}

export class AddDept {
  static readonly type = '[Depts] AddDept';
  constructor(public item: Dept) {}
}

export class UpdateDept {
  static readonly type = '[Depts] UpdateDept';
  constructor(public item: Dept) {}
}

export class RemoveDept {
  static readonly type = '[Depts] RemoveDept';
  constructor(public id: number) {}
}
// --- State ---
export interface DeptsStateModel {
  items?: Dept[];
}

@State<DeptsStateModel>({
  name: 'DeptsState',
})
@Injectable()
export class DeptsState {
  @Selector()
  static items(state: DeptsStateModel) {
    return state.items;
  }

  @Action(SetDepts)
  set(ctx: StateContext<DeptsStateModel>, { items }: SetDepts) {
    ctx.patchState({
      items: items,
    });
  }

  @Action(AddDept)
  add(ctx: StateContext<DeptsStateModel>, { item }: AddDept) {
    ctx.setState(
      patch({
        items: append([item]),
      })
    );
  }

  @Action(UpdateDept)
  update(ctx: StateContext<DeptsStateModel>, { item }: UpdateDept) {
    ctx.setState(
      patch({
        items: updateItem<Dept>((itm) => itm.Id == item.Id, item),
      })
    );
  }

  @Action(RemoveDept)
  remove(ctx: StateContext<DeptsStateModel>, { id }: RemoveDept) {
    ctx.setState(
      patch({
        items: removeItem<Dept>((itm) => itm.Id == id),
      })
    );
  }
}
