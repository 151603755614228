import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

export interface DialogData {
  title: string;
  text: string;
  okText: string;
}

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="modal-header">
      <h4 class="modal-title">{{ data.title }}</h4>
    </div>
    <div class="modal-body">
      <div>{{ data.text }}</div>
      <br />
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" type="button" (click)="dialogRef.close()">
        {{ data.okText }}
      </button>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 300px;
      }
    `,
  ],
})
export class MessageDialogComponent {
  data: DialogData = {
    title: '',
    text: '',
    okText: 'Ok',
  };

  constructor(
    public dialogRef: DialogRef<boolean>,
    @Inject(DIALOG_DATA) inputData: Partial<DialogData>
  ) {
    this.data = { ...this.data, ...inputData };
  }
}
