<tr>
  <td>{{ year }}</td>
  <td *ngFor="let ctrl of milestoneForms.controls">
    <span [formGroup]="$any(ctrl)">
      <fieldset class="pms-fieldset form-group nc-field mb-0">
        <textarea rows="1" formControlName="Description"></textarea>
      </fieldset>
    </span>
  </td>
  <td>
    <div class="btn-group btn-group-sm">
      <button
        [disabled]="form.disabled || !form.valid"
        (click)="save()"
        class="btn btn-sm btn-primary"
      >
        Save
      </button>
      <button (click)="cancel()" class="btn btn-sm btn-outline-primary">
        Cancel
      </button>
    </div>
  </td>
</tr>
