import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'pms-bar',
  imports: [],
  template: `
    <div class="nc-bar">
      <div class="nc-bar-container" (click)="clicked.emit()">
        <div class="nc-bar-bg">
          <div
            class="nc-bar-progress {{ backgroundClass }}"
            [style]="{ width: width + '%' }"
          ></div>
        </div>
        <div class="nc-bar-end"><i class="fa fa-{{ icon }}"></i></div>
        <div class="nc-bar-label">{{ label }}</div>
      </div>
      <div class="nc-bar-badges">
        <ng-content />
      </div>
    </div>
  `,
  styleUrls: ['./bar.component.less'],
})
export class BarComponent implements OnInit {
  @Input() color?: string;
  @Input() label?: string;
  @Input() perc?: number;
  @Input() icon?: string;
  @Output() clicked = new EventEmitter<void>();
  backgroundClass?: string;
  width?: number;

  ngOnInit(): void {
    this.backgroundClass = 'bg-' + this.color;
    this.width = Math.max(this.perc || 0, 10);
  }
}
