<div class="modal-header">
  <h4 class="modal-title">Indicator Score History</h4>
</div>
<div class="modal-body">
  <table class="nc-table table">
    <thead>
      <tr>
        <td>Quarter</td>
        <td>Personal Assessment</td>
        <td>Final Assessment</td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of items$ | async">
        <td>{{ item.QuarterNo }}</td>
        <td>
          <div *ngIf="item.Actual !== null">
            <div class="nc-field-label">Personal Comments</div>
            <div>{{ item.Actual }}</div>
          </div>
          <div *ngIf="item.UnderPerformanceReason !== null">
            <div class="nc-field-label">Reason for Underperformance</div>
            <div>{{ item.UnderPerformanceReason }}</div>
          </div>
          <div *ngIf="item.MitigatingMeasures !== null">
            <div class="nc-field-label">Mitigating Measures Put in Place</div>
            <div>{{ item.MitigatingMeasures }}</div>
          </div>
          <div *ngIf="item.WhenTargetMet !== null">
            <div class="nc-field-label">
              Envisioned Time when Targets will be Met
            </div>
            <div>{{ item.WhenTargetMet }}</div>
          </div>
        </td>
        <td>
          <div *ngIf="item.Comment !== null">
            <div class="nc-field-label">Manager Comments</div>
            <div>{{ item.Comment }}</div>
          </div>
          <div *ngIf="item.RemedialAction !== null">
            <div class="nc-field-label">Performance Improvement Actions</div>
            <div>{{ item.RemedialAction }}</div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" (click)="close()">Close</button>
</div>
