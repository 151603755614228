<div class="modal-header">
  <h4 class="modal-title">Employee Details</h4>
</div>
<div class="modal-body" [formGroup]="form">
  <div class="row">
    <div class="col">
      <pms-input-field
        label="First Name"
        ngDefaultControl
        [formControl]="$any(form.get('FirstName'))"
        placeholder="e.g. John"
      ></pms-input-field>
      <pms-input-field
        label="Last Name"
        ngDefaultControl
        [formControl]="$any(form.get('LastName'))"
        placeholder="e.g. Doe"
      ></pms-input-field>
      <pms-input-field
        label="Email"
        ngDefaultControl
        [formControl]="$any(form.get('Email'))"
        placeholder="e.g. jdoe@company.co.za"
      ></pms-input-field>
      <pms-input-field
        label="Job Title"
        ngDefaultControl
        [formControl]="$any(form.get('JobTitle'))"
        placeholder="e.g. Office Manager"
      ></pms-input-field>
      <pms-select-field
        label="Department"
        ngDefaultControl
        [formControl]="$any(form.get('DeptId'))"
        [kvp]="depts$ | async"
      ></pms-select-field>
      <pms-select-field
        label="Designation"
        ngDefaultControl
        [formControl]="$any(form.get('Designation'))"
        [kvp]="designations$ | async"
      ></pms-select-field>
      <pms-select-field
        label="Manager"
        ngDefaultControl
        [formControl]="$any(form.get('ManagerId'))"
        [kvp]="managers$ | async"
      ></pms-select-field>
      <pms-checkbox-field
        ngDefaultControl
        label="Employee is a director"
        [formControl]="$any(form.get('IsDirector'))"
      ></pms-checkbox-field>
    </div>
    <div class="col">
      <pms-input-field
        label="Logon Name"
        ngDefaultControl
        [formControl]="$any(form.get('UserName'))"
        placeholder="e.g. jdoe"
      ></pms-input-field>
      <pms-checkbox-field
        ngDefaultControl
        label="Enabled"
        [formControl]="$any(form.get('IsCurrent'))"
      ></pms-checkbox-field>
      <pms-multi-checkbox-field
        label="System Groups"
        [formControl]="$any(form.get('GroupIds'))"
        [kvp]="groups$ | async"
      >
      </pms-multi-checkbox-field>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    class="btn btn-sm btn-primary"
    [disabled]="form.disabled || !form.valid"
    (click)="save()"
  >
    Save
  </button>
  <button
    *ngIf="form.get('Id')?.value"
    class="btn btn-outline-primary"
    (click)="changePassword()"
  >
    Change Password
  </button>

  <button class="btn btn-sm btn-default" (click)="cancel()">Cancel</button>
</div>
