<pms-page
  title="Personal Development Plans"
  subTitle="Generate Personal Development Plans"
  icon="user-cog"
  [loading]="loading()"
>
  <!-- toolbar -->
  <span toolbar class="flex items-center" [formGroup]="filterForm">
    <span class="input-group input-group-sm w-auto mr-2">
      <span class="input-group-prepend">
        <span class="input-group-text">Year</span>
      </span>
      <select class="form-control" formControlName="year">
        <option *ngFor="let year of years()" [ngValue]="year.Key">
          {{ year.Value }}
        </option>
      </select>
    </span>

    <span class="input-group input-group-sm w-auto mr-2">
      <span class="input-group-prepend">
        <span class="input-group-text">Quarter</span>
      </span>
      <select class="form-control" formControlName="quarter">
        <option *ngFor="let quarter of quarters" [ngValue]="quarter.Key">
          {{ quarter.Value }}
        </option>
      </select>
    </span>

    <div *ngIf="showDepts()" class="input-group input-group-sm w-auto mr-2">
      <span class="input-group-prepend">
        <span class="input-group-text">Department</span>
      </span>
      <select class="form-control max-w-[220px]" formControlName="dept">
        <option *ngFor="let dept of depts()" [ngValue]="dept.Key">
          {{ dept.Value }}
        </option>
      </select>
    </div>

    <button
      [disabled]="loading()"
      (click)="fetch()"
      class="btn btn-sm btn-success mr-2"
    >
      View
    </button>

    <button
      [disabled]="loading()"
      (click)="print()"
      class="btn btn-sm btn-outline-success"
    >
      <i class="fa fa-print"></i>
      Print
    </button>
  </span>

  <!-- /toolbar -->

  <!-- body -->
  <div body>
    @if(url(); as url) {
    <div id="reports-main" *ngIf="!loading()">
      <iframe [src]="url | safe"></iframe>
    </div>
    } @else {
    <div class="mt-16 text-center italic">
      Choose filter parameters on the toolbar above to generate a report.
    </div>
    }
  </div>

  <!-- /body -->
</pms-page>
