import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, switchMap } from 'rxjs';
import {
  AddPriority,
  PrioritiesState,
  Priority,
  RemovePriority,
  SetPriorities,
  UpdateIsCurrentPriority,
  UpdatePriority,
} from './priorities.state';

@Injectable({
  providedIn: 'root',
})
export class PrioritiesService {
  constructor(private http: HttpClient, private store: Store) {
    this.http
      .get<Priority[]>(`api/priorities`)
      .subscribe((items) => this.store.dispatch(new SetPriorities(items)));
  }

  list$() {
    return this.store.select(PrioritiesState.items);
  }

  get$(id: number) {
    return this.http.get<Priority>(`api/priorities/${id}`);
  }

  add$(item: Partial<Priority>) {
    return this.http
      .post<Priority>(`api/priorities`, item)
      .pipe(
        switchMap((result) =>
          this.store.dispatch(new AddPriority(result)).pipe(map(() => result))
        )
      );
  }

  update$(item: Priority) {
    return this.http
      .put<Priority>(`api/priorities/${item.Id}`, item)
      .pipe(
        switchMap((result) =>
          this.store
            .dispatch(new UpdatePriority(result))
            .pipe(map(() => result))
        )
      );
  }
  updateIsCurrent$(id: number, isCurrent: boolean) {
    return this.http
      .put<boolean>(`api/priorities/${id}/iscurrent`, isCurrent)
      .pipe(
        switchMap((result) =>
          this.store
            .dispatch(new UpdateIsCurrentPriority(id, isCurrent))
            .pipe(map(() => result))
        )
      );
  }

  remove$(id: number) {
    return this.http
      .delete<void>(`api/priorities/${id}`)
      .pipe(switchMap(() => this.store.dispatch(new RemovePriority(id))));
  }
}
