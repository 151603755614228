import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface Section {
  Ordinal: number;
  Title: string;
}

export interface Review {
  Id: number;
  CanEdit: boolean;
  Status: string;
  NoOfScoresCompleted: number;
  NoOfScores: number;
  TotalScore: number;
}

export interface PlanInfo {
  Id: number;
  Section: Section;
  FullName: string;
  JobTitle: string;
  Dept: string;
  LastUpdated: string;
  IsPopulated: boolean;
  IsSignedOff: boolean;
  IsSigning: boolean;
  CanEdit: boolean;
  Reviews: Review[];
}

export interface Plan {
  Id: number;
  EmployeeId: number;
  PlanYear: number;
  Created: Date;
  Updated: Date;
  IsSignedManager: boolean;
  IsSignedEmployee: boolean;
  SignedDateEmployee?: Date;
  SignedDateManager?: Date;
  FinYear: string;
  FullName: string;
  ManagerFullName: string;
  CanEdit: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PlansService {
  constructor(private http: HttpClient) {}

  list$(year: number, deptId: number) {
    return this.http.get<PlanInfo[]>(`api/plans?year=${year}&deptId=${deptId}`);
  }

  get$(id: number | string) {
    return this.http.get<Plan>(`api/plans/${id}`);
  }

  // function get(id) {
  //   return api.get("api/plans/" + id);
  // }

  signAsManager$(id: string, pwd: string) {
    return this.http.put<void>(`api/plans/${id}/manager/sign`, {
      Password: pwd,
    });
  }

  signAsEmployee$(id: string, pwd: string) {
    return this.http.put<void>(`api/plans/${id}/employee/sign`, {
      Password: pwd,
    });
  }

  resetPlan$(id: number) {
    return this.http.put<void>(`api/plans/${id}/reset`, {});
  }
}
