<div class="modal-header">
  <h4 class="modal-title">Milestones</h4>
</div>
<div class="modal-body">
  <pms-progress
    *ngIf="loading$ | async"
    class="block pb-4"
    [text]="'Loading'"
    [barWidth]="'60%'"
  ></pms-progress>
  <div [hidden]="loading$ | async">
    <div class="toolbar grow flex justify-end p-1">
      <button
        (click)="add()"
        class="btn btn-sm btn-warning"
        [disabled]="loading$ | async"
      >
        <i class="fa fa-plus"></i>
        Add Year
      </button>
    </div>
    <table class="nc-table table">
      <thead>
        <tr>
          <td>Year</td>
          <td>Quarter 1</td>
          <td>Quarter 2</td>
          <td>Quarter 3</td>
          <td>Quarter 4</td>
          <td class="w-[124px]"></td>
        </tr>
      </thead>

      <tbody *ngIf="list$ | async as list">
        <pms-milestone
          *ngFor="let item of list | groupBy : 'Year' | sort : 'Key' : true"
          [projectId]="projectId"
          [year]="item.Key"
          [milestones]="item.Value"
        ></pms-milestone>
        <pms-add-milestone
          *ngIf="newYear$ | async"
          [projectId]="projectId"
          [year]="newYear$ | async"
          (doneEvent)="this.newYear$.next(null)"
        ></pms-add-milestone>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button class="btn btn-sm btn-outline-success" (click)="done()">
      Done
    </button>
  </div>
</div>
