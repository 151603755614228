<div class="modal-header">
  <h4 class="modal-title">
    <i class="fa fa-file-import"></i>Import Indicators
  </h4>
</div>
<div id="indicators-import" class="modal-body">
  <pms-progress
    *ngIf="loading$ | async"
    class="block pb-4"
    [text]="'Loading'"
    [barWidth]="'60%'"
  ></pms-progress>
  <div [formGroup]="form" *ngIf="(loading$ | async) === false">
    <div class="nav form-inline mb-3" *ngIf="source === 'employee'">
      <span class="input-group input-group-sm">
        <span class="input-group-prepend">
          <span class="input-group-text">Employee</span>
        </span>

        <pms-select-field
          ngDefaultControl
          [formControl]="$any(form.get('employeeId'))"
          [kvp]="employees$ | async"
        ></pms-select-field>
      </span>
    </div>
    <table class="nc-table table" *ngIf="(loading$ | async) === false">
      <thead>
        <tr>
          <td class="id"></td>
          <td class="kpa" *ngIf="type !== 'CCR'">KPA / <i>Strategy</i></td>
          <td class="kpi" *ngIf="type !== 'CCR'">KPI</td>
          <td class="kpa" *ngIf="type === 'CCR'">CCR</td>
          <td class="kpi" *ngIf="type === 'CCR'">Annual Target</td>
          <td class="weight">Weight</td>
          <td class="button"></td>
        </tr>
      </thead>
      <tbody formArrayName="indicators">
        <tr
          *ngFor="let item of indicators.controls; let i = index"
          [formGroupName]="i"
        >
          <td>{{ item.get('Id')?.value }}</td>
          <td *ngIf="type !== 'CCR'">
            <div>{{ item.get('KPA')?.value }}</div>
            <div>
              <i>{{ item.get('DevStrategy')?.value }}</i>
            </div>
          </td>
          <td *ngIf="type !== 'CCR'">
            <div>{{ item.get('Name')?.value }}</div>
            <div *ngIf="type === 'IDP'">
              <i>{{ item.get('Project')?.value }}</i>
            </div>
          </td>
          <td *ngIf="type === 'CCR'">
            <div>{{ item.get('CCR')?.value }}</div>
          </td>
          <td *ngIf="type === 'CCR'">
            <div>{{ item.get('TargetAnnual')?.value }}</div>
          </td>
          <td>{{ item.get('Weight')?.value * 100 | number : '2.2-2' }}%</td>
          <td>
            <input
              type="checkbox"
              class="from-check-input"
              formControlName="Selected"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="modal-footer">
  <div class="nav mr-auto">
    <button class="btn btn-sm btn-outline-primary" (click)="selectAll(true)">
      Select All
    </button>
    &nbsp;
    <button class="btn btn-sm btn-outline-primary" (click)="selectAll(false)">
      Clear All
    </button>
  </div>
  <div class="nav">
    <button class="btn btn-primary" (click)="import()">Import</button>

    <button class="btn btn-default" (click)="cancel()">Cancel</button>
  </div>
</div>
