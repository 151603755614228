<pms-page
  title="Departments"
  subTitle="Manage the departments of your organisation here"
  icon="building"
  [loading]="(loading$ | async)!"
>
  <span toolbar>
    <button (click)="add()" class="btn btn-sm btn-warning">
      <i class="fa fa-plus"></i>Add
    </button>
  </span>

  <div body>
    <table class="nc-table table">
      <thead>
        <tr>
          <td>Code</td>
          <td>Description</td>
          <td></td>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let item of list$ | async">
          <td>{{ item.Code }}</td>
          <td>{{ item.Description }}</td>
          <td>
            <div class="btn-group btn-group-sm">
              <button (click)="edit(item.Id)" class="btn btn-sm btn-primary">
                <i class="fa fa-pen"></i>Edit
              </button>
              <button
                (click)="remove(item.Id)"
                class="btn btn-sm btn-outline-primary"
              >
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</pms-page>
