<div class="login-panel">
  <div class="form-panel">
    <div
      *ngIf="isTestSite"
      class="row bg-danger text-white font-weight-bold p-3 justify-content-center"
    >
      Test Site
    </div>
    <br />
    <div>
      <img title="Home" src="logo.png" />
    </div>

    <br />

    <h1>Performance Management System Login</h1>
    <br />

    <div class="form-group">
      <input
        autofocus="autofocus"
        class="form-control"
        placeholder="Logon Name"
        type="text"
        [(ngModel)]="user"
      />
      <br />
      <input
        class="form-control"
        placeholder="Password"
        type="password"
        [(ngModel)]="password"
        (keydown)="$event.keyCode === 13 ? signIn() : null"
      />

      <br />
      <button
        class="btn btn-lg btn-primary btn-block"
        (click)="signIn()"
        [disabled]="isLoading$ | async"
      >
        <span *ngIf="(isLoading$ | async) === false">Sign in</span>
        <span *ngIf="isLoading$ | async"
          ><i class="fas fa-circle-notch fa-spin"></i
        ></span>
      </button>
    </div>

    <div class="validation-summary-errors text-danger">
      <ul style="text-align: left">
        <li *ngFor="let error of errors$ | async">{{ error }}</li>
      </ul>
    </div>

    <div class="text-[10px]">
      <div>©2023 | All Rights Reserved</div>
      <div>{{ attribution }}</div>
    </div>
  </div>
</div>
