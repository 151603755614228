import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Indicator, IndicatorInfo } from './indicator.models';

@Injectable({
  providedIn: 'root',
})
export class IndicatorsService {
  constructor(private http: HttpClient) {}

  list$(planId: number | string) {
    return this.http.get<IndicatorInfo[]>(`api/indicators?planId=${planId}`);
  }

  get$(id: number | string) {
    return this.http.get<Indicator>(`api/indicators/${id}`);
  }

  add$(item: Partial<Indicator>) {
    return this.http.post<IndicatorInfo>(`api/indicators`, item);
  }

  update$(item: Partial<Indicator>) {
    return this.http.put<IndicatorInfo>(`api/indicators/${item.Id}`, item);
  }

  remove$(id: number | string) {
    return this.http.delete<IndicatorInfo>(`api/indicators/${id}`);
  }

  projectIndicators$(year: number) {
    return this.http.get<Indicator[]>(`api/projects/${year}/indicators`);
  }
  
  import$(employeeId: number | null, year: number | null, type: string) {
    return this.http.get<IndicatorInfo[]>(`api/indicators/import`, {
      params: {
        employeeId: employeeId ? employeeId.toString() : '',
        year: year ? year.toString() : '',
        type: type,
      },
    });
  }
}
