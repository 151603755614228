<div class="modal-header">
  <h4 class="modal-title">Select Year</h4>
</div>
<div class="modal-body" [formGroup]="form">
  <div style="margin-bottom: 8px" *ngIf="existingYears.length > 0">
    <i
      >Milestones already exist for the following years:
      {{ existingYears | finyears }}
    </i>
  </div>
  <pms-select-field
    label="Years"
    ngDefaultControl
    [formControl]="$any(form.get('Year'))"
    [kvp]="years$ | async"
  ></pms-select-field>
</div>

<div class="modal-footer">
  <button
    class="btn btn-sm btn-primary"
    [disabled]="form.disabled || !form.valid"
    (click)="save()"
  >
    Select
  </button>

  <button class="btn btn-sm btn-default" (click)="cancel()">Cancel</button>
</div>
