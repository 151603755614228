import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { isNil } from 'lodash';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subscription,
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  of,
  startWith,
  switchMap,
  take,
  throwError,
} from 'rxjs';
import { ConfirmDialogComponent } from '../shared/confirm-dialog.component';
import { PageComponent } from '../shared/layout/page.component';
import { LoadingService } from '../shared/loading/loading.service';
import { MessageDialogComponent } from '../shared/message-dialog.component';
import { ScrollerDirective } from '../shared/scroller.directive';
import { isDefined } from '../util/common';
import { FinyearsPipe } from '../util/pipes/finyears.pipe';
import { YearsService } from '../years/years.service';
import { MilestonesComponent } from './milestones/milestones.component';
import { ProjectComponent } from './project.component';
import { Project, ProjectInfo } from './project.models';
import { ProjectsService } from './projects.service';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PageComponent,
    DialogModule,
    FinyearsPipe,
    ScrollerDirective,
  ],
  templateUrl: './projects.component.html',
})
export class ProjectsComponent implements OnDestroy {
  filterForm = new FormGroup({
    year: new FormControl(),
  });
  loading$ = this.loadingService.isLoading$(this);
  list$: Observable<ProjectInfo[] | undefined> = EMPTY;
  years$ = this.yearsService.getYearsWithAll$();
  loadingSub: Subscription;
  currentId$ = new BehaviorSubject<string | number | undefined>('');

  constructor(
    private service: ProjectsService,
    private dialog: Dialog,
    private yearsService: YearsService,
    private loadingService: LoadingService
  ) {
    this.loadingService.startLoading(this);

    this.yearsService
      .getCurrentYear$()
      .pipe(take(1))
      .subscribe((year) => {
        this.filterForm.patchValue({ year: year }, { emitEvent: true });
      });

    this.list$ = this.filterForm.valueChanges.pipe(
      startWith(this.filterForm.value),
      filter((values) => !isNil(values.year)),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      switchMap((m) => {
        return this.service
          .list$()
          .pipe(
            map((items) =>
              items?.filter((itm) => m.year == 0 || itm.Years.includes(m.year))
            )
          );
      })
    );

    this.service
      .list$()
      .pipe(
        filter((f) => isDefined(f)),
        take(1),
        finalize(() => this.loadingService.stopLoading(this))
      )
      .subscribe();

    this.loadingSub = this.loading$.subscribe((loading) => {
      if (loading) {
        this.filterForm.disable();
      } else {
        this.filterForm.enable();
      }
    });
  }

  add() {
    this.dialog
      .open(ProjectComponent, { data: of({}) })
      .closed.subscribe((result) => {
        if (!result) return;
        const project = result as Project;
        this.loadingService.startLoading(this);
        this.service
          .add$(project)
          .pipe(finalize(() => this.loadingService.stopLoading(this)))
          .subscribe((p) => {
            this.milestones((p as ProjectInfo).Id);
            this.currentId$.next(project.Id);
          });
      });
  }

  edit(id: number) {
    this.dialog
      .open(ProjectComponent, { data: this.service.get$(id) })
      .closed.subscribe((result) => {
        if (!result) return;
        const project = result as Project;
        this.loadingService.startLoading(this);
        this.service
          .update$(project)
          .pipe(
            finalize(() => {
              this.loadingService.stopLoading(this);
              this.currentId$.next(project.Id);
            })
          )
          .subscribe();
      });
  }

  milestones(id: number) {
    this.dialog
      .open(MilestonesComponent, { data: this.service.get$(id) })
      .closed.subscribe(() => {
        this.loadingService.startLoading(this);
        //update the project with the latest milestone data
        this.service.get$(id).subscribe((p) => {
          this.service
            .update$(p as Project)
            .pipe(finalize(() => this.loadingService.stopLoading(this)))
            .subscribe((project) => {
              this.currentId$.next(project.Id);
            });
        });
      });
  }
  remove(id: number) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          text: 'Are you sure you want to delete this Project?',
        },
      })
      .closed.subscribe((result) => {
        if (result as boolean) {
          this.loadingService.startLoading(this);
          this.service
            .remove$(id)
            .pipe(
              catchError(() => {
                this.dialog.open(MessageDialogComponent, {
                  data: {
                    title: 'Request Denied',
                    text: 'This record is in use and cannot be deleted.',
                  },
                });
                return throwError(() => new Error(`Delete failed [id=${id}]`));
              })
            )
            .pipe(finalize(() => this.loadingService.stopLoading(this)))
            .subscribe();
        }
      });
  }
  ngOnDestroy(): void {
    this.loadingSub.unsubscribe();
  }
}
