import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'finyears',
  standalone: true,
})
export class FinyearsPipe implements PipeTransform {
  transform(years: number[]): string[] {
    return years.map((m) => this.format(m));
  }

  format(year: number) {
    return `${year - 1}/${year}`;
  }
}
