import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { InputFieldComponent, ValidationService } from '@pmslogic/widgets';
import { finalize, forkJoin, map, of, take } from 'rxjs';
import { LoadingService } from '../../shared/loading/loading.service';
import { ProgressComponent } from '../../shared/progress/progress.component';
import { MilestonesService } from './milestones.service';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'pms-add-milestone',
  providers: [],
  templateUrl: './add-milestone.component.html',
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputFieldComponent,
    ProgressComponent,
  ],
})
export class AddMilestoneComponent implements OnInit {
  @Input() projectId: number | null | undefined;
  @Input() year: number | null = null;
  @Output() doneEvent = new EventEmitter();

  milestones$ = of([]);

  form = this.fb.group({
    milestoneForms: this.fb.array([]),
  });

  constructor(
    private fb: FormBuilder,
    private service: MilestonesService,
    private validation: ValidationService,
    private loadingService: LoadingService
  ) {}
  ngOnInit(): void {
    this.milestones$
      .pipe(
        take(1),
        map(() => {
          this.milestoneForms.clear();

          for (let e = 1; e <= 4; e++) {
            const frm = this.fb.group({
              Id: null,
              Year: [this.year],
              Description: ['' || '', this.validation.required()],
              ProjectId: [this.projectId],
              QtrNo: e,
            });
            this.milestoneForms.push(frm);
          }
        })
      )
      .subscribe();
  }

  save() {
    this.loadingService.startLoading(this);
    const calls$ = this.milestoneForms.controls
      .filter((f) => f.value)
      .map((m) => {
        return this.service.add$(m.value);
      });

    forkJoin(calls$)
      .pipe(
        take(1),
        finalize(() => {
          this.doneEvent.emit();
          this.loadingService.stopLoading(this);
        })
      )
      .subscribe();
  }

  cancel() {
    this.doneEvent.emit(null);
  }

  get milestoneForms(): FormArray {
    return this.form.get('milestoneForms') as FormArray;
  }
}
