import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Report, ReportsService } from '../reports/reports.service';
import { PageComponent } from '../shared/layout/page.component';
import { SafePipe } from '../util/pipes/safe.pipe';

@Component({
  standalone: true,
  imports: [CommonModule, PageComponent, SafePipe],
  template: ` <pms-page
    title="Performance Review"
    subTitle="Performance Review Report"
    icon="print"
    [loading]="false"
  >
    <!-- toolbar -->
    <span toolbar class="flex items-center gap-1">
      <button
        (click)="print()"
        class="whitespace-nowrap btn btn-sm btn-outline-success"
      >
        <i class="fa fa-print"></i>Print
      </button>
      <button
        (click)="back()"
        class="whitespace-nowrap btn btn-sm btn-outline-success"
      >
        Back
      </button>
    </span>

    <!-- /toolbar -->
    <div body class="h-full">
      <iframe
        #iframe
        class="h-[99%] w-[99%] border border-gray-300 shadow-md"
        id="report"
        [src]="report?.url | safe"
      ></iframe>
    </div>
  </pms-page>`,
})
export class PrintReviewComponent {
  id: number;
  report?: Report | null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private reportsService: ReportsService
  ) {
    this.id = this.route.snapshot.params['id'];
    this.report = this.reportsService.reviews(this.id);
  }

  print() {
    this.report?.print();
  }
  back() {
    this.router.navigateByUrl(`reviews/${this.id}`);
  }
}
