<div class="modal-header">
  <h4 class="modal-title">Project Details</h4>
</div>
<div class="modal-body" [formGroup]="form">
  <div class="row">
    <div class="col">
      <pms-input-field
        label="Project Number"
        ngDefaultControl
        [formControl]="$any(form.get('ProjectNo'))"
        placeholder="the project number (leave blank for auto-number)"
      ></pms-input-field>
      <!--  -->
      <pms-input-field
        label="Title"
        ngDefaultControl
        [formControl]="$any(form.get('Title'))"
        [rows]="3"
        placeholder="the project title"
      ></pms-input-field>
      <!--  -->
      <pms-input-field
        label="Description"
        ngDefaultControl
        [formControl]="$any(form.get('Description'))"
        [rows]="3"
        placeholder="the project description"
      ></pms-input-field>
      <!--  -->
      <pms-select-field
        label="Employee"
        ngDefaultControl
        [formControl]="$any(form.get('EmployeeId'))"
        [kvp]="employees$ | async"
      ></pms-select-field>
      <!--  -->
    </div>

    <div class="col">
      <pms-select-field
        label="Key Performance Area (KPA)"
        ngDefaultControl
        [formControl]="$any(form.get('KPAId'))"
        [kvp]="kpas$ | async"
      ></pms-select-field>
      <!--  -->
      <pms-strategy-picker
        ngDefaultControl
        [formControl]="$any(form.get('DevStrategyId'))"
      ></pms-strategy-picker>
      <!--  -->
      <pms-select-field
        label="GFS Function"
        ngDefaultControl
        [formControl]="$any(form.get('GFSFuncId'))"
        [kvp]="gfsFuncs$ | async"
      ></pms-select-field>
      <!--  -->
      <pms-input-field
        label="Annual Target"
        ngDefaultControl
        [formControl]="$any(form.get('OutputMeasure'))"
        placeholder="the annual target"
      ></pms-input-field>
      <!--  -->
      <pms-input-field
        label="Proof"
        ngDefaultControl
        [formControl]="$any(form.get('Verification'))"
        placeholder="proof of completion"
      ></pms-input-field>
      <!--  -->
      <pms-input-field
        label="Expenditure Vote"
        ngDefaultControl
        [formControl]="$any(form.get('Vote'))"
        placeholder="the expenditure vote"
      ></pms-input-field>
      <!--  -->
      <pms-input-field
        label="Grant Vote"
        ngDefaultControl
        [formControl]="$any(form.get('GrantVote'))"
        placeholder="the grant vote"
      ></pms-input-field>
      <!--  -->
    </div>
  </div>
</div>

<div class="modal-footer">
  <button
    class="btn btn-sm btn-primary"
    [disabled]="form.disabled || !form.valid"
    (click)="save()"
  >
    Save
  </button>

  <button class="btn btn-sm btn-default" (click)="cancel()">Cancel</button>
</div>
