import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Permissions } from '../../user/user.models';
import { PlanInfo } from '../plans.service';
import { BarComponent } from './bar.component';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'pms-plan-bar',
  imports: [CommonModule, BarComponent],
  template: `<pms-bar
    (clicked)="planClicked.emit()"
    [color]="color"
    [label]="label"
    [perc]="perc"
    [icon]="icon"
  >
    <div
      *ngIf="canReset()"
      (click)="resetClicked.emit()"
      class="nc-badge badge badge-light"
    >
      reset
    </div>
  </pms-bar> `,
  styleUrls: ['./bar.component.less'],
})
export class PlanBarComponent implements OnInit {
  @Input() model?: PlanInfo;
  @Input() permissions?: Permissions;
  @Output() planClicked = new EventEmitter<void>();
  @Output() resetClicked = new EventEmitter<void>();

  label?: string;
  icon?: string;
  perc?: number;
  color?: string;

  ngOnInit(): void {
    const mdl = this.model;

    if (mdl?.IsSignedOff) {
      this.label = 'signed off';
      this.icon = 'print';
      this.perc = 100;
      this.color = 'progress-100';
    } else if (mdl?.IsSigning) {
      this.label = 'signing';
      this.icon = 'pen-nib';
      this.perc = 100;
      this.color = 'progress-83';
    } else if (mdl?.IsPopulated) {
      this.label = 'progressing';
      this.icon = 'edit';
      this.perc = 50;
      this.color = 'progress-50';
    } else {
      this.label = 'not started';
      this.icon = 'edit';
      this.perc = 0;
      this.color = 'progress-0';
    }
  }

  canReset() {
    return (
      this.permissions?.CanResetPlans &&
      (this.model?.IsSignedOff || this.model?.IsSigning)
    );
  }
}
