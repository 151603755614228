import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Kvp } from '@pmslogic/widgets';

// --- Actions ---
export class SetCcrTypes {
  static readonly type = '[CcrTypes] SetCcrTypes';
  constructor(public items: Kvp[]) {}
}

// --- State ---
export interface CcrTypesStateModel {
  items: Kvp[];
}

@State<CcrTypesStateModel>({
  name: 'CcrTypesState',
  defaults: {
    items: [],
  },
})
@Injectable()
export class CcrTypesState {
  @Selector()
  static items(state: CcrTypesStateModel) {
    return state.items;
  }
  @Action(SetCcrTypes)
  set(ctx: StateContext<CcrTypesStateModel>, { items }: SetCcrTypes) {
    ctx.patchState({
      items: items,
    });
  }
}
