import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { NgxsModule } from '@ngxs/store';
import { PlanlessState } from './planless/planless.state';
import { IndicatorTypesState } from './plans/indicators/indicator-types/indicator-types.state';
import { ObjectivesState } from './priorities/objectives/objectives.state';
import { PrioritiesState } from './priorities/priorities.state';
import { StrategiesState } from './priorities/strategies/strategies.state';
import { MilestonesState } from './projects/milestones/milestones.state';
import { ProjectsState } from './projects/projects.state';
import { SettingsState } from './settings/settings.state';
import { UserState } from './user/user.state';
import { YearsState } from './years/years.state';
import { CcrTypesState } from './ccrs/ccr-types/ccr-types.state';
import { CcrsState } from './ccrs/ccrs.state';
import { DeptsState } from './depts/depts.state';
import { DocsState } from './documents/documents.state';
import { EmployeesState } from './employees/employees.state';
import { KpasState } from './kpas/kpas.state';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { tokenInterceptor } from './interceptors/token.interceptor';
import { baseUrlInterceptor } from './interceptors/base-url.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes),
    provideHttpClient(withInterceptors([tokenInterceptor, baseUrlInterceptor])),
    importProvidersFrom(
      NgxsModule.forRoot(
        [
          CcrsState,
          CcrTypesState,
          DeptsState,
          DocsState,
          EmployeesState,
          IndicatorTypesState,
          KpasState,
          MilestonesState,
          ObjectivesState,
          PlanlessState,
          PrioritiesState,
          ProjectsState,
          SettingsState,
          StrategiesState,
          UserState,
          YearsState,
        ],
        {
          developmentMode: true,
        }
      )
    ),
  ],
};
