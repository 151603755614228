import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject, Subscription, map, startWith } from 'rxjs';
import { ValidationService } from '../shared/validation.service';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'pms-input-field',
  templateUrl: './input-field.component.html',
  imports: [ReactiveFormsModule, CommonModule],
  providers: [ValidationService],
})
export class InputFieldComponent implements OnInit, OnDestroy {
  private valueChangesSubscription: Subscription | undefined;

  @Input() label = '';
  @Input() type = 'text';
  @Input() placeholder = '';
  @Input() formControl!: FormControl | undefined;
  @Input() rows: number | null = null;
  @Input() readonly = false;
  @Output() blurEvent = new EventEmitter();

  errorMessage$ = new BehaviorSubject<string>('');

  constructor(public validation: ValidationService) {}
  ngOnInit(): void {
    if (this.formControl) {
      this.valueChangesSubscription = this.formControl.valueChanges
        .pipe(
          startWith(this.formControl.value),
          map(() => this.validation.getErrors(this.formControl?.errors ?? []))
        )
        .subscribe((errorMessage) => {
          this.errorMessage$.next(errorMessage);
        });
    }
  }
  onBlur() {
    this.blurEvent.emit();
  }
  ngOnDestroy() {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
  }
}
