<div class="modal-header">
  <h4 class="modal-title">Reset Password</h4>
</div>
<div class="modal-body" [formGroup]="form">
  <fieldset class="pms-fieldset mb-4">
    <label>
      <span>Enter New Password</span>
      <small *ngIf="form.get('Password')?.hasError('required')">
        *Required
      </small>
    </label>

    <input type="password" formControlName="Password" />
  </fieldset>

  <fieldset class="pms-fieldset mb-4">
    <label>
      <span>Re-Enter New Password</span>
      <small *ngIf="form.get('Password2')?.hasError('required')">
        *Required
      </small>
    </label>

    <input type="password" formControlName="Password2" />
  </fieldset>
</div>
<!--modal-body-->

<div class="modal-footer">
  <small class="text-danger grow">{{ message$ | async }}</small>
  <button
    class="btn btn-sm btn-primary"
    [disabled]="form.disabled || !form.valid"
    (click)="save()"
  >
    Save
  </button>

  <button class="btn btn-sm btn-default" (click)="cancel()">Cancel</button>
</div>
