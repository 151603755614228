import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Routes } from '../app.routes';

export const authGuard: CanActivateFn = () => {
  const router = inject(Router);

  const token = localStorage.getItem('access_token');

  if (token !== null) {
    return true;
  }
  return router.createUrlTree([Routes.Login]);
};
