<pms-page
  [title]="'Tutorials'"
  [subTitle]="'Learning videos'"
  [icon]="'video'"
  [loading]="false"
>
  <!-- body -->
  <div body>
    <h4>Video: Project Related Indicators</h4>
    <iframe
      width="{{ width }}"
      height="{{ height }}"
      src="https://www.youtube.com/embed/-FyhHWYL5a0"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>

    <h4>Video: Key Performance Indicators</h4>
    <iframe
      class="bg-gray-100"
      width="{{ width }}"
      height="{{ height }}"
      src="https://www.youtube.com/embed/Ijsu5FPvBzA"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>

    <h4>Video: Core Competency Requirements</h4>
    <iframe
      width="{{ width }}"
      height="{{ height }}"
      src="https://www.youtube.com/embed/ev0_i8bjzUk"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>

    <h4>Video: Sign off a Performance Plans</h4>
    <iframe
      width="{{ width }}"
      height="{{ height }}"
      src="https://www.youtube.com/embed/ff15tHAX9D0"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</pms-page>
