import { Injectable } from '@angular/core';
import printJS from 'print-js';
import { environment } from '../../environments/environment';

export class Report {
  constructor(private path: string) {}

  get url(): string {
    return `${environment.apiHost}reports/${
      this.path
    }&access_token=${localStorage.getItem('access_token')}`;
  }

  print() {
    fetch(this.url).then((res) => {
      res.blob().then((blob) => {
        const uri = URL.createObjectURL(blob);
        printJS(uri);
        URL.revokeObjectURL(uri);
      });
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  plan(id: number) {
    return new Report(`plan?id=${id}`);
  }

  reviews(id: number | null = null) {
    if (id) {
      return new Report(`reviews?id=${id}`);
    } else {
      return new Report(`reviews`);
    }
  }

  scorecard(year: number) {
    return new Report(`scorecard?year=${year}`);
  }

  pdps(deptId: number, quarter: number, year: number) {
    return new Report(`pdps?deptId=${deptId}&year=${year}&quarter=${quarter}`);
  }
}
