<fieldset class="pms-fieldset form-group nc-field">
  <label>
    <span> {{ label }}</span>
    <small class="p-error" *ngIf="errorMessage$ | async">
      *{{ errorMessage$ | async }}</small
    >
  </label>
  <div class="w-100 h-6 flex" *ngFor="let item of kvp">
    <input
      class="w-5"
      type="checkbox"
      [checked]="formControl.value.includes(item.Key)"
      (change)="toggle(item.Key)"
    />
    <div class="cursor-default" (click)="toggle(item.Key)">
      {{ item.Value }}
    </div>
  </div>
</fieldset>
