import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { User } from './user.models';

// --- Actions ---
export class SetUser {
  static readonly type = '[User] SetUser';
  constructor(public user: User) {}
}

// --- State ---
export interface UserStateModel {
  user: User | null;
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    user: null,
  },
})
@Injectable()
export class UserState {
  @Selector()
  static user(state: UserStateModel) {
    return state.user;
  }

  @Action(SetUser)
  setUser(ctx: StateContext<UserStateModel>, { user }: SetUser) {
    ctx.patchState({
      user: user,
    });
  }
}
