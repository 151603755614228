import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InputFieldComponent, ValidationService } from '@pmslogic/widgets';
import { Observable } from 'rxjs';
import { Strategy } from './strategies.state';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ReactiveFormsModule, InputFieldComponent],
  providers: [ValidationService],
  templateUrl: './strategy.component.html',
  styles: [
    `
      :host {
        display: block;
        width: 300px;
      }
    `,
  ],
})
export class StrategyComponent implements OnInit {
  form: FormGroup;

  constructor(
    public dialogRef: DialogRef<Strategy | null>,
    @Inject(DIALOG_DATA) private data$: Observable<Partial<Strategy>>,
    validation: ValidationService
  ) {
    this.form = new FormGroup({
      Id: new FormControl(), //Not shown on form
      ObjectiveId: new FormControl(''),
      IsCurrent: new FormControl(), //not shown on form
      Description: new FormControl('', [validation.required()]),
    });

    this.form.disable();
  }
  ngOnInit(): void {
    this.data$.subscribe((item) => {
      this.form.patchValue(item);
      this.form.enable();
    });
  }

  save() {
    this.dialogRef.close(this.form.value);
  }

  cancel() {
    this.dialogRef.close(null);
  }
}
