import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { isDefined } from '../../util/common';

// --- Model ---
export interface Strategy {
  Id: number;
  ObjectiveId: number;
  Description: string;
  IsCurrent: boolean;
}

// --- Actions ---
export class SetStrategies {
  static readonly type = '[Strategies] SetStrategies';
  constructor(public items: Strategy[]) {}
}

export class AddStrategy {
  static readonly type = '[Strategies] AddStrategy';
  constructor(public item: Strategy) {}
}

export class UpdateStrategy {
  static readonly type = '[Strategies] UpdateStrategy';
  constructor(public item: Strategy) {}
}

export class UpdateIsCurrentStrategy {
  static readonly type = '[Strategies] UpdateIsCurrentStrategy';
  constructor(public id: number, public isCurrent: boolean) {}
}

export class RemoveStrategy {
  static readonly type = '[Strategies] RemoveStrategy';
  constructor(public id: number) {}
}

// --- State ---
export interface StrategiesStateModel {
  items?: Strategy[];
}

@State<StrategiesStateModel>({
  name: 'StrategiesState',
})
@Injectable()
export class StrategiesState {
  @Selector()
  static items(state: StrategiesStateModel) {
    return state.items;
  }

  @Action(SetStrategies)
  set(ctx: StateContext<StrategiesStateModel>, { items }: SetStrategies) {
    ctx.patchState({
      items: items,
    });
  }

  @Action(AddStrategy)
  add(ctx: StateContext<StrategiesStateModel>, { item }: AddStrategy) {
    ctx.setState(
      patch({
        items: append([item]),
      })
    );
  }

  @Action(UpdateIsCurrentStrategy)
  updateIsCurrent(
    ctx: StateContext<StrategiesStateModel>,
    { id, isCurrent }: UpdateIsCurrentStrategy
  ) {
    const state = ctx.getState();
    if (!isDefined(state.items)) return;
    const idx = state.items.findIndex((itm) => itm.Id === id);
    const updatedItem = { ...state.items[idx], IsCurrent: isCurrent };

    ctx.setState(
      patch({
        items: updateItem<Strategy>((itm) => itm.Id == id, updatedItem),
      })
    );
  }

  @Action(UpdateStrategy)
  update(ctx: StateContext<StrategiesStateModel>, { item }: UpdateStrategy) {
    ctx.setState(
      patch({
        items: updateItem<Strategy>((itm) => itm.Id == item.Id, item),
      })
    );
  }

  @Action(RemoveStrategy)
  remove(ctx: StateContext<StrategiesStateModel>, { id }: RemoveStrategy) {
    ctx.setState(
      patch({
        items: removeItem<Strategy>((itm) => itm.Id == id),
      })
    );
  }
}
