import { CommonModule, DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { isNil } from 'lodash';
import {
  Observable,
  Subscription,
  filter,
  finalize,
  forkJoin,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { PageComponent } from '../shared/layout/page.component';
import { LoadingService } from '../shared/loading/loading.service';
import { YearsService } from '../years/years.service';
import { PerformanceReport, PerformanceService } from './performance.service';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './performance.component.html',

  imports: [CommonModule, PageComponent, ReactiveFormsModule, DecimalPipe],
})
export class PerformanceComponent implements OnDestroy {
  loading$ = this.loadingService.isLoading$(this);

  loadingSub: Subscription;
  yearsSub: Subscription;

  years$ = this.yearsService.getYears$();
  filterForm = new FormGroup({
    year: new FormControl(),
  });

  reports$: Observable<[PerformanceReport[], PerformanceReport[]]>;

  constructor(
    private yearsService: YearsService,
    private performanceService: PerformanceService,
    private loadingService: LoadingService
  ) {
    this.yearsSub = this.yearsService.getCurrentYear$().subscribe((year) => {
      this.filterForm.patchValue({ year: year }, { emitEvent: true });
    });

    this.reports$ = this.filterForm.valueChanges.pipe(
      startWith(this.filterForm.value),
      filter((values) => !isNil(values.year)),
      tap(() => {
        this.loadingService.startLoading(this);
      }),
      switchMap((m) => {
        return forkJoin([
          this.performanceService.cumulative$(m.year),
          this.performanceService.overall$(m.year),
        ]).pipe(finalize(() => this.loadingService.stopLoading(this)));
      })
    );

    this.loadingSub = this.loading$.subscribe((loading) => {
      if (loading) {
        this.filterForm.disable({ emitEvent: false });
      } else {
        this.filterForm.enable({ emitEvent: false });
      }
    });
  }

  ngOnDestroy() {
    this.yearsSub.unsubscribe();
    this.loadingSub.unsubscribe();
  }
}
