export const environment = {
  apiHost: 'https://api-mbda-test.pmslogic.co.za/',
  shortName: 'MBDA',
  hideTutorials: false,
  supportEmail: 'support@vargafon.co.za',
  isTestSite: true,
  attribution: null,
  //licenceExpiry: new Date('2025-06-01'),
  licenceExpiry: new Date('2100-04-14'),
};
