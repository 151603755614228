<div class="p-[20px]">
  <div *ngFor="let section of sections$ | async" class="mb-3">
    <div class="title">{{ section.title }}</div>
    <a
      *ngFor="let link of section.links"
      routerLink="{{ link.path }}"
      (click)="link.method?.()"
      >{{ link.title }}</a
    >
  </div>
  <div>
    <div class="title">HELP</div>
    <a routerLink="/videos">Tutorials</a>
    <a [href]="email">E-mail Support</a>
  </div>
</div>
