import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  InputFieldComponent,
  Kvp,
  SelectFieldComponent,
  ValidationService,
} from '@pmslogic/widgets';
import { PdpItem } from './pdp-items.models';
import { PdpItemsService } from './pdp-items.service';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputFieldComponent,
    SelectFieldComponent,
  ],
  providers: [ValidationService],
  templateUrl: './pdp-item.component.html',
  styles: [
    `
      :host {
        display: block;
        width: 800px;
      }
    `,
  ],
})
export class PdpItemComponent implements OnInit {
  form: FormGroup;
  typeKvps: Kvp[] = [];
  forKvps: Kvp[] = [];
  timeFrameKvps: Kvp[] = [];

  constructor(
    public dialogRef: DialogRef<PdpItem | null>,
    @Inject(DIALOG_DATA) private data: Partial<PdpItem>,
    service: PdpItemsService,
    validationService: ValidationService
  ) {
    this.form = new FormGroup({
      Id: new FormControl(), //Not shown on form
      EmployeeId: new FormControl(''), //Not shown on form
      SkillRequired: new FormControl('', [validationService.required()]),
      Outcome: new FormControl('', [validationService.required()]),
      SuggestedTraining: new FormControl('', [validationService.required()]),
      TimeFrame: new FormControl('', [validationService.required()]),
      WorkOpportunity: new FormControl('', [validationService.required()]),
      SupportPerson: new FormControl('', [validationService.required()]),
      Comment: new FormControl('', [validationService.required()]),
      TrainingType: new FormControl('', [validationService.required()]),
      ScheduledFor: new FormControl('', [validationService.required()]),
    });

    this.form.disable();

    // Add current value to accomodate deprecated values
    this.typeKvps = service.trainingTypeList(data.TrainingType);
    this.forKvps = service.scheduledForList(data.ScheduledFor);
    this.timeFrameKvps = service.timeFramesList(data.TimeFrame);
  }

  ngOnInit(): void {
    console.log('DATA', this.data);
    this.form.patchValue(this.data);
    this.form.enable();
  }

  save() {
    this.dialogRef.close(this.form.value);
  }

  cancel() {
    this.dialogRef.close(null);
  }
}
