import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject, Subscription, map, startWith } from 'rxjs';
import { Kvp } from '../shared/kvp.model';
import { ValidationService } from '../shared/validation.service';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'pms-select-field',
  templateUrl: './select-field.component.html',
  imports: [ReactiveFormsModule, CommonModule],
  providers: [ValidationService],
})
export class SelectFieldComponent implements OnInit, OnDestroy {
  @Input() label = '';
  @Input() formControl: FormControl | undefined;
  @Input() kvp: Kvp[] | null = [];

  errorMessage$ = new BehaviorSubject<string>('');
  private valueChangesSubscription: Subscription | undefined;
  constructor(public validation: ValidationService) {}
  ngOnInit(): void {
    if (this.formControl) {
      this.valueChangesSubscription = this.formControl.valueChanges
        .pipe(
          startWith(this.formControl.value),
          map(() => this.validation.getErrors(this.formControl?.errors ?? []))
        )
        .subscribe((errorMessage) => {
          this.errorMessage$.next(errorMessage);
        });
    }
  }
  ngOnDestroy() {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
  }
}
