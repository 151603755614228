<pms-page
  [title]="'Planless Projects'"
  [subTitle]="'Projects that do not appear on performance plans'"
  [icon]="'unlink'"
  [loading]="(loading$ | async)!"
>
  <!-- toolbar -->
  <span toolbar class="flex items-center gap-1" [formGroup]="filterForm">
    <span class="input-group input-group-sm">
      <span class="input-group-prepend">
        <span class="input-group-text">Year</span>
      </span>
      <select
        class="form-control input-sm nc-select-control"
        formControlName="year"
      >
        <option *ngFor="let t of years$ | async" [ngValue]="t.Key">
          {{ t.Value }}
        </option>
      </select>
    </span>
  </span>

  <!-- /toolbar -->

  <!-- body -->
  <div body>
    <table class="nc-table table">
      <thead>
        <tr>
          <td>Code</td>
          <td>Title</td>
          <td>Description</td>
          <td></td>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let item of list$ | async">
          <td>{{ item.Id }}</td>
          <td>{{ item.Title }}</td>
          <td>{{ item.Description }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- /body -->
</pms-page>
