<div class="modal-header">
  <h4 class="modal-title">Indicator Risks</h4>
</div>
<div class="modal-body">
  <div
    class="nav"
    *ngIf="(data.plan$ | async)?.CanEdit && (loading$ | async) !== true"
  >
    <div class="form-inline border p-1 ml-auto mb-3">
      <button (click)="add()" class="btn btn btn-danger">
        <i class="fa fa-plus"></i>Add
      </button>
    </div>
  </div>

  <pms-progress
    *ngIf="loading$ | async"
    class="block pb-4"
    [text]="'Loading'"
    [barWidth]="'400px'"
  ></pms-progress>

  <div *ngIf="(loading$ | async) === false">
    <table id="risks" class="nc-table table">
      <thead>
        <tr>
          <td>Area</td>
          <td>Measures</td>
          <td *ngIf="(data.plan$ | async)?.CanEdit"></td>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let item of this.list$ | async">
          <td>{{ item.Area }}</td>
          <td>{{ item.Measures }}</td>
          <td class="w-28" *ngIf="(data.plan$ | async)?.CanEdit">
            <div class="btn-group btn-group-sm">
              <button (click)="edit(item.Id)" class="btn btn-sm btn-primary">
                <i class="fa fa-pen"></i>Edit
              </button>
              <button
                (click)="remove(item.Id)"
                class="btn btn-sm btn-outline-primary"
              >
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-outline-success" (click)="done()">Done</button>
</div>
