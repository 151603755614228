import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { BehaviorSubject, Subscription, map, startWith } from 'rxjs';
import { ValidationService } from '../shared/validation.service';
import { Kvp } from '../shared/kvp.model';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'pms-multi-checkbox-field',
  templateUrl: './multi-checkbox-field.component.html',
  imports: [ReactiveFormsModule, CommonModule],
  providers: [
    ValidationService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiCheckboxFieldComponent),
      multi: true,
    },
  ],
  styleUrls: ['multi-checkbox.component.less'],
})
export class MultiCheckboxFieldComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input() label = '';
  @Input() formControl!: FormControl;
  @Input() kvp: Kvp[] | null = [];
  errorMessage$ = new BehaviorSubject<string>('');
  private valueChangesSubscription: Subscription | undefined;

  constructor(public validation: ValidationService) {}
  ngOnInit(): void {
    if (this.formControl) {
      this.valueChangesSubscription = this.formControl.valueChanges
        .pipe(
          startWith(this.formControl.value),
          map(() => this.validation.getErrors(this.formControl?.errors ?? []))
        )
        .subscribe((errorMessage) => {
          this.errorMessage$.next(errorMessage);
        });
    }
  }
  toggle(key: any) {
    const index = this.formControl.value.indexOf(key);

    if (index >= 0) {
      this.formControl.value.splice(index, 1);
    } else {
      this.formControl.value.push(key);
    }

    // Update the form control's value.
    this.formControl.setValue(this.formControl.value, { emitEvent: true });
  }
  writeValue(obj: any): void {
    //this.formControl.setValue(obj, { emitEvent: false });
  }
  registerOnChange(fn: any): void {
    //this.formControl.valueChanges.subscribe(fn);
  }
  registerOnTouched(fn: any): void {
    //throw new Error('Method not implemented.');
  }
  setDisabledState?(isDisabled: boolean): void {
    //isDisabled ? this.formControl.disable() : this.formControl.enable();
  }

  ngOnDestroy() {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
  }
}
