import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { isDefined } from '../../util/common';

// --- Model ---
export interface Objective {
  Id: number;
  DevPriorityId: number;
  Description: string;
  IsCurrent: boolean;
}

export class SetObjectives {
  static readonly type = '[Objectives] SetObjectives';
  constructor(public items: Objective[]) {}
}

export class AddObjective {
  static readonly type = '[Objectives] AddObjective';
  constructor(public item: Objective) {}
}

export class UpdateObjective {
  static readonly type = '[Objectives] UpdateObjective';
  constructor(public item: Objective) {}
}

export class UpdateIsCurrentObjective {
  static readonly type = '[Objectives] UpdateIsCurrentObjective';
  constructor(public id: number, public isCurrent: boolean) {}
}

export class RemoveObjective {
  static readonly type = '[Objectives] RemoveObjective';
  constructor(public id: number) {}
}

// --- State ---
export interface ObjectivesStateModel {
  items?: Objective[];
}

@State<ObjectivesStateModel>({
  name: 'ObjectivesState',
})
@Injectable()
export class ObjectivesState {
  @Selector()
  static items(state: ObjectivesStateModel) {
    return state.items;
  }

  @Action(SetObjectives)
  set(ctx: StateContext<ObjectivesStateModel>, { items }: SetObjectives) {
    ctx.patchState({
      items: items,
    });
  }

  @Action(AddObjective)
  add(ctx: StateContext<ObjectivesStateModel>, { item }: AddObjective) {
    ctx.setState(
      patch({
        items: append([item]),
      })
    );
  }

  @Action(UpdateIsCurrentObjective)
  updateIsCurrent(
    ctx: StateContext<ObjectivesStateModel>,
    { id, isCurrent }: UpdateIsCurrentObjective
  ) {
    const state = ctx.getState();
    if (!isDefined(state.items)) return;
    const idx = state.items.findIndex((itm) => itm.Id === id);
    const updatedItem = { ...state.items[idx], IsCurrent: isCurrent };
    ctx.setState(
      patch({
        items: updateItem<Objective>((itm) => itm.Id == id, updatedItem),
      })
    );
  }

  @Action(UpdateObjective)
  update(ctx: StateContext<ObjectivesStateModel>, { item }: UpdateObjective) {
    ctx.setState(
      patch({
        items: updateItem<Objective>((itm) => itm.Id == item.Id, item),
      })
    );
  }

  @Action(RemoveObjective)
  remove(ctx: StateContext<ObjectivesStateModel>, { id }: RemoveObjective) {
    ctx.setState(
      patch({
        items: removeItem<Objective>((itm) => itm.Id == id),
      })
    );
  }
}
