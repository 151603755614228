<pms-page
  [title]="'Development Priorities, Objectives and Strategies'"
  [subTitle]="
    'Add, edit and delete development priorities, objectives and strategies'
  "
  [icon]="'list'"
  [loading]="(loading$ | async)!"
>
  <!-- toolbar -->
  <span toolbar>
    <button (click)="add()" class="btn btn-sm btn-warning">
      <i class="fa fa-plus"></i>Add Development Priority
    </button>
  </span>
  <!-- /toolbar -->
  <!-- body -->
  <div body>
    <table class="nc-table table">
      <thead>
        <tr>
          <td>Description</td>
          <td class="text-right">
            <button (click)="toggleShowAll()" class="btn btn-sm btn-dark">
              <i
                class="fa"
                [class.fa-eye]="showAll"
                [class.fa-eye-slash]="!showAll"
              ></i>
            </button>
          </td>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let item of (list$ | async) ?? [] | showall : showAll">
          <td>
            <div class="flex grow justify-between">
              <div
                class="mr-auto"
                [style.text-decoration]="
                  item.IsCurrent ? 'none' : 'line-through'
                "
              >
                {{ item.Description }}
              </div>

              <button
                (click)="expand(item.Id)"
                class="btn btn-sm bg-white italic"
              >
                Objectives&nbsp;&nbsp;
                <i
                  class="fa"
                  [class.fa-angle-down]="item.expanded"
                  [class.fa-angle-right]="!item.expanded"
                ></i>
              </button>
            </div>
            <div>
              <div *ngIf="item.expanded">
                <br />
                <pms-objectives
                  [parentId]="item.Id"
                  [showAll]="showAll"
                  [isCurrent]="item.IsCurrent"
                ></pms-objectives>
              </div>
            </div>
          </td>

          <td class="w-[110px]">
            <div class="btn-group btn-group-sm">
              <button
                title="Edit"
                (click)="edit(item.Id)"
                class="btn btn-sm btn-primary"
              >
                <i class="fa fa-pen"></i>Edit
              </button>
              <button
                title="Hide"
                *ngIf="item.IsCurrent"
                (click)="updateIsCurrent(item, false)"
                class="btn btn-sm btn-outline-primary"
              >
                <i class="fa fa-eye-slash"></i>Disable
              </button>
              <button
                title="Show"
                *ngIf="!item.IsCurrent"
                (click)="updateIsCurrent(item, true)"
                class="btn btn-sm btn-outline-primary"
              >
                <i class="fa fa-eye"></i>Enable
              </button>
              <button
                title="Delete"
                (click)="remove(item.Id)"
                class="btn btn-sm btn-outline-primary"
              >
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- /body -->
</pms-page>
