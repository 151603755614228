<pms-page
  [title]="'Institutional Scorecard'"
  [subTitle]="'Select parameters on right'"
  [icon]="'print'"
  [loading]="(loading$ | async)!"
>
  <!-- toolbar -->
  <span toolbar class="flex items-center gap-1" [formGroup]="filterForm">
    <span class="input-group input-group-sm">
      <span class="input-group-prepend">
        <span class="input-group-text">Year</span>
      </span>
      <select class="form-control input-sm" formControlName="year">
        <option *ngFor="let t of years$ | async" [ngValue]="t.Key">
          {{ t.Value }}
        </option>
      </select>

      <span class="input-group-append">
        <button (click)="load()" class="btn btn-sm btn-success">
          <i class="fa fa-play"></i>
          Go
        </button>
      </span>
    </span>

    <button
      (click)="print()"
      class="whitespace-nowrap btn btn-sm btn-outline-success"
    >
      <i class="fa fa-print"></i>Print
    </button>
  </span>

  <!-- /toolbar -->

  <!-- body -->
  <div body class="h-full">
    <iframe
      #iframe
      class="h-[99%] w-[99%] border border-gray-300 shadow-md"
      id="report"
      [src]="report?.url | safe"
    ></iframe>
  </div>
  <!-- /body -->
</pms-page>
