import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'rating',
})
export class RatingPipe implements PipeTransform {
  transform(value: number): string {
    switch (value) {
      case 1:
        return 'Unacceptable performance';
      case 2:
        return 'Not fully effective';
      case 3:
        return 'Fully effective';
      case 4:
        return 'Performance significantly above expectations';
      case 5:
        return 'Outstanding performance';
      default:
        return '';
    }
  }
}
