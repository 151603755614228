import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, of, switchMap, tap } from 'rxjs';

interface TokenResponse {
  access_token: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private router: Router) {}

  signIn(user: string, password: string): Observable<string[]> {
    const result: string[] = [];
    if (user.length == 0) {
      result.push('Please type in a user name.');
    }
    if (password.length == 0) {
      result.push('Please type in a password.');
    }
    if (result.length > 0) {
      return of(result);
    }

    const data = `grant_type=password&username=${user}&password=${password}`;
    const url = `auth/token`;

    return this.http
      .post<TokenResponse>(url, data, {
        headers: new HttpHeaders().set(
          'Content-Type',
          'application/x-www-form-urlencoded'
        ),
      })
      .pipe(
        tap((response) => {
          localStorage.setItem('access_token', response.access_token);
        }),
        switchMap(() => of([])),
        catchError(() => {
          return of(['Sign-in was unsuccessful.']);
        })
      );
  }

  signOut(): void {
    localStorage.removeItem('access_token');
    this.router.navigate(['/login']);
  }
}
