<div class="modal-header">
  <h4 class="modal-title">Review Diary</h4>
</div>

<pms-progress
  *ngIf="loading$ | async"
  class="block pb-4"
  [barWidth]="'60%'"
></pms-progress>

<div class="modal-body" [hidden]="loading$ | async">
  <div toolbar>
    <div *ngIf="claims.CanChangeDiary" class="nav">
      <div class="form-inline border p-1 ml-auto mb-3">
        <button (click)="add()" class="btn btn btn-danger">
          <i class="fa fa-plus"></i>Add
        </button>
      </div>
    </div>
  </div>

  <table class="nc-table table" ts-wrapper>
    <thead>
      <tr>
        <td ts-criteria="ReviewDate">Date</td>
        <td ts-criteria="ReviewDate">Added By</td>
        <td ts-criteria="Comment">Comment</td>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let item of items$ | async" ts-repeat>
        <td>{{ item.ReviewDate | date : 'dd-MMM-yyyy' }}</td>
        <td>{{ item.AddedBy }}</td>
        <td>{{ item.Comment }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="modal-footer">
  <button class="btn btn-outline-success" (click)="done()">Done</button>
</div>
