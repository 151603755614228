import { Dialog } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Kvp } from '@pmslogic/widgets';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  combineLatest,
  finalize,
  forkJoin,
  map,
  of,
  switchMap,
  take,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';
import { PageComponent } from '../shared/layout/page.component';
import { LoadingService } from '../shared/loading/loading.service';
import { MessageDialogComponent } from '../shared/message-dialog.component';
import { IndicatorTypesService } from './indicators/indicator-types/indicator-types.service';
import { IndicatorComponent } from './indicators/indicator.component';

import { CcrsService } from '../ccrs/ccrs.service';
import { KpasService } from '../kpas/kpas.service';
import { StrategiesService } from '../priorities/strategies/strategies.service';
import { ProjectsService } from '../projects/projects.service';
import { ConfirmDialogComponent } from '../shared/confirm-dialog.component';
import { ImportFromProjectsComponent } from './indicators/from-projects/from-projects.component';
import { ImportComponent as ImportIndicatorsComponent } from './indicators/import/import.component';
import {
  ImportDialogData,
  Indicator,
  IndicatorDialogData,
  IndicatorInfo,
  Score,
} from './indicators/indicator.models';
import { IndicatorsService } from './indicators/indicators.service';
import { Plan, PlansService } from './plans.service';
import { RisksComponent } from './risks/risks.component';
import { Risk, RiskDialogData } from './risks/risks.models';
import { RisksService } from './risks/risks.service';
import { Signature, SignatureComponent } from './signature/signature.component';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './plan.component.html',
  imports: [CommonModule, PageComponent, SignatureComponent],
  styleUrls: ['./plan.component.less'],
})
export class PlanComponent implements OnDestroy {
  id: string;
  loading$ = this.loadingService.isLoading$(this);
  title = 'Performance Plan';

  plan$ = new BehaviorSubject<Plan>({} as Plan);
  indicators$ = new BehaviorSubject<IndicatorInfo[]>([]);
  types$: Observable<Kvp[]> = of([]);

  private destroy$ = new Subject<void>();

  constructor(
    private ccrsService: CcrsService,
    public cdr: ChangeDetectorRef,
    private dialog: Dialog,
    private indicatorTypesService: IndicatorTypesService,
    private indicatorsService: IndicatorsService,
    private kpasService: KpasService,
    private loadingService: LoadingService,
    private plansService: PlansService,
    private projectsService: ProjectsService,
    private risksService: RisksService,
    private route: ActivatedRoute,
    private router: Router,
    private strategiesService: StrategiesService
  ) {
    this.id = this.route.snapshot.params['id'];

    this.loadingService.startLoading(this);

    //plan, indicators, indictaor  types
    const calls = [
      plansService.get$(this.id),
      indicatorsService.list$(this.id),
      indicatorTypesService.dictionary$(),
    ];

    combineLatest(calls)
      .pipe(
        map(([p, i, t]) => {
          this.indicators$.next((i || []) as IndicatorInfo[]);
          this.types$ = of(t as Kvp[]);

          const plan = p as Plan;
          this.plan$.next(plan);
          this.title = `${plan.FullName} - ${plan.FinYear}`;
        }),
        tap(() => {
          this.loadingService.stopLoading(this);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
  print() {
    this.router.navigateByUrl(`plans/print/${this.id}`);
  }
  back() {
    this.router.navigateByUrl(`plans`);
  }
  add(indicatorType: string | number) {
    this.dialog
      .open(IndicatorComponent, {
        data: this.getIndicatorDialogData$(indicatorType, this.plan$),
      })
      .closed.subscribe((result) => {
        if (result) {
          this.loadingService.startLoading(this);
          this.indicatorsService
            .add$(result as IndicatorInfo)
            .pipe(
              take(1),
              map((result) => {
                const index = [...this.indicators$.getValue(), result];
                this.indicators$.next(index);
                this.cdr.markForCheck();
              }),
              finalize(() => this.loadingService.stopLoading(this)),
              catchError((error) => {
                console.error('An error occurred:', error);
                this.dialog.open(MessageDialogComponent, {
                  data: {
                    title: 'Error',
                    text: `${error.error.title}`,
                  },
                });
                return throwError(() => error);
              })
            )
            .subscribe();
        }
      });
  }

  edit(indicator: IndicatorInfo) {
    this.dialog
      .open(IndicatorComponent, {
        data: this.getIndicatorDialogData$(
          indicator.IndicatorType,
          this.plan$,
          indicator.Id
        ),
      })
      .closed.subscribe((result) => {
        if (result) {
          this.loadingService.startLoading(this);
          this.indicatorsService
            .update$(result as IndicatorInfo)
            .pipe(
              take(1),
              map((result) => {
                const indicators = this.indicators$.getValue();
                const index = indicators.findIndex((i) => i.Id === result.Id);
                if (index !== -1) {
                  indicators[index] = result;
                  this.indicators$.next(indicators);
                  this.cdr.markForCheck();
                }
              }),
              finalize(() => this.loadingService.stopLoading(this)),
              catchError((error) => {
                console.error('An error occurred:', error);
                this.dialog.open(MessageDialogComponent, {
                  data: {
                    title: 'Error',
                    text: `${error.error.title}`,
                  },
                });
                return throwError(() => error);
              })
            )
            .subscribe();
        }
      });
  }
  remove(indicator: IndicatorInfo) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          text: 'Are you sure you want to delete this Risk?',
        },
      })
      .closed.subscribe((result) => {
        if (result as boolean) {
          this.loadingService.startLoading(this);
          this.indicatorsService
            .remove$(indicator.Id)
            .pipe(
              take(1),
              map(() => {
                const indicators = this.indicators$.getValue();
                const index = indicators.findIndex(
                  (i) => i.Id === indicator.Id
                );
                if (index !== -1) {
                  indicators.splice(index, 1);
                  this.indicators$.next(indicators);
                  this.cdr.markForCheck();
                }
              }),
              catchError((err) => {
                this.dialog.open(MessageDialogComponent, {
                  data: {
                    title: 'Request Denied',
                    text: 'This record is in use and cannot be deleted.',
                  },
                });
                return throwError(
                  () => new Error(`Delete failed [id=${indicator.Id}]`)
                );
              }),
              finalize(() => this.loadingService.stopLoading(this))
            )
            .subscribe();
        }
      });
  }

  risks(indicator: IndicatorInfo) {
    this.dialog
      .open(RisksComponent, {
        data: {
          indicator$: of(indicator),
          plan$: this.plan$,
          risks$: this.risksService.list$(indicator.Id),
        } as RiskDialogData,
      })
      .closed.subscribe((result) => {
        if (result) {
          indicator.NoOfRisks = result as number;
          this.cdr.markForCheck();
        } else {
          //if the user just clicks off the dialog, without closing/pressing done
          this.loadingService.startLoading(this);
          this.risksService
            .list$(indicator.Id)
            .pipe(take(1))
            .subscribe((r: Risk[]) => {
              indicator.NoOfRisks = r.length;
              this.loadingService.stopLoading(this);
            });
        }
      });
  }
  importFromProjects() {
    const year = this.plan$.getValue().PlanYear;
    this.dialog
      .open(ImportFromProjectsComponent, {
        data: this.indicatorsService.projectIndicators$(year),
      })
      .closed.subscribe((result) => {
        if (!result) return;
        this.importIndicators(this.plan$.getValue().Id, result as Indicator[]);
      });
  }
  import(source: string, type: string | number) {
    this.dialog
      .open(ImportIndicatorsComponent, {
        data: {
          plan$: this.plan$,
          source: source,
          type: type,
        } as ImportDialogData,
      })
      .closed.subscribe((result) => {
        if (!result) return;
        this.importIndicators(this.plan$.getValue().Id, result as Indicator[]);
      });
  }

  importIndicators(planId: number, indicators: Indicator[]) {
    this.loadingService.startLoading(this);

    const calls = indicators.map((pi) => {
      pi.PlanId = planId;
      return this.indicatorsService.add$(pi);
    });
    forkJoin(calls)
      .pipe(
        take(1),
        map((items) => {
          const i = [...this.indicators$.getValue(), ...items];
          this.indicators$.next(i);
          this.cdr.markForCheck();
        }),
        catchError((error) => {
          console.error('An error occurred:', error);
          this.dialog.open(MessageDialogComponent, {
            data: {
              title: 'Error',
              message: JSON.stringify(error),
            },
          });
          return throwError(() => error);
        }),
        finalize(() => {
          this.loadingService.stopLoading(this);
        })
      )
      .subscribe();
  }
  adjusted(ind: IndicatorInfo) {
    const type = ind.IndicatorType;
    let sum = 0.0;

    // Get the current value of the BehaviorSubject
    const indicators = this.indicators$.getValue();

    for (let i = 0; i < indicators.length; i++) {
      const current = indicators[i].IndicatorType;

      if (
        type === current ||
        (type === 'OP' && current === 'IDP') ||
        (type === 'IDP' && current === 'OP')
      )
        sum += indicators[i].Weight;
    }

    if (sum === 0) return 1;

    return ind.Weight / sum;
  }
  signOffEmployee(model: Signature) {
    this.plansService
      .signAsEmployee$(this.id, model.Password)
      .pipe(
        take(1),
        tap(() => {
          this.dialog.open(MessageDialogComponent, {
            data: {
              title: 'Sign-Off Success',
              text: 'The employee has signed-off the plan successfully.',
            },
          });

          const p = this.plan$.getValue();
          p.IsSignedEmployee = true;
          p.SignedDateEmployee = new Date();
          p.CanEdit = false;
          this.plan$.next(p);
        }),
        catchError((err) => {
          this.dialog.open(MessageDialogComponent, {
            data: {
              title: 'Sign-Off Failed',
              text: (err?.Message ?? 'Sign-Off Failed') + '.',
            },
          });
          return of();
        })
      )
      .subscribe();
  }
  signoffManager(model: Signature) {
    this.plansService
      .signAsManager$(this.id, model.Password)
      .pipe(
        take(1),
        tap(() => {
          this.dialog.open(MessageDialogComponent, {
            data: {
              title: 'Sign-Off Success',
              text: 'The manager has signed-off the plan successfully.',
            },
          });

          const p = this.plan$.getValue();
          p.IsSignedManager = true;
          p.SignedDateManager = new Date();
          p.CanEdit = false;
          this.plan$.next(p);
        }),
        catchError((err) => {
          this.dialog.open(MessageDialogComponent, {
            data: {
              title: 'Sign-Off Failed',
              text: (err?.Message ?? 'Sign-Off Failed') + '.',
            },
          });
          return of();
        })
      )
      .subscribe();
  }

  hasIndicators(
    typeKey: string | number,
    indicators: IndicatorInfo[]
  ): boolean {
    return indicators.some((ind) => ind.IndicatorType === typeKey);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  //
  private getIndicatorDialogData$(
    indicatorType: string | number,
    plan$: BehaviorSubject<Plan>,
    indicatorId?: string | number
  ): Observable<IndicatorDialogData> {
    const planYear = plan$.getValue().PlanYear;
    const planId = plan$.getValue().Id;

    return combineLatest([
      plan$,
      this.ccrsService.dictionary$(),
      this.kpasService.dictionary$(),
      this.strategiesService.dictionary$(),
      this.projectsService.dictionary$(planYear),
      indicatorId
        ? this.indicatorsService.get$(indicatorId)
        : of({
            IndicatorType: indicatorType,
            PlanId: planId,
            ScoreList: [{} as Score, {} as Score, {} as Score, {} as Score],
            Weight: 0.1,
          } as Indicator),
    ]).pipe(
      take(1),
      switchMap(([plan, ccrs, kpas, strategies, projects, indicator]) => {
        const output = {
          indicator,
          plan,
          ccrs,
          kpas,
          strategies,
          projects,
        } as IndicatorDialogData;

        return of(output);
      })
    );
  }
}
