<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
</div>

<pms-progress
  *ngIf="loading$ | async"
  class="block pb-4"
  [text]="'Loading'"
  [barWidth]="'60%'"
></pms-progress>

<div class="modal-body" [formGroup]="form" *ngIf="form">
  <div class="row">
    <div class="col-lg">
      <pms-select-field
        *ngIf="indicator.IndicatorType === 'IDP'"
        label="Project"
        ngDefaultControl
        [formControl]="$any(form.get('ProjectId'))"
        [kvp]="projects"
      ></pms-select-field>

      <pms-select-field
        *ngIf="indicator.IndicatorType !== 'CCR'"
        label="Key Performance Area"
        ngDefaultControl
        [formControl]="$any(form.get('KPAId'))"
        [kvp]="kpas"
      ></pms-select-field>

      <pms-select-field
        *ngIf="indicator.IndicatorType === 'CCR'"
        label="Core Competency Requirement"
        ngDefaultControl
        [formControl]="$any(form.get('CCRId'))"
        [kvp]="ccrs"
      ></pms-select-field>

      <pms-select-field
        *ngIf="indicator.IndicatorType !== 'CCR'"
        label="Development Strategy"
        ngDefaultControl
        [formControl]="$any(form.get('DevStrategyId'))"
        [kvp]="strategies"
      ></pms-select-field>

      <pms-input-field
        *ngIf="indicator.IndicatorType !== 'CCR'"
        label="Key Performance Indicator"
        ngDefaultControl
        [formControl]="$any(form.get('Name'))"
        [rows]="2"
      ></pms-input-field>

      <pms-input-field
        label="Annual Target"
        ngDefaultControl
        [formControl]="$any(form.get('TargetAnnual'))"
        [rows]="2"
      ></pms-input-field>

      <pms-input-field
        label="Proof"
        ngDefaultControl
        [formControl]="$any(form.get('Proof'))"
        [rows]="2"
      ></pms-input-field>

      <pms-input-field
        label="Weight (%)"
        type="number"
        ngDefaultControl
        [formControl]="$any(form.get('Weight'))"
        (blurEvent)="formatWeight($event)"
      ></pms-input-field>
    </div>
    <div class="col-lg">
      <div *ngFor="let ctrl of scoreListForms.controls; let i = index">
        <pms-input-field
          ngDefaultControl
          [formControl]="$any(ctrl.get('Target'))"
          label="{{ i + 1 | ordinal }} Quarter Target"
          placeholder="e.g. finish {{ (i + 1) * 25 }}% of construction"
          [rows]="2"
        >
        </pms-input-field>
      </div>
    </div>
  </div>

  <!-- <pre>form: {{ form.getRawValue().Weight | json }}</pre> -->
</div>

<div class="modal-footer">
  <button
    class="btn btn-sm btn-primary"
    [disabled]="form?.disabled || !form?.valid"
    (click)="save()"
  >
    Save
  </button>

  <button class="btn btn-sm btn-default" (click)="cancel()">Cancel</button>
</div>
