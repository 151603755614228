import { Dialog } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { InputFieldComponent, ValidationService } from '@pmslogic/widgets';
import { BehaviorSubject, Observable, combineLatest, map, of } from 'rxjs';
import { StrategiesService } from '../priorities/strategies/strategies.service';
import { Strategy } from '../priorities/strategies/strategies.state';
import { StrategyPickerDialogComponent } from './strategy-picker-dialog.component';

@Component({
  selector: 'pms-strategy-picker',
  standalone: true,
  templateUrl: './strategy-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, InputFieldComponent, ReactiveFormsModule],
})
export class StrategyPickerComponent implements OnInit {
  @Input() formControl: FormControl | undefined;
  messages$ = new BehaviorSubject<string>('');
  selectedStrategy$ = new Observable<Strategy | undefined>();
  constructor(
    private dialog: Dialog,
    public validation: ValidationService,
    private strategiesService: StrategiesService
  ) {}

  showDialog() {
    this.dialog
      .open(StrategyPickerDialogComponent, { data: of({}) })
      .closed.subscribe((result) => {
        if (result) {
          this.formControl?.setValue(result);
        }
      });
  }
  ngOnInit(): void {
    if (!this.formControl) return;

    this.selectedStrategy$ = combineLatest([
      this.formControl.valueChanges,
      this.strategiesService.list$(),
    ]).pipe(
      map(([value, strategies]) => {
        this.validation.getErrors(this.formControl?.errors ?? []);
        return strategies?.find((f) => f.Id == value);
      })
    );
  }
}
