import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { Observable, Subject, map, take, takeUntil } from 'rxjs';

import { InputFieldComponent, SelectFieldComponent } from '@pmslogic/widgets';
import { LoadingService } from '../../../shared/loading/loading.service';
import { ProgressComponent } from '../../../shared/progress/progress.component';
import { ImportIndicator, Indicator } from '../indicator.models';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './from-projects.component.html',
  styleUrls: ['./from-projects.component.less'],
  styles: [
    `
      :host {
        display: block;
        width: 900px;
      }
    `,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputFieldComponent,
    SelectFieldComponent,
    ProgressComponent,
  ],
})
export class ImportFromProjectsComponent implements OnDestroy {
  loading$ = this.loadingService.isLoading$(this);
  private destroy$ = new Subject<void>();
  form: FormGroup;

  constructor(
    public dialogRef: DialogRef<Indicator[] | null>,
    @Inject(DIALOG_DATA) private data$: Observable<Indicator[]>,
    private loadingService: LoadingService,
    private fb: FormBuilder
  ) {
    this.loadingService.startLoading(this);
    this.form = this.fb.group({
      indicators: this.fb.array([]),
    });

    this.data$
      .pipe(
        take(1),
        map((items) => {
          this.form.setControl(
            'indicators',
            this.fb.array(
              items.map((indicator) => {
                const group = this.fb.group({
                  ...indicator,
                  ScoreList: this.fb.array(
                    indicator.ScoreList.map((score) => this.fb.group(score))
                  ),
                  Weight: 0,
                  Selected: false,
                });

                const ctrl = group.get('Weight');
                if (ctrl)
                  ctrl.valueChanges
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(() => {
                      group.patchValue(
                        { Selected: true },
                        { emitEvent: false }
                      );
                    });

                return group;
              })
            )
          );
          this.loadingService.stopLoading(this);
        })
      )
      .subscribe();
  }

  selectAll(value: boolean) {
    const indicatorsControlArray = this.form.get('indicators') as FormArray;
    indicatorsControlArray.controls.forEach((control: AbstractControl) => {
      const formGroup = control as FormGroup;
      formGroup.patchValue({ Selected: value });
    });
  }

  import() {
    this.dialogRef.close(
      (this.form.getRawValue().indicators as ImportIndicator[])
        .filter((f) => f.Selected)
        .map((m) => {
          m.Weight = m.Weight /= 100;
          return m;
        })
    );
  }
  cancel() {
    this.dialogRef.close(null);
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get indicators(): FormArray {
    return this.form.get('indicators') as FormArray;
  }
}
