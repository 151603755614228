import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable, filter, finalize, of, take } from 'rxjs';
import { PageComponent } from '../shared/layout/page.component';
import { LoadingService } from '../shared/loading/loading.service';
import { isDefined } from '../util/common';
import { ShowallPipe } from '../util/pipes/showall.pipe';
import { EmployeeComponent } from './employee.component';
import { EmployeesService } from './employees.service';
import { Employee } from './employees.state';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './employees.component.html',
  imports: [CommonModule, PageComponent, DialogModule, ShowallPipe],
})
export class EmployeesComponent {
  loading$ = this.loadingService.isLoading$(this);
  list$: Observable<Employee[] | undefined>;
  showAll = false;

  constructor(
    private service: EmployeesService,
    private dialog: Dialog,
    private loadingService: LoadingService
  ) {
    this.loadingService.startLoading(this);

    this.list$ = this.service.list$();

    this.service
      .list$()
      .pipe(
        filter((f) => isDefined(f)),
        take(1),
        finalize(() => this.loadingService.stopLoading(this))
      )
      .subscribe();
  }

  add() {
    this.dialog
      .open(EmployeeComponent, { data: of({}) })
      .closed.subscribe((result) => {
        if (result) {
          this.loadingService.startLoading(this);
          this.service
            .add$(result as Employee)
            .pipe(
              take(1),
              finalize(() => this.loadingService.stopLoading(this))
            )
            .subscribe();
        }
      });
  }

  edit(id: number) {
    this.dialog
      .open(EmployeeComponent, { data: this.service.get$(id) })
      .closed.subscribe((result) => {
        if (result) {
          this.loadingService.startLoading(this);
          this.service
            .update$(result as Employee)
            .pipe(
              take(1),
              finalize(() => this.loadingService.stopLoading(this))
            )
            .subscribe();
        }
      });
  }
  updateIsCurrent(item: Employee, isCurrent: boolean) {
    this.loadingService.startLoading(this);
    this.service
      .updateIsCurrent$(item.Id, isCurrent)
      .pipe(
        take(1),
        finalize(() => this.loadingService.stopLoading(this))
      )
      .subscribe();
  }
  toggleShowAll() {
    this.showAll = !this.showAll;
  }
}
