<div class="navbar nc-titlebar">
  <a href="./">
    <img
      title="Home"
      class="img-fluid"
      style="max-height: 50px"
      src="./logo.png"
    />
  </a>

  <h4 class="m-auto text-dark ng-binding">
    {{ title }}
    <span
      class="badge-warning ng-binding"
      [ngStyle]="{ display: isTestSite ? 'inline-block' : 'none' }"
      >Test Site</span
    >
  </h4>

  <div>
    <div>{{ (user$ | async)?.FullName }}</div>
  </div>

  <ul class="navbar-nav">
    <li class="nav-item">
      <button class="btn btn-lg" title="Log out" (click)="logout.emit()">
        <i class="fas fa-sign-out-alt"></i>
      </button>
    </li>
  </ul>
</div>
