<pms-page
  [title]="'Key Performance Areas (KPAs)'"
  [subTitle]="
    'Manage the key performance areas that are available on the high level performance plans'
  "
  [icon]="'calendar-check'"
  [loading]="(loading$ | async)!"
>
  <!-- toolbar -->
  <span toolbar>
    <button (click)="add()" class="btn btn-sm btn-warning">
      <i class="fa fa-plus"></i>Add
    </button>
  </span>
  <!-- /toolbar -->

  <!-- body -->
  <div body>
    <table class="nc-table table">
      <thead>
        <tr>
          <td>Description</td>
          <td></td>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let item of list$ | async">
          <td>{{ item.Description }}</td>
          <td class="w-[110px]">
            <div class="btn-group btn-group-sm">
              <button (click)="edit(item.Id)" class="btn btn-sm btn-primary">
                <i class="fa fa-pen"></i>Edit
              </button>
              <button
                (click)="remove(item.Id)"
                class="btn btn-sm btn-outline-primary"
              >
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- /body -->
</pms-page>
