<pms-page
  [title]="'Policies and Documents'"
  [subTitle]="'Performance management related documentation'"
  [icon]="'book'"
  [loading]="(loading$ | async)!"
>
  <!-- toolbar -->
  <span toolbar>
    <div [formGroup]="fileForm" class="nav form-inline border p-1">
      <input
        type="file"
        class="file-upload"
        (change)="onFileSelected($event)"
      />
      <button class="btn btn-danger" (click)="upload()">
        <i class="fa fa-upload"></i>Upload
      </button>
    </div>
  </span>
  <!-- /toolbar -->

  <!-- body -->
  <div body>
    <div class="list-group">
      <div
        *ngFor="let item of list$ | async"
        class="list-group-item list-group-item-action"
      >
        <span><i class="fa fa-paperclip circle-icon"></i></span>

        &nbsp;

        <span>{{ item.Name }}</span>

        <div class="btn-group btn-group-sm float-right">
          <button
            class="ml-auto btn btn-sm btn-primary"
            (click)="download(item.FileId, item.Name)"
          >
            <i class="fa fa-download"></i>
            download
          </button>

          <button
            class="ml-auto btn btn-sm btn-outline-primary float-right"
            (click)="remove(item.Id)"
          >
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- /body -->
</pms-page>
