import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { Score, ScoreHistory, StaffComment } from './scores.models';

@Injectable({
  providedIn: 'root',
})
export class ScoresService {
  constructor(private http: HttpClient) {}

  list$(reviewId: number) {
    return this.http.get<Score[]>(`api/reviews/${reviewId}/scores`);
  }

  indicatorScores$(indicatorId: number) {
    return this.http.get<ScoreHistory[]>(
      `api/indicators/${indicatorId}/scores`
    );
  }

  getStaffComments$(id: number) {
    return this.http.get<StaffComment[]>(
      `api/indicatorscores/${id}/staffcomments`
    );
  }

  update$(item: Score) {
    return this.http.put<Score>(`api/indicatorscores/${item.Id}`, item);
  }

  isComplete(score: Score) {
    return (
      !isEmpty(score.Actual) &&
      score.Score > 0 &&
      !isEmpty(score) &&
      score.FinalScore > 0
    );
  }
}
