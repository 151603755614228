import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, switchMap } from 'rxjs';
import { AddDoc, Doc, DocsState, RemoveDoc, SetDocs } from './documents.state';

@Injectable({
  providedIn: 'root',
})
export class DocsService {
  constructor(private http: HttpClient, private store: Store) {}

  load() {
    this.http
      .get<Doc[]>(`api/documents`)
      .subscribe((items) => this.store.dispatch(new SetDocs(items)));
  }

  list$() {
    return this.store.select(DocsState.items);
  }

  get$(id: string) {
    return this.http.get<Blob>(`api/files/${id}`, {
      responseType: 'arraybuffer' as 'json',
    });
  }

  add$(item: File) {
    const formData = new FormData();
    formData.append('file', item);

    return this.http
      .post<Doc>(`api/documents`, formData)
      .pipe(
        switchMap((result) =>
          this.store.dispatch(new AddDoc(result)).pipe(map(() => result))
        )
      );
  }

  remove$(id: number) {
    return this.http
      .delete<void>(`api/documents/${id}`)
      .pipe(switchMap(() => this.store.dispatch(new RemoveDoc(id))));
  }
}
