import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ScoreHistory } from '../scores.models';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  templateUrl: './score-history.component.html',
  styles: [
    `
      :host {
        display: block;
        width: 800px;
      }
    `,
  ],
})
export class ScoreHistoryComponent {
  items$: Observable<ScoreHistory[]>;

  constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) data$: Observable<ScoreHistory[]>
  ) {
    this.items$ = data$;
  }

  close() {
    this.dialogRef.close(null);
  }
}
