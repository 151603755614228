import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  inject,
  input,
  signal,
} from '@angular/core';
import { catchError, finalize, map, take, throwError } from 'rxjs';
import { AcceptControlComponent } from '../../../shared/accept-control.component';
import { ConfirmDialogComponent } from '../../../shared/confirm-dialog.component';
import { PageComponent } from '../../../shared/layout/page.component';
import { LoadingService } from '../../../shared/loading/loading.service';
import { MessageDialogComponent } from '../../../shared/message-dialog.component';
import { isDefined } from '../../../util/common';
import { AttachmentsComponent } from '../attachments/attachments.component';
import { Claims } from '../review.models';
import { PdpItemComponent } from './pdp-item.component';
import { PDP_ITEM_ATTACHMENTS_PATH, PdpItem } from './pdp-items.models';
import { PdpItemsService } from './pdp-items.service';

// TODO: This component should accept a list of PdpItems as input.
@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'pms-pdp-items',
  imports: [CommonModule, PageComponent, DialogModule, AcceptControlComponent],
  templateUrl: './pdp-items.component.html',
  styleUrls: ['./pdp-items.component.less'],
})
export class PdpItemsComponent {
  private pdpItemsService = inject(PdpItemsService);
  private loadingService = inject(LoadingService);
  private dialog = inject(Dialog);

  employeeId = input<number | undefined>();
  claims = input<Claims>();

  showAcceptControl = computed(() => this.claims()?.CanApprovePdpItems);

  items = signal<PdpItem[]>([]);

  constructor(private cdr: ChangeDetectorRef) {
    effect(
      () => {
        const value = this.employeeId();
        if (isDefined(value)) {
          this.pdpItemsService
            .list$(value)
            .pipe(take(1))
            .subscribe((pdpItems) => {
              this.items.set(pdpItems);
            });
        }
      },
      { allowSignalWrites: true }
    );
  }

  add() {
    this.dialog
      .open(PdpItemComponent, { data: { EmployeeId: this.employeeId } })
      .closed.subscribe((result) => {
        if (result) {
          this.loadingService.startLoading(this);
          this.pdpItemsService
            .add$(result as PdpItem)
            .pipe(take(1))
            .subscribe((item) => {
              this.items.set([...this.items(), item]);
              this.cdr.markForCheck();
              this.loadingService.stopLoading(this);
            });
        }
      });
  }

  edit(item: PdpItem) {
    this.dialog
      .open(PdpItemComponent, { data: item })
      .closed.subscribe((result) => {
        if (result) {
          this.loadingService.startLoading(this);
          this.pdpItemsService
            .update$(result as PdpItem)
            .pipe(take(1))
            .subscribe((item) => {
              const items = this.items();
              const idx = items.findIndex((f) => f.Id == item.Id);
              items[idx] = item;
              this.items.set(items);
              this.cdr.markForCheck();
              this.loadingService.stopLoading(this);
            });
        }
      });
  }

  approve(item: PdpItem, approved: boolean | null) {
    this.loadingService.startLoading(this);
    this.pdpItemsService
      .update$({ ...item, Approved: approved })
      .pipe(take(1))
      .subscribe((item) => {
        const items = this.items();
        const idx = items.findIndex((f) => f.Id == item.Id);
        items[idx] = item;
        this.items.set(items);
        this.cdr.markForCheck();
        this.loadingService.stopLoading(this);
      });
  }

  files(id: number) {
    this.dialog
      .open(AttachmentsComponent, {
        data: {
          parentId: id,
          path: PDP_ITEM_ATTACHMENTS_PATH,
          claims: this.claims ?? ({} as Claims),
          disableApprovals: true,
        },
      })
      .closed.subscribe((result) => {
        const fileCount = result as number;
        const items = this.items();
        const item = items.find((f) => f.Id == id);
        if (item) {
          item.NoOfFiles = fileCount;
          this.items.set(items);
        }
      });
  }

  remove(id: number) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          text: 'Are you sure you want to delete this item?',
        },
      })
      .closed.subscribe((result) => {
        if (result as boolean) {
          this.loadingService.startLoading(this);
          this.pdpItemsService
            .remove$(id)
            .pipe(
              take(1),
              map(() => {
                const items = this.items();
                const index = items.findIndex((item) => item.Id === id);
                if (index !== -1) {
                  items.splice(index, 1);
                }
                this.items.set(items);
              }),
              catchError(() => {
                this.dialog.open(MessageDialogComponent, {
                  data: {
                    title: 'Request Denied',
                    text: 'This record is in use and cannot be deleted.',
                  },
                });
                return throwError(() => new Error(`Delete failed [id=${id}]`));
              })
            )
            .pipe(finalize(() => this.loadingService.stopLoading(this)))
            .subscribe();
        }
      });
  }
}
