import {
  DIALOG_DATA,
  Dialog,
  DialogModule,
  DialogRef,
} from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, catchError, throwError } from 'rxjs';
import { EmployeesService } from '../../employees/employees.service';
import { MessageDialogComponent } from '../../shared/message-dialog.component';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ReactiveFormsModule, DialogModule],
  templateUrl: './change-password.component.html',
  styles: [
    `
      :host {
        display: block;
        width: 300px;
      }
    `,
  ],
})
export class ChangePasswordComponent {
  form: FormGroup;
  message$ = new BehaviorSubject('');

  constructor(
    private employeesService: EmployeesService,
    private dialog: Dialog,
    private dialogRef: DialogRef<void>,
    @Inject(DIALOG_DATA) private data: number
  ) {
    this.form = new FormGroup({
      Password: new FormControl('', [Validators.required]),
      Password2: new FormControl('', [Validators.required]),
    });
  }
  save() {
    const val = this.form.value;
    if (val.Password !== val.Password2) {
      this.message$.next('Passwords do not match');
    } else {
      this.employeesService
        .reset$(this.data, { Password: val.Password })
        .pipe(
          catchError(() => {
            this.dialogRef.close();
            this.dialog.open(MessageDialogComponent, {
              data: {
                title: 'Error',
                text: 'An error occurred. Password was not changed.',
              },
            });
            return throwError(() => new Error('Password change failed'));
          })
        )
        .subscribe(() => {
          this.dialogRef.close();
          this.dialog.open(MessageDialogComponent, {
            data: {
              title: 'Success',
              text: 'Password changed successfully.',
            },
          });
        });
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
