import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Permissions, User } from '../../user/user.models';
import { UserService } from '../../user/user.service';

interface Section {
  title: string;
  links: Link[];
}

interface Link {
  title: string;
  path: string | null;
  method: (() => void) | null;
}

@Component({
  standalone: true,
  imports: [CommonModule, RouterLink],
  selector: 'pms-menu',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.less'],
})
export class MenuComponent {
  email = `mailto:${environment.supportEmail}`;
  sections$ = new BehaviorSubject<Section[]>([]);

  @Output() logout = new EventEmitter<void>();
  @Output() changePassword = new EventEmitter<void>();

  constructor(userService: UserService) {
    userService.getUser$().subscribe((user) => this.buildMenu(user));
  }

  private buildMenu(user: User) {
    const sections: Section[] = [];
    this.addHomeSection(sections);
    this.addCaptureSection(sections, user.Permissions);
    this.addReportsSection(sections, user.Permissions);
    this.addSetupSection(sections, user.Permissions);
    this.addUserDetailsSection(sections);
    this.sections$.next(sections);
  }

  private addHomeSection(sections: Section[]) {
    sections.push({
      title: 'HOME',
      links: [
        {
          title: 'Policies and Documents',
          path: '/documents',
          method: null,
        },
      ],
    });
  }

  private addCaptureSection(sections: Section[], permissions: Permissions) {
    const links = [] as Link[];

    links.push({ title: 'Performance Plans', path: '/plans', method: null });

    if (permissions.CanManageProjects) {
      links.push({ title: 'Projects', path: '/projects', method: null });
    }

    if (permissions.CanManageEmployees) {
      links.push({ title: 'Employees', path: '/employees', method: null });
    }

    sections.push({ title: 'CAPTURE', links });
    return links;
  }

  private addReportsSection(sections: Section[], permissions: Permissions) {
    const links = [] as Link[];

    links.push({
      title: 'Institutional Scorecard',
      path: '/scorecard',
      method: null,
    });

    if (permissions.CanViewManagementReports) {
      links.push({
        title: 'Performance Reports',
        path: '/performance',
        method: null,
      });
    }

    if (permissions.CanEditPlans) {
      links.push({
        title: 'Planless Projects',
        path: '/planless',
        method: null,
      });
    }

    if (permissions.CanViewPDPReport) {
      links.push({
        title: 'Personal Dev. Plans',
        path: '/pdps',
        method: null,
      });
    }

    sections.push({ title: 'REPORTS', links });
  }

  private addSetupSection(sections: Section[], permissions: Permissions) {
    const links = [] as Link[];

    if (permissions.CanManageDepartments) {
      links.push({ title: 'Departments', path: '/depts', method: null });
    }

    if (permissions.CanManagePriorities) {
      links.push({
        title: 'Development Priorities',
        path: '/priorities',
        method: null,
      });
    }

    if (permissions.CanManageCCRs) {
      links.push({ title: 'CCRs', path: '/ccrs', method: null });
    }

    if (permissions.CanManageKPAs) {
      links.push({ title: 'KPAs', path: '/kpas', method: null });
    }

    if (permissions.CanChangeSettings) {
      links.push({
        title: 'System Settings',
        path: '/settings',
        method: null,
      });
    }

    if (links.length > 0) {
      sections.push({ title: 'SET-UP', links });
    }
  }

  private addUserDetailsSection(sections: Section[]) {
    sections.push({
      title: 'USER DETAILS',
      links: [
        {
          title: 'Change Password',
          path: null,
          method: () => this.changePassword.emit(),
        },
        { title: 'Logoff', path: null, method: () => this.logout.emit() },
      ],
    });
  }
}
