<div class="nc-progress">
  <div class="nc-progress-container" style="width:{{ barWidth }}">
    <div
      class="progress-bar bg-info text-light progress-bar-striped active"
      role="progressbar"
      aria-valuenow="100"
      aria-valuemin="0"
      aria-valuemax="100"
      style="width: 100%"
    >
      <span class="progress-text">
        <span>{{ text }}</span>
        <span class="dotdotdot"></span>
      </span>
    </div>
  </div>
</div>
