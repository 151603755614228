import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InputFieldComponent, ValidationService } from '@pmslogic/widgets';
import { Observable } from 'rxjs';
import { Dept } from './depts.state';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ReactiveFormsModule, InputFieldComponent],
  providers: [ValidationService],
  templateUrl: './dept.component.html',
  styles: [
    `
      :host {
        display: block;
        width: 300px;
      }
    `,
  ],
})
export class DeptComponent implements OnInit {
  form: FormGroup;

  constructor(
    public dialogRef: DialogRef<Dept | null>,
    @Inject(DIALOG_DATA) private data$: Observable<Partial<Dept>>,
    validation: ValidationService
  ) {
    this.form = new FormGroup({
      Id: new FormControl(),
      Code: new FormControl('', [validation.required()]),
      Description: new FormControl('', [validation.required()]),
    });

    this.form.disable();
  }
  ngOnInit(): void {
    this.data$.subscribe((item) => {
      this.form.patchValue(item);
      this.form.enable();
    });
  }
  save() {
    this.dialogRef.close(this.form.value);
  }

  cancel() {
    this.dialogRef.close(null);
  }
}
