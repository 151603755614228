import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showall',
  standalone: true,
})
export class ShowallPipe implements PipeTransform {
  transform<T extends { IsCurrent: boolean }>(
    items: T[] | null,
    show: boolean
  ): T[] | null {
    if (!items) return null;
    return items.filter((item) => show || item.IsCurrent);
  }
}
