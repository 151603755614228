import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Kvp } from '@pmslogic/widgets';
import { Observable, map, switchMap } from 'rxjs';
import {
  AddEmployee,
  Employee,
  EmployeesState,
  SetEmployees,
  UpdateEmployee,
  UpdateIsCurrentEmployee,
} from './employees.state';

@Injectable({
  providedIn: 'root',
})
export class EmployeesService {
  constructor(private http: HttpClient, private store: Store) {
    this.http
      .get<Employee[]>(`api/employees`)
      .subscribe((items) => this.store.dispatch(new SetEmployees(items)));
  }

  dictionary$(): Observable<Kvp[]> {
    return this.store.select(EmployeesState.items).pipe(
      map((items) =>
        (items || [])
          .filter((f) => f.IsCurrent)
          .map((item) => ({
            Key: item.Id,
            Value: item.FullName,
          }))
          .sort((a, b) => a.Value.localeCompare(b.Value))
      )
    );
  }

  reset$(id: number, password: { Password: string }) {
    return this.http.put<void>(`api/employees/${id}/reset`, password);
  }

  list$() {
    return this.store.select(EmployeesState.items);
  }

  get$(id: number) {
    return this.http.get<Employee>(`api/employees/${id}`);
  }

  add$(item: Partial<Employee>) {
    return this.http
      .post<Employee>(`api/employees`, item)
      .pipe(
        switchMap((result) =>
          this.store.dispatch(new AddEmployee(result)).pipe(map(() => result))
        )
      );
  }
  update$(item: Partial<Employee>) {
    return this.http
      .put<Employee>(`api/employees/${item.Id}`, item)
      .pipe(
        switchMap((result) =>
          this.store
            .dispatch(new UpdateEmployee(result))
            .pipe(map(() => result))
        )
      );
  }
  updateIsCurrent$(id: number, isCurrent: boolean) {
    return this.http
      .put<boolean>(`api/employees/${id}/iscurrent`, isCurrent)
      .pipe(
        switchMap((result) =>
          this.store
            .dispatch(new UpdateIsCurrentEmployee(id, isCurrent))
            .pipe(map(() => result))
        )
      );
  }
}
