import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="flex min-h-screen items-center justify-center">
      <div class="text-center space-y-4">
        <h3><i class="fa fa-ban mr-2"></i>System Not Available</h3>
        <p class="text-gray-700 text-lg">
          The right to use this system has expired. Please contact support.
        </p>
      </div>
    </div>
  `,
  styles: [
    `
      .expiry-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
      }
    `,
  ],
})
export class LicenceExpiredComponent {}
