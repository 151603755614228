import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { environment } from '../../../environments/environment';
import { UserService } from '../../user/user.service';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'pms-navbar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.less'],
})
export class NavbarComponent {
  user$ = this.userService.getUser$();
  @Output() logout = new EventEmitter<void>();

  title = `${environment.shortName} Performance Management System`;
  isTestSite = environment.isTestSite;

  constructor(private userService: UserService) {}
}
