<div class="modal-header">
  <h4 class="modal-title">Select Strategy</h4>
</div>
<div class="modal-body">
  <div class="flex items-center">
    <button
      *ngIf="(currentStep$ | async)! >= 2"
      (click)="back()"
      class="btn btn-sm btn-outline-primary"
    >
      <i class="fa fa-angle-left"></i>
    </button>
    &nbsp;
    <span class="nc-field-label">{{ title$ | async }}</span>
  </div>
  <hr />
  <div class="list-group" *ngIf="(currentStep$ | async) === 1">
    <button
      *ngFor="let item of priorities$ | async"
      (click)="step(2, item.Id)"
      class="list-group-item list-group-item-action"
    >
      {{ item.Description }}
    </button>
  </div>
  <div class="list-group" *ngIf="(currentStep$ | async) === 2">
    <button
      *ngFor="let item of objectives$ | async"
      (click)="step(3, item.Id)"
      class="list-group-item list-group-item-action"
    >
      {{ item.Description }}
    </button>
  </div>
  <div class="list-group" *ngIf="(currentStep$ | async) === 3">
    <button
      *ngFor="let item of strategies$ | async"
      (click)="select(item.Id)"
      class="list-group-item list-group-item-action"
    >
      {{ item.Description }}
    </button>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-sm btn-default" (click)="done()">Cancel</button>
</div>
