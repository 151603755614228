<div class="modal-header">
  <h4 class="modal-title">Assessment</h4>
</div>
<div class="modal-body">
  <div>
    <span style="font-weight: bold">{{ model.Employee }}</span
    >&nbsp;-&nbsp;
    <span>Quarter {{ model.Quarter }}, {{ model.Year | finyear }}</span>
  </div>
  <br />
  <table class="nc-table table">
    <thead>
      <tr>
        <th>KPA</th>
        <th>Weight</th>
        <th>Personal</th>
        <th>Final</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of model.KPAs">
        <td>{{ item.Description }}</td>
        <td>{{ item.Weight * 100 | number : '1.1-1' }}%</td>
        <td>{{ item.PersonalScore | number : '1.1-1' }}</td>
        <td>{{ item.FinalScore | number : '1.1-1' }}</td>
      </tr>
      <tr class="total">
        <td>Total:</td>
        <td>100%</td>
        <td>{{ model.KPATotalPersonalScore | number : '1.1-1' }}</td>
        <td>{{ model.KPATotalFinalScore | number : '1.1-1' }}</td>
      </tr>
      <tr class="total">
        <td colspan="3">KPA weight</td>
        <td>80%</td>
      </tr>
      <tr class="total">
        <td colspan="3">KPA SCORE</td>
        <td>{{ model.KPAAdjustedScore * 100 | number : '1.0-0' }}%</td>
      </tr>
    </tbody>
  </table>
  <br />

  <table class="nc-table table">
    <thead>
      <tr>
        <th>CCR</th>
        <th>Weight</th>
        <th>Personal</th>
        <th>Final</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of model.CCRs">
        <td>{{ item.Description }}</td>
        <td>{{ item.Weight * 100 | number : '1.1-1' }}%</td>
        <td>{{ item.PersonalScore | number : '1.1-1' }}</td>
        <td>{{ item.FinalScore | number : '1.1-1' }}</td>
      </tr>
    </tbody>
    <tfoot>
      <tr class="total">
        <td>Total:</td>
        <td>100%</td>
        <td>{{ model.CCRTotalPersonalScore | number : '1.1-1' }}</td>
        <td>{{ model.CCRTotalFinalScore | number : '1.1-1' }}</td>
      </tr>
      <tr class="total">
        <td colspan="3">CCR weight</td>
        <td>20%</td>
      </tr>
      <tr class="total">
        <td colspan="3">CCR SCORE</td>
        <td>{{ model.CCRAdjustedScore * 100 | number : '1.0-0' }}%</td>
      </tr>
    </tfoot>
  </table>

  <br />
  <h4>FINAL SCORE: {{ model.TotalScore * 100 | number : '1.1-1' }}</h4>
</div>
<!--modal-body-->

<div class="modal-footer">
  <button class="btn btn-primary" (click)="dialogRef.close()">Close</button>
</div>
