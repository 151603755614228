import { Route } from '@angular/router';
import { authGuard } from './auth/auth.guard';
import { LoginComponent } from './auth/login/login.component';
import { CcrsComponent } from './ccrs/ccrs.component';
import { DeptsComponent } from './depts/depts.component';
import { DocumentsComponent } from './documents/documents.component';
import { EmployeesComponent } from './employees/employees.component';
import { KpasComponent } from './kpas/kpas.component';
import { LicenceExpiredComponent } from './licence/licence-expired.component';
import { licenceGuard } from './licence/licence.guard';
import { MainComponent } from './main/main.component';
import { PdpsComponent } from './pdps/pdps.component';
import { PerformanceComponent } from './performance/performance.component';
import { PlanlessComponent } from './planless/planless.component';
import { PlanComponent } from './plans/plan.component';
import { PlansComponent } from './plans/plans.component';
import { PrintPlanComponent } from './plans/print-plan.component';
import { PrintReviewComponent } from './plans/print-review.component';
import { ReviewComponent } from './plans/review/review.component';
import { PrioritiesComponent } from './priorities/priorities.component';
import { ProjectsComponent } from './projects/projects.component';
import { ScorecardComponent } from './scorecard/scorecard.component';
import { SettingsComponent } from './settings/settings.component';
import { VideosComponent } from './videos/videos.component';

export const enum Routes {
  Login = 'login',
  LicenceExpired = 'licence-expired',
  Documents = 'documents',
  Plans = 'plans',
  Plan = 'plans/:id',
  PrintPlan = 'plans/print/:id',
  Review = 'reviews/:id',
  PrintReview = 'reviews/print/:id',
  Projects = 'projects',
  Employees = 'employees',
  Scorecard = 'scorecard',
  Performance = 'performance',
  Planless = 'planless',
  Pdps = 'pdps',
  Depts = 'depts',
  Priorities = 'priorities',
  Ccrs = 'ccrs',
  Kpas = 'kpas',
  Settings = 'settings',
  Password = 'password',
  Videos = 'videos',
}

export const appRoutes: Route[] = [
  {
    path: Routes.Login,
    component: LoginComponent,
    canActivate: [licenceGuard],
  },
  { path: Routes.LicenceExpired, component: LicenceExpiredComponent },
  {
    path: '',
    component: MainComponent,
    canActivate: [licenceGuard, authGuard],
    children: [
      { path: '', redirectTo: Routes.Plans, pathMatch: 'full' },
      // Home
      {
        path: Routes.Documents,
        component: DocumentsComponent,
        canActivate: [licenceGuard, authGuard],
      },
      // Capture
      {
        path: Routes.Plans,
        component: PlansComponent,
        canActivate: [licenceGuard, authGuard],
      },
      {
        path: Routes.Plan,
        component: PlanComponent,
        canActivate: [licenceGuard, authGuard],
      },
      {
        path: Routes.PrintPlan,
        component: PrintPlanComponent,
        canActivate: [licenceGuard, authGuard],
      },
      {
        path: Routes.Review,
        component: ReviewComponent,
        canActivate: [licenceGuard, authGuard],
      },
      {
        path: Routes.PrintReview,
        component: PrintReviewComponent,
        canActivate: [licenceGuard, authGuard],
      },
      {
        path: Routes.Projects,
        component: ProjectsComponent,
        canActivate: [licenceGuard, authGuard],
      },
      {
        path: Routes.Employees,
        component: EmployeesComponent,
        canActivate: [licenceGuard, authGuard],
      },
      // Reports
      {
        path: Routes.Scorecard,
        component: ScorecardComponent,
        canActivate: [licenceGuard, authGuard],
      },
      {
        path: Routes.Performance,
        component: PerformanceComponent,
        canActivate: [licenceGuard, authGuard],
      },
      {
        path: Routes.Planless,
        component: PlanlessComponent,
        canActivate: [licenceGuard, authGuard],
      },
      {
        path: Routes.Pdps,
        component: PdpsComponent,
        canActivate: [licenceGuard, authGuard],
      },
      // Set-Up
      {
        path: Routes.Depts,
        component: DeptsComponent,
        canActivate: [licenceGuard, authGuard],
      },
      {
        path: Routes.Priorities,
        component: PrioritiesComponent,
        canActivate: [licenceGuard, authGuard],
      },
      {
        path: Routes.Ccrs,
        component: CcrsComponent,
        canActivate: [licenceGuard, authGuard],
      },
      {
        path: Routes.Kpas,
        component: KpasComponent,
        canActivate: [licenceGuard, authGuard],
      },
      {
        path: Routes.Settings,
        component: SettingsComponent,
        canActivate: [licenceGuard, authGuard],
      },
      // Help
      {
        path: Routes.Videos,
        component: VideosComponent,
        canActivate: [licenceGuard, authGuard],
      },
    ],
  },
];
