import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InputFieldComponent, ValidationService } from '@pmslogic/widgets';
import {
  BehaviorSubject,
  Subject,
  catchError,
  combineLatest,
  debounceTime,
  filter,
  finalize,
  forkJoin,
  map,
  mergeMap,
  of,
  take,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog.component';
import { DictionariesService } from '../../shared/dictionaries.service';
import { PageComponent } from '../../shared/layout/page.component';
import { LoadingService } from '../../shared/loading/loading.service';
import { MessageDialogComponent } from '../../shared/message-dialog.component';
import { isDefined } from '../../util/common';
import {
  Signature,
  SignatureComponent,
} from '../signature/signature.component';
import { InterimReviewsComponent } from './interim-reviews/interim-reviews.component';
import { PdpItemsComponent } from './pdp-items/pdp-items.component';
import { Review } from './review.models';
import { ReviewService } from './review.service';
import { ScoreComponent } from './scores/score.component';
import { Score } from './scores/scores.models';
import { ScoresService } from './scores/scores.service';

interface CompletedScore extends Score {
  completed: boolean;
}

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    DialogModule,
    InputFieldComponent,
    PageComponent,
    PdpItemsComponent,
    ReactiveFormsModule,
    ScoreComponent,
    SignatureComponent,
  ],
  providers: [ValidationService],
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.less'],
})
export class ReviewComponent implements OnDestroy {
  private destroy$ = new Subject<void>();
  private scores: CompletedScore[] = [];

  form: FormGroup;

  loading$ = this.loadingService.isLoading$(this);
  types$ = this.dictionariesService.dictionary$('indicatortypes');
  isComplete$ = new BehaviorSubject(false);
  canEdit$ = new BehaviorSubject(false);
  saving$ = new BehaviorSubject(false);

  title = 'Review';
  id: number;
  item$ = new BehaviorSubject<Review | null>(null);
  scores$ = new BehaviorSubject<Score[]>([]);

  constructor(
    private reviewService: ReviewService,
    private scoresService: ScoresService,
    private dictionariesService: DictionariesService,
    private dialog: Dialog,
    private loadingService: LoadingService,
    private router: Router,
    route: ActivatedRoute
  ) {
    this.id = Number(route.snapshot.params['id']);

    this.form = new FormGroup({
      OutOfPlanActivities: new FormControl(''),
      EnoughTime: new FormControl(''),
      ManagerComment: new FormControl(''),
      EmployeeComment: new FormControl(''),
    });

    this.loadingService.startLoading(this);

    combineLatest([reviewService.get$(this.id), scoresService.list$(this.id)])
      .pipe(
        filter(([review, scores]) => isDefined(review) && isDefined(scores)),
        mergeMap(([review, scores]: [Review, Score[]]) => {
          const scoreWithComments$ = scores.map((score) =>
            score.Type === 'IDP'
              ? this.scoresService.getStaffComments$(score.Id).pipe(
                  map((comments) => {
                    return { ...score, comments: comments ?? [] };
                  })
                )
              : of({ ...score, comments: [] })
          );

          return forkJoin(scoreWithComments$).pipe(
            map((scores) => {
              this.title = `${review.FullName} - ${review.FinYear} - Quarter ${review.QuarterNo}`;
              this.item$.next(review);
              this.scores$.next(scores);
              this.setScores(scores);
              this.initForm(review);
            }),
            take(1),
            finalize(() => this.loadingService.stopLoading(this))
          );
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initForm(review: Review) {
    this.form.patchValue(review);

    if (!review.Claims.CanChangeGeneralComments) {
      this.form.get('OutOfPlanActivities')?.disable();
      this.form.get('EnoughTime')?.disable();
    }

    if (!review.Claims.CanChangeManagerComments) {
      this.form.get('ManagerComment')?.disable();
    }

    if (!review.Claims.CanChangeEmployeeComments) {
      this.form.get('EmployeeComment')?.disable();
    }

    this.form.valueChanges
      .pipe(debounceTime(1000), takeUntil(this.destroy$))
      .subscribe(() => {
        const item = { ...this.item$.getValue(), ...this.form.value };
        this.saving$.next(true);
        this.reviewService
          .update$(item)
          .pipe(
            takeUntil(this.destroy$),
            catchError(() => {
              this.dialog.open(MessageDialogComponent, {
                data: {
                  title: 'Failed to save',
                  text: 'There was an issue saving the last changes you made. Try refreshing your browser. If that does not work, contact support.',
                },
              });
              this.saving$.next(false);
              return throwError(() => new Error('Review save failed'));
            })
          )
          .subscribe(() => {
            this.saving$.next(false);
            this.item$.next(item);
          });
      });
  }

  onScoreChanged(score: Score) {
    this.saving$.next(true);

    this.scoresService
      .update$(score)
      .pipe(
        takeUntil(this.destroy$),
        catchError(() => {
          this.dialog.open(MessageDialogComponent, {
            data: {
              title: 'Failed to save',
              text: 'There was an issue saving the last changes you made. Try refreshing your browser. If that does not work, contact support.',
            },
          });
          this.saving$.next(false);
          return throwError(() => new Error('Score save failed'));
        })
      )
      .subscribe(() => {
        this.saving$.next(false);
        this.scores = this.scores.map((sc) =>
          sc.Id === score.Id
            ? { ...score, completed: this.scoresService.isComplete(score) }
            : sc
        );
        this.isComplete$.next(this.scores.every((score) => score.completed));
      });
  }

  signOffEmployee(model: Signature) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          text: 'I confirm that my manager has discussed my Personal Development Plan (PDP) with me.',
        },
      })
      .closed.subscribe((result) => {
        if (result as boolean) {
          this.reviewService
            .signAsEmployee$(this.id, model.Password)
            .pipe(
              take(1),
              tap((claims) => {
                this.dialog.open(MessageDialogComponent, {
                  data: {
                    title: 'Sign-Off Success',
                    text: `The ${model.Designation} has signed-off the review successfully.`,
                  },
                });

                const item = this.item$.getValue();
                if (!item) return;
                item.IsSignedEmployee = true;
                item.SignedDateEmployee = new Date();
                item.Claims = claims;
                this.item$.next(item);
              }),
              catchError((err) => {
                this.dialog.open(MessageDialogComponent, {
                  data: {
                    title: 'Sign-Off Failed',
                    text: (err?.Message ?? 'Sign-Off Failed') + '.',
                  },
                });
                return of();
              })
            )
            .subscribe();
        }
      });
  }

  signOffManager(model: Signature) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          text: "I confirm that I have discussed the employee's Personal Development Plan (PDP) with them.",
        },
      })
      .closed.subscribe((result) => {
        if (result as boolean) {
          this.reviewService
            .signAsManager$(this.id, model.Password)
            .pipe(
              take(1),
              tap((claims) => {
                this.dialog.open(MessageDialogComponent, {
                  data: {
                    title: 'Sign-Off Success',
                    text: `The ${model.Designation} has signed-off the review successfully.`,
                  },
                });

                const item = this.item$.getValue();
                if (!item) return;
                item.IsSignedManager = true;
                item.SignedDateManager = new Date();
                item.Claims = claims;
                this.item$.next(item);
              }),
              catchError((err) => {
                this.dialog.open(MessageDialogComponent, {
                  data: {
                    title: 'Sign-Off Failed',
                    text: (err?.Message ?? 'Sign-Off Failed') + '.',
                  },
                });
                return of();
              })
            )
            .subscribe();
        }
      });
  }

  print() {
    this.router.navigateByUrl(`reviews/print/${this.id}`);
  }
  back() {
    this.router.navigateByUrl(`plans`);
  }
  diary() {
    this.dialog.open(InterimReviewsComponent, { data: this.item$.getValue() });
  }

  private setScores(scores: Score[]) {
    this.scores = scores.map((score) => ({
      ...score,
      completed: this.scoresService.isComplete(score),
    }));

    this.isComplete$.next(this.scores.every((score) => score.completed));
  }
}
