<fieldset class="pms-fieldset form-group nc-field">
  <label>
    <span>Development Strategy</span>
    <small class="p-error" *ngIf="messages$ | async">
      *{{ messages$ | async }}</small
    >
  </label>
  <div class="input-group">
    <!-- <pre>{{ (selectedStrategy$ | async)?.Description }}</pre> -->
    <div
      class="form-control input-sm overflow-hidden !bg-neutral-100 !opacity-100"
    >
      {{ (selectedStrategy$ | async)?.Description }}
    </div>
    <input
      [hidden]="true"
      class="form-control input-sm"
      [formControl]="formControl!"
      [placeholder]=""
    />
    <div class="input-group-append">
      <button
        class="btn btn-outline-primary"
        type="button"
        (click)="showDialog()"
      >
        <i class="fa fa-list-alt"></i>
      </button>
    </div>
  </div>
</fieldset>
