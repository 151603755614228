<div class="modal-header">
  <h4 class="modal-title">Review Diary Item</h4>
</div>
<div class="modal-body">
  <pms-input-field
    label="Comment"
    ngDefaultControl
    [formControl]="$any(form.get('Comment'))"
    placeholder="e.g. everything seems to be on-track"
    [rows]="4"
  ></pms-input-field>

  <div class="alert alert-danger" role="alert">
    Note: Diary Items cannot be deleted. Please be sure your comments are
    correct.
  </div>
</div>
<!--modal-body-->

<div class="modal-footer">
  <button
    class="btn btn-primary"
    [disabled]="form.disabled || !form.valid"
    (click)="save()"
  >
    Save
  </button>

  <button class="btn btn-default" (click)="cancel()">Cancel</button>
</div>
