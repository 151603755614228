import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Kvp } from '@pmslogic/widgets';
import { map, switchMap } from 'rxjs';
import { Project } from './project.models';
import {
  AddProject,
  ProjectInfo,
  ProjectsState,
  RemoveProject,
  SetProjects,
  UpdateProject,
} from './projects.state';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  constructor(private http: HttpClient, private store: Store) {
    this.http
      .get<ProjectInfo[]>(`api/projects`)
      .subscribe((items) => this.store.dispatch(new SetProjects(items)));
  }

  list$() {
    return this.store.select(ProjectsState.items);
  }

  dictionary$(year: number) {
    return this.list$().pipe(
      map((items) =>
        (items ?? [])
          .filter((item) => item.Years.includes(year))
          .map((item) => {
            return { Key: item.Id, Value: item.Title } as Kvp;
          })
      )
    );
  }

  get$(id: number) {
    return this.http.get<Project>(`api/projects/${id}`);
  }

  add$(item: Partial<Project>) {
    return this.http
      .post<ProjectInfo>(`api/projects`, item)
      .pipe(
        switchMap((result) =>
          this.store.dispatch(new AddProject(result)).pipe(map(() => result))
        )
      );
  }
  update$(item: Partial<Project>) {
    return this.http
      .put<ProjectInfo>(`api/projects/${item.Id}`, item)
      .pipe(
        switchMap((result) =>
          this.store.dispatch(new UpdateProject(result)).pipe(map(() => result))
        )
      );
  }
  remove$(id: number) {
    return this.http
      .delete<void>(`api/projects/${id}`)
      .pipe(switchMap(() => this.store.dispatch(new RemoveProject(id))));
  }
}
