<div id="header-area">
  <pms-navbar (logout)="logout()"></pms-navbar>
</div>

<div id="body-area" *ngIf="(user$ | async) !== null">
  <div id="left-area">
    <pms-menu
      (changePassword)="changePassword()"
      (logout)="logout()"
    ></pms-menu>
  </div>

  <div id="right-area">
    <div id="notification-area"><pms-notifications></pms-notifications></div>

    <div id="content-area" class="nc-content">
      <div id="main-outlet"><router-outlet></router-outlet></div>
    </div>
  </div>
</div>

<div id="footer-area" class="text-[10px]">
  <div>{{ attribution }}</div>
</div>
