import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Kvp } from '@pmslogic/widgets';

import {
  IndicatorTypesState,
  SetIndicatorTypes,
} from './indicator-types.state';

@Injectable({
  providedIn: 'root',
})
export class IndicatorTypesService {
  constructor(private http: HttpClient, private store: Store) {
    this.http.get<Kvp[]>(`api/indicatortypes/dictionary`).subscribe((items) => {
      this.store.dispatch(new SetIndicatorTypes(items));
    });
  }

  dictionary$() {
    return this.store.select(IndicatorTypesState.items);
  }
}
