import { Dialog } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { ConfirmDialogComponent } from '../shared/confirm-dialog.component';
import { PageComponent } from '../shared/layout/page.component';
import { MessageDialogComponent } from '../shared/message-dialog.component';
import { DocsService } from './documents.service';
import { Doc } from './documents.state';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, PageComponent, ReactiveFormsModule],
  templateUrl: './documents.component.html',
})
export class DocumentsComponent {
  loading$ = new BehaviorSubject<boolean>(true);
  list$: Observable<Doc[]>;
  fileForm: FormGroup;
  file: File | null = null;

  constructor(private service: DocsService, private dialog: Dialog) {
    this.list$ = this.service.list$();
    this.list$.subscribe(() => {
      this.loading$.next(false);
    });
    this.service.load();
    this.fileForm = new FormGroup({
      file: new FormControl('', Validators.required),
    });
  }

  onFileSelected(event: Event) {
    const target = event?.target as HTMLInputElement;
    if (target.files == null) return;

    this.file = target.files[0];
  }
  upload() {
    if (!this.file) return;
    this.loading$.next(true);

    this.service.add$(this.file).subscribe(() => {
      this.loading$.next(false);
    });
  }

  download(id: string, name: string) {
    this.service.get$(id).subscribe((data) => {
      const blob = new Blob([data], { type: data.type });
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = name;
      a.click();
      window.URL.revokeObjectURL(a.href);
    });
  }

  remove(id: number) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          text: 'Are you sure you want to delete this Document?',
        },
      })
      .closed.subscribe((result) => {
        if (result as boolean) {
          this.service
            .remove$(id)
            .pipe(
              catchError(() => {
                this.dialog.open(MessageDialogComponent, {
                  data: {
                    title: 'Request Denied',
                    text: 'This record is in use and cannot be deleted.',
                  },
                });
                return throwError(() => new Error(`Delete failed [id=${id}]`));
              })
            )
            .subscribe();
        }
      });
  }
}
