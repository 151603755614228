import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Observable, take } from 'rxjs';

import {
  InputFieldComponent,
  SelectFieldComponent,
  ValidationService,
} from '@pmslogic/widgets';
import { Risk } from './risks.models';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputFieldComponent,
    SelectFieldComponent,
  ],
  providers: [ValidationService],
  templateUrl: './risk.component.html',
  styles: [
    `
      :host {
        display: block;
        width: 300px;
      }
    `,
  ],
})
export class RiskComponent implements OnInit {
  form: FormGroup;

  constructor(
    public dialogRef: DialogRef<Risk | null>,
    @Inject(DIALOG_DATA) private data$: Observable<Partial<Risk>>,
    validation: ValidationService
  ) {
    this.form = new FormGroup({
      Id: new FormControl(),
      IndicatorId: new FormControl(),
      Area: new FormControl('', [validation.required()]),
      Measures: new FormControl('', [validation.required()]),
    });

    this.form.disable();
  }
  ngOnInit(): void {
    this.data$.pipe(take(1)).subscribe((item) => {
      this.form.patchValue(item);
      this.form.enable();
    });
  }
  save() {
    this.dialogRef.close(this.form.value);
  }

  cancel() {
    this.dialogRef.close(null);
  }
}
