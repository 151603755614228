import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'finyear',
  standalone: true,
})
export class FinyearPipe implements PipeTransform {
  transform(year: number): string {
    return `${year - 1}/${year}`;
  }
}
