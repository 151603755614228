<fieldset *ngIf="!readonly" class="pms-fieldset form-group nc-field">
  <label class="w-100">
    <span> {{ label }}</span>
    <small class="p-error" *ngIf="errorMessage$ | async">
      *{{ errorMessage$ | async }}</small
    >

    <input
      *ngIf="!rows"
      [formControl]="formControl!"
      [type]="type"
      [placeholder]="placeholder"
      (blur)="onBlur()"
    />
    <textarea
      *ngIf="rows"
      [formControl]="formControl!"
      [placeholder]="placeholder"
      [rows]="rows"
      (blur)="onBlur()"
    ></textarea>
  </label>
</fieldset>

<div *ngIf="readonly">
  <div class="nc-field-label">
    {{ label }}
  </div>
  <div>
    <i>{{ formControl?.value ?? '---' }}</i>
  </div>
  <br />
</div>
