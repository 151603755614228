import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProgressComponent } from '../progress/progress.component';
import { ToolbarComponent } from './toolbar.component';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ToolbarComponent, ProgressComponent],
  selector: 'pms-page',
  template: `
    <div class="absolute h-full w-full flex flex-col">
      <div class="px-3 py-2">
        <pms-toolbar [title]="title" [subTitle]="subTitle" [icon]="icon">
          <ng-content select="[toolbar]"></ng-content>
        </pms-toolbar>
      </div>
      <div class="grow overflow-y-auto relative py-2 px-4">
        <pms-progress
          *ngIf="loading"
          class="block h-3 mt-48"
          [text]="'Loading'"
          [barWidth]="'60%'"
        ></pms-progress>
        <div *ngIf="!loading" class="h-full">
          <ng-content select="[body]"></ng-content>
        </div>
      </div>
    </div>
    <pre></pre>
  `,
})
export class PageComponent {
  @Input() title?: string;
  @Input() public subTitle?: string;
  @Input() public icon?: string;
  @Input() loading = true;
}
