export const PDP_ITEM_ATTACHMENTS_PATH = 'pdpitemattachments';

export interface PdpItem {
  Id: number;
  EmployeeId: number;
  EntryDate: string;
  SkillRequired: string;
  Outcome: string;
  SuggestedTraining: string;
  TimeFrame: string;
  WorkOpportunity: string;
  SupportPerson: string;
  Comment: string;
  TrainingType: string;
  ScheduledFor: string;
  Approved: boolean | null;
  NoOfFiles: number;
}
