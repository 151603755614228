import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { PlanInfo } from '../plans.service';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'pms-review-summary-bar',
  imports: [],
  template: `<div class="summary-status">
    <div class="done">{{ done }}</div>
    <div class="busy">{{ busy }}</div>
    <div class="notdone">{{ notdone }}</div>
  </div> `,
  styleUrls: ['./summary-bar.component.less'],
})
export class ReviewSummaryBarComponent implements OnInit {
  @Input() model: PlanInfo[] = [];
  @Input() qtr = 1;

  done?: number;
  busy?: number;
  notdone?: number;

  ngOnInit(): void {
    const reviews = this.model.map((m) => m.Reviews[this.qtr - 1]);
    this.done = reviews.filter((m) => m.Status === 'Reviewed').length;
    this.busy = reviews.filter((m) => m.Status === 'InProgress').length;
    this.notdone = reviews.filter((m) => m.Status === 'NotStarted').length;
  }
}
