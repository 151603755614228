import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PageComponent } from '../shared/layout/page.component';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, PageComponent],
  templateUrl: './videos.component.html',
  styles: [
    `
      iframe {
        background-color: #f2f2f2;
        margin-bottom: 30px;
      }
    `,
  ],
})
export class VideosComponent {
  width = 560;
  height = 315;
}
