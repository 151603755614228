import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Kvp } from '@pmslogic/widgets';

@Injectable({
  providedIn: 'root',
})
export class DictionariesService {
  constructor(private http: HttpClient) {}

  dictionary$(name: string) {
    return this.http.get<Kvp[]>(`api/${name}/dictionary`);
  }
}
