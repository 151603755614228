import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Kvp } from '@pmslogic/widgets';

import { CcrTypesState, SetCcrTypes } from './ccr-types.state';

@Injectable({
  providedIn: 'root',
})
export class CcrTypesService {
  constructor(private http: HttpClient, private store: Store) {
    this.http.get<Kvp[]>(`api/ccrtypes/dictionary`).subscribe((items) => {
      this.store.dispatch(new SetCcrTypes(items));
    });
  }

  dictionary$() {
    return this.store.select(CcrTypesState.items);
  }
}
