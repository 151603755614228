import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  of,
  switchMap,
} from 'rxjs';
import { ObjectivesService } from '../priorities/objectives/objectives.service';
import { Objective } from '../priorities/objectives/objectives.state';
import { PrioritiesService } from '../priorities/priorities.service';
import { Priority } from '../priorities/priorities.state';
import { StrategiesService } from '../priorities/strategies/strategies.service';
import { Strategy } from '../priorities/strategies/strategies.state';

@Component({
  selector: 'pms-strategy-picker-dialog',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './strategy-picker-dialog.component.html',
  styles: [
    `
      :host {
        display: block;
        width: 600px;
      }
    `,
  ],
})
export class StrategyPickerDialogComponent {
  title$: Observable<string>;
  priorities$: Observable<Priority[] | undefined>;
  objectives$: Observable<Objective[] | undefined>;
  strategies$: Observable<Strategy[] | undefined>;
  selectedPriority$ = new BehaviorSubject<number | null>(null);
  selectedObjective$ = new BehaviorSubject<number | null>(null);
  selectedStrategy$ = new BehaviorSubject<number | null>(null);
  currentStep$ = new BehaviorSubject(1);
  constructor(
    private prioritiesService: PrioritiesService,
    private objectivesService: ObjectivesService,
    private strategiesService: StrategiesService,
    public dialogRef: DialogRef<number | null>,
    @Inject(DIALOG_DATA) private data$: Observable<number[]>
  ) {
    this.priorities$ = prioritiesService.list$();

    this.objectives$ = combineLatest([
      this.selectedPriority$,
      objectivesService.list$(),
    ]).pipe(
      switchMap(([sel, obj]) => {
        if (sel === null) {
          return of([]);
        }
        return of(obj?.filter((f) => f.DevPriorityId == sel));
      })
    );

    this.strategies$ = combineLatest([
      this.selectedObjective$,
      strategiesService.list$(),
    ]).pipe(
      switchMap(([sel, strat]) => {
        if (sel === null) {
          return of([]);
        }
        return of(strat?.filter((f) => f.ObjectiveId == sel));
      })
    );

    this.title$ = this.currentStep$.pipe(
      switchMap((m) => {
        switch (m) {
          case 1:
            return of('Select a Development Priority');
          case 2:
            return of('Select a Development Objective');
          case 3:
            return of('Select a Development Strategy');
          default:
            return of('Select');
        }
      })
    );
  }

  step(step: number, id: number | null): void {
    this.currentStep$.next(step);
    if (step == 2) {
      this.selectedPriority$.next(id);
    }
    if (step == 3) {
      this.selectedObjective$.next(id);
    }
  }

  back() {
    if (this.currentStep$.value == 1) return;
    this.currentStep$.next(this.currentStep$.value - 1);
  }
  select(id: number) {
    this.dialogRef.close(id);
  }
  done() {
    this.dialogRef.close(null);
  }
}
