import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Risk } from './risks.models';

@Injectable({
  providedIn: 'root',
})
export class RisksService {
  constructor(private http: HttpClient) {}

  list$(indicatorId: number) {
    return this.http.get<Risk[]>(`api/risks?indicatorId=${indicatorId}`);
  }

  get$(id: number | string) {
    return this.http.get<Risk>(`api/risks/${id}`);
  }

  add$(item: Partial<Risk>) {
    return this.http.post<Risk>(`api/risks`, item);
  }

  update$(item: Partial<Risk>) {
    return this.http.put<Risk>(`api/risks/${item.Id}`, item);
  }

  remove$(id: number | string) {
    return this.http.delete<Risk>(`api/risks/${id}`);
  }
}
