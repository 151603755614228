import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import groupBy from 'lodash.groupby';
import {
  BehaviorSubject,
  Observable,
  distinctUntilChanged,
  filter,
  of,
  skip,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { DeptKvp, DeptsService } from '../depts/depts.service';
import { SettingsService } from '../settings/settings.service';
import { ConfirmDialogComponent } from '../shared/confirm-dialog.component';
import { PageComponent } from '../shared/layout/page.component';
import { LoadingService } from '../shared/loading/loading.service';
import { MessageDialogComponent } from '../shared/message-dialog.component';
import { Permissions } from '../user/user.models';
import { UserService } from '../user/user.service';
import { isNil } from '../util/common';
import { FinyearPipe } from '../util/pipes/finyear.pipe';
import { YearsService } from '../years/years.service';
import { SetCurrentYear, YearKvp } from '../years/years.state';
import { AssessmentDialogComponent } from './assessment-dialog/assessment-dialog.component';
import { PlanBarComponent } from './bars/plan-bar.component';
import { PlanSummaryBarComponent } from './bars/plan-summary-bar.component';
import { ReviewBarComponent } from './bars/review-bar.component';
import { ReviewSummaryBarComponent } from './bars/review-summary-bar.component';
import { PlanInfo, PlansService, Review } from './plans.service';
import { ReviewService } from './review/review.service';

@Component({
  standalone: true,
  //changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PageComponent,
    FinyearPipe,
    PlanSummaryBarComponent,
    ReviewSummaryBarComponent,
    PlanBarComponent,
    ReviewBarComponent,
    DialogModule,
  ],
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.less'],
})
export class PlansComponent {
  filterForm = new FormGroup({
    dept: new FormControl(),
    year: new FormControl(),
  });
  finyearTitle = '';
  loading$ = this.loadingService.isLoading$(this);
  depts$: Observable<DeptKvp[]>;
  years$: Observable<YearKvp[]>;
  planSections$ = new BehaviorSubject<{ key: string; items: PlanInfo[] }[]>([]);
  permissions$: Observable<Permissions>;

  constructor(
    private store: Store,
    private router: Router,
    private plansService: PlansService,
    private reviewService: ReviewService,
    private dialog: Dialog,
    private userService: UserService,
    private deptsService: DeptsService,
    private yearsService: YearsService,
    private settingsService: SettingsService,
    private loadingService: LoadingService
  ) {
    this.loadingService.startLoading(this);
    this.filterForm.disable();
    //this.gotoPlan(2424);

    const valueChanged$ = this.filterForm.valueChanges.pipe(
      filter((values) => !isNil(values.dept) && !isNil(values.year)),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );

    // first load
    valueChanged$.pipe(take(1)).subscribe((values) => {
      this.fetch();
    });

    valueChanged$.pipe(skip(1)).subscribe(() => this.fetch());

    this.loading$.subscribe((loading) => {
      if (loading) {
        this.filterForm.disable();
      } else {
        this.filterForm.enable();
      }
    });

    this.depts$ = this.deptsService.dictionary$().pipe(
      switchMap((depts) => of([{ Key: 0, Value: 'All' }, ...depts])),
      tap((depts) => this.filterForm.patchValue({ dept: depts[0].Key }))
    );

    this.depts$.subscribe(); //If the Depts dropdown is hidden, there is no async trigger.

    this.years$ = this.yearsService.getYears$();

    this.yearsService
      .getCurrentYear$()
      .subscribe((year) => this.filterForm.patchValue({ year: year }));

    this.permissions$ = this.userService.getPermissions$();
  }

  groupByDept(plans: PlanInfo[]): { key: string; items: PlanInfo[] }[] {
    return Object.entries(groupBy(plans, 'Dept')).map(([key, items]) => {
      return { key, items };
    });
  }

  fetch() {
    this.loadingService.startLoading(this);
    const values = this.filterForm.value;
    this.finyearTitle = FinyearPipe.prototype.transform(values.year);
    this.plansService.list$(values.year, values.dept).subscribe((plans) => {
      this.store.dispatch(new SetCurrentYear(values.year));
      this.planSections$.next(
        Object.entries(groupBy(plans, 'Section.Title')).map(([key, items]) => {
          return { key, items };
        })
      );
      this.loadingService.stopLoading(this);
    });
  }

  navigate(item: any) {
    this.router.navigateByUrl(item.route);
  }

  gotoPlan(id: number) {
    this.router.navigateByUrl(`plans/${id}`);
  }

  resetPlan(id: number) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          text: 'Are you sure you want to reset this plan? NOTE: This process cannot be undone and will require the employee and their manager to resign the plan.',
        },
      })
      .closed.subscribe((result) => {
        if (result as boolean) {
          this.plansService.resetPlan$(id).subscribe((_) => {
            this.router.navigateByUrl(`plans/${id}`);
          });
        }
      });
  }

  gotoReview(review: Review) {
    if (review.Status === 'AwaitingPlan') {
      this.dialog.open(MessageDialogComponent, {
        data: {
          title: 'Cannot Proceed',
          text: 'Reviews can only be performed after the Plan has been signed-off.',
        },
      });
    } else {
      this.router.navigateByUrl(`reviews/${review.Id}`);
    }
  }

  showAssessment(review: Review) {
    this.dialog.open(AssessmentDialogComponent, {
      data: review.Id,
    });
  }

  resetReview(review: Review) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          text: 'Are you sure you want to reset this review? NOTE: This process cannot be undone and will require the employee and their manager to redo the review.',
        },
      })
      .closed.subscribe((result) => {
        if (result as boolean) {
          this.reviewService.reset$(review.Id).subscribe((_) => {
            this.router.navigateByUrl(`reviews/${review.Id}`);
          });
        }
      });
  }
}
