import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Permissions } from '../../user/user.models';
import { Review } from '../plans.service';
import { BarComponent } from './bar.component';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'pms-review-bar',
  imports: [CommonModule, BarComponent],
  template: `<pms-bar
    (clicked)="reviewClicked.emit()"
    [color]="color"
    [label]="label"
    [perc]="perc"
    [icon]="icon"
  >
    <div
      *ngIf="isReady"
      (click)="reviewClicked.emit()"
      class="nc-badge badge badge-primary"
    >
      ready!
    </div>

    <div
      *ngIf="canReset()"
      (click)="resetClicked.emit()"
      class="nc-badge badge badge-light"
    >
      reset
    </div>

    <div
      *ngIf="hasScore"
      (click)="assessmentClicked.emit()"
      class="nc-badge badge badge-success float-right"
    >
      {{ (model?.TotalScore ?? 0) * 100 | number : '1.1-1' }}%
    </div>
  </pms-bar> `,
  styleUrls: ['./bar.component.less'],
})
export class ReviewBarComponent implements OnInit {
  @Input() model?: Review;
  @Input() permissions?: Permissions;
  @Output() reviewClicked = new EventEmitter<void>();
  @Output() resetClicked = new EventEmitter<void>();
  @Output() assessmentClicked = new EventEmitter<void>();

  label?: string;
  icon?: string;
  perc?: number;
  color?: string;
  isReady = false;
  hasScore = false;

  ngOnInit(): void {
    const mdl = this.model;

    let perc = 50;

    if (mdl && mdl.NoOfScores !== 0)
      perc = (mdl.NoOfScoresCompleted / mdl.NoOfScores) * 100;

    switch (mdl?.Status) {
      case 'NotOpen':
        this.label = 'not open';
        this.icon = 'times-circle';
        this.color = 'progress-0';
        break;

      case 'Reviewed':
        this.label = 'reviewed';
        this.icon = 'print';
        this.perc = 100;
        this.color = 'progress-100';
        break;

      case 'ReadyToSign':
        this.label = 'ready to sign';
        this.icon = 'edit';
        this.perc = 80;
        this.isReady = true;
        this.color = 'progress-67';
        break;

      case 'InProgress':
        this.label = `${mdl.NoOfScoresCompleted} of ${mdl.NoOfScores} completed`;
        this.icon = 'edit';
        this.perc = perc - 15;
        this.color = 'progress-50';
        break;

      case 'NotStarted':
        this.label = 'not started';
        this.icon = 'edit';
        this.perc = 0;
        this.color = 'progress-0';
        break;

      case 'Signing':
        this.label = 'Signing';
        this.icon = 'pen-nib';
        this.perc = 85;
        this.color = 'progress-83';
        break;

      case 'AwaitingPlan':
        this.label = 'awaiting plan';
        this.icon = 'times-circle';
        this.perc = 0;
        this.color = 'progress-0';
        break;

      default:
    }

    this.hasScore = mdl?.Status === 'Reviewed';
  }

  canReset() {
    return (
      this.permissions?.CanResetPlans &&
      (this.model?.Status === 'Reviewed' || this.model?.Status === 'Signing')
    );
  }
}
