import { DIALOG_DATA, Dialog, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  InputFieldComponent,
  Kvp,
  SelectFieldComponent,
  ValidationService,
} from '@pmslogic/widgets';
import {
  Observable,
  Subject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import { MilestonesService } from '../../projects/milestones/milestones.service';
import { Milestone } from '../../projects/milestones/milestones.state';
import { Project } from '../../projects/project.models';
import { ProjectsService } from '../../projects/projects.service';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog.component';
import { LoadingService } from '../../shared/loading/loading.service';
import { ProgressComponent } from '../../shared/progress/progress.component';
import { OrdinalPipe } from '../../util/pipes/ordinal.pipe';
import { Plan } from '../plans.service';
import { Indicator, IndicatorDialogData } from './indicator.models';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './indicator.component.html',
  styles: [
    `
      :host {
        display: block;
        min-width: 900px !important ;
        width: 900px;
      }
    `,
  ],
  providers: [ValidationService],
  imports: [
    CommonModule,
    FormsModule,
    InputFieldComponent,
    OrdinalPipe,
    ProgressComponent,
    ReactiveFormsModule,
    SelectFieldComponent,
  ],
})
export class IndicatorComponent implements OnDestroy {
  title = '';
  loading$ = this.loadingService.isLoading$(this);
  swallow = true;
  plan!: Plan;
  ccrs!: Kvp[];
  kpas!: Kvp[];
  strategies!: Kvp[];
  projects!: Kvp[];
  indicator!: Indicator;
  prevProjectId: number | null = null;
  form?: FormGroup | null = null;
  private destroy$ = new Subject<void>();

  constructor(
    @Inject(DIALOG_DATA)
    public data$: Observable<IndicatorDialogData>,
    public dialogRef: DialogRef<IndicatorDialogData | null>,
    private dialog: Dialog,
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private projectsService: ProjectsService,
    private milestonesService: MilestonesService,
    private validation: ValidationService
  ) {
    this.loadingService.startLoading(this);

    this.data$
      .pipe(
        take(1),
        finalize(() => {
          this.loadingService.stopLoading(this);
        })
      )
      .subscribe((data) => {
        this.plan = data.plan;
        this.title = this.getTitle(data.indicator);
        this.indicator = data.indicator;
        this.ccrs = data.ccrs;
        this.kpas = data.kpas;
        this.projects = data.projects;
        this.strategies = data.strategies;

        this.initForm(data.indicator);
        this.setFormData(data.indicator);
      });
  }

  initForm(indicator: Indicator) {
    const type = indicator.IndicatorType;

    this.form = this.fb.group({
      Id: [],
      IndicatorType: [type],
      PlanId: [indicator.PlanId],
      ProjectId: ['', [this.validation.requiredIf(() => type === 'IDP')]],
      KPAId: ['', [this.validation.requiredIf(() => type !== 'CCR')]],
      CCRId: ['', [this.validation.requiredIf(() => type === 'CCR')]],
      DevStrategyId: [''],
      Name: ['', [this.validation.requiredIf(() => type !== 'CCR')]],
      TargetAnnual: ['', [this.validation.required()]],
      Proof: ['', [this.validation.required()]],
      Weight: [0.1, [this.validation.required()]],
      ScoreList: this.fb.array([
        this.fb.group({
          Id: null,
          QuarterNo: 1,
          Target: ['', [this.validation.required()]],
          ReviewId: null,
        }),
        this.fb.group({
          Id: null,
          QuarterNo: 2,
          Target: ['', [this.validation.required()]],
          ReviewId: null,
        }),
        this.fb.group({
          Id: null,
          QuarterNo: 3,
          Target: ['', [this.validation.required()]],
          ReviewId: null,
        }),
        this.fb.group({
          Id: null,
          QuarterNo: 4,
          Target: ['', [this.validation.required()]],
          ReviewId: null,
        }),
      ]),
    });

    const control = this.form?.get('ProjectId');
    if (!control) return;

    control.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(200),
        filter((f) => f != null && f != '' && f != this.prevProjectId),
        distinctUntilChanged(),
        switchMap((projectId) => {
          return combineLatest([
            this.projectsService.get$(projectId),
            this.milestonesService.list$().pipe(
              map((milestones) => {
                return milestones.filter((f) => f.ProjectId == projectId);
              })
            ),
          ]);
        }),
        map(([project, milestones]) => {
          this.changeProject(project, milestones);
        })
      )
      .subscribe();

    this.enableDisableControls(indicator.IndicatorType);
  }

  changeProject(project: Project, milestones: Milestone[]) {
    if (this.swallow) {
      this.prevProjectId = project.Id;
      const indicator = this.mapIndicator(project, milestones);
      this.setFormData(indicator);
      this.swallow = false;
      return;
    }
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: 'Please Confirm',
          text: 'Changing the project will change other properties of this indicator. Are you sure you want to continue?',
        },
      })
      .closed.subscribe((result) => {
        if (result as boolean) {
          this.prevProjectId = project.Id;
          const indicator = this.mapIndicator(project, milestones);
          this.setFormData(indicator);
        } else {
          this.form?.get('ProjectId')?.setValue(this.prevProjectId);
        }
      });
  }

  formatWeight(event: any) {
    const value = this.form?.get('Weight')?.value;
    if (value !== null && value !== '') {
      this.form?.get('Weight')?.setValue(parseFloat(value).toFixed(3));
    }
  }

  save() {
    const result = this.form?.getRawValue();
    result.Weight = result.Weight / 100;
    this.dialogRef.close(result);
  }

  cancel() {
    this.dialogRef.close(null);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  //
  get scoreListForms(): FormArray {
    return this.form?.get('ScoreList') as FormArray;
  }

  private setFormData(data: Indicator) {
    this.form?.patchValue(data);
    this.form?.controls['Weight'].setValue(data.Weight * 100);
  }

  private getTitle(indicator: Indicator) {
    switch (indicator?.IndicatorType) {
      case 'IDP':
        return 'IDP Project Indicator Details';
      case 'OP':
        return 'IDP Operational Indicator Details';
      case 'CCR':
        return 'Core Competency Requirement Details';
      default:
        return '';
    }
  }

  private mapIndicator(project: Project, milestones: Milestone[]): Indicator {
    const indicator = this.indicator;

    indicator.ProjectId = project.Id;

    indicator.KPAId = project.KPAId;
    indicator.DevStrategyId = project.DevStrategyId;
    indicator.Name = project.Description;
    indicator.Proof = project.Verification;
    indicator.TargetAnnual = project.OutputMeasure;

    indicator.ScoreList[0].Target = this.lookup(milestones, 1);
    indicator.ScoreList[1].Target = this.lookup(milestones, 2);
    indicator.ScoreList[2].Target = this.lookup(milestones, 3);
    indicator.ScoreList[3].Target = this.lookup(milestones, 4);

    return indicator;
  }

  private lookup(milestones: Milestone[], qtr: number): string {
    const ms = milestones.find(
      (m) => m.Year == this.plan.PlanYear && m.QtrNo == qtr
    );
    if (ms) return ms.Description;
    else return `(no project milestone for quarter ${qtr})`;
  }

  private enableDisableControls(type: string) {
    if (!this.form) return;
    if (type === 'IDP') {
      this.form.get('ProjectId')?.enable();
      this.form.get('KPAId')?.disable();
      this.form.get('CCRId')?.disable();
      this.form.get('DevStrategyId')?.disable();
    } else if (type === 'CCR') {
      this.form.get('ProjectId')?.disable();
      this.form.get('KPAId')?.disable();
      this.form.get('CCRId')?.enable();
      this.form.get('DevStrategyId')?.disable();
    } else {
      this.form.get('ProjectId')?.disable();
      this.form.get('KPAId')?.enable();
      this.form.get('CCRId')?.disable();
      this.form.get('DevStrategyId')?.enable();
    }
  }
}
