<div class="review-stars" title="Hover over stars to see description of score">
  <div class="score">{{ value }}</div>

  <div>
    <div
      *ngFor="let star of stars; index as i"
      (click)="rate(i + 1)"
      class="star-holder"
      [class.isEditable]="!disabled"
      [title]="i + 1 | rating"
    >
      <span
        class="number"
        *ngIf="(showNumbers$ | async) === true"
        [class.checked]="checked(i + 1)"
        >{{ i + 1 }}</span
      >
      <span class="star fa fa-star" [class.checked]="checked(i + 1)"></span>
    </div>
  </div>
</div>
