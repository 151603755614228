import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { isDefined } from '../util/common';

// --- Model ---
export interface Employee {
  Id: number;
  FullName: string;
  UserName: string;
  Manager: string;
  JobTitle: string;
  Dept: string;
  IsCurrent: boolean;
}
export interface EmployeeKvp {
  Key: number;
  Value: string;
}
// --- Actions ---
export class SetEmployees {
  static readonly type = '[Employees] SetEmployees';
  constructor(public items: Employee[]) {}
}

export class AddEmployee {
  static readonly type = '[Employees] AddEmployee';
  constructor(public item: Employee) {}
}

export class UpdateEmployee {
  static readonly type = '[Employees] UpdateEmployee';
  constructor(public item: Employee) {}
}
export class UpdateIsCurrentEmployee {
  static readonly type = '[Employees] UpdateIsCurrentEmployee';
  constructor(public id: number, public isCurrent: boolean) {}
}
export class RemoveEmployee {
  static readonly type = '[Employees] RemoveEmployee';
  constructor(public id: number) {}
}
// --- State ---
export interface EmployeesStateModel {
  items?: Employee[];
}

@State<EmployeesStateModel>({
  name: 'EmployeesState',
})
@Injectable()
export class EmployeesState {
  @Selector()
  static items(state: EmployeesStateModel) {
    return state.items;
  }

  @Action(SetEmployees)
  set(ctx: StateContext<EmployeesStateModel>, { items }: SetEmployees) {
    ctx.patchState({
      items: items,
    });
  }

  @Action(AddEmployee)
  add(ctx: StateContext<EmployeesStateModel>, { item }: AddEmployee) {
    ctx.setState(
      patch({
        items: append([item]),
      })
    );
  }

  @Action(UpdateEmployee)
  update(ctx: StateContext<EmployeesStateModel>, { item }: UpdateEmployee) {
    ctx.setState(
      patch({
        items: updateItem<Employee>((itm) => itm.Id == item.Id, item),
      })
    );
  }
  @Action(UpdateIsCurrentEmployee)
  updateIsCurrent(
    ctx: StateContext<EmployeesStateModel>,
    { id, isCurrent }: UpdateIsCurrentEmployee
  ) {
    const state = ctx.getState();
    if (!isDefined(state.items)) return;
    const idx = state.items.findIndex((itm) => itm.Id === id);
    const updatedItem = { ...state.items[idx], IsCurrent: isCurrent };

    ctx.setState(
      patch({
        items: updateItem<Employee>((itm) => itm.Id == id, updatedItem),
      })
    );
  }
  @Action(RemoveEmployee)
  remove(ctx: StateContext<EmployeesStateModel>, { id }: RemoveEmployee) {
    ctx.setState(
      patch({
        items: removeItem<Employee>((itm) => itm.Id == id),
      })
    );
  }
}
