import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  of,
  switchMap,
} from 'rxjs';
import { ConfirmDialogComponent } from '../shared/confirm-dialog.component';
import { PageComponent } from '../shared/layout/page.component';
import { NumberControlDirective } from '../shared/number-control.directive';
import { UserService } from '../user/user.service';
import { SettingsService } from './settings.service';
import { Settings } from './settings.state';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PageComponent,
    NumberControlDirective,
    DialogModule,
  ],
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.less'],
})
export class SettingsComponent {
  form!: FormGroup;
  alertText = '';
  showAlert = false;
  loading$ = new BehaviorSubject<boolean>(true);
  canImportPlans$: Observable<boolean>;

  constructor(
    userService: UserService,
    private settingsService: SettingsService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: Dialog
  ) {
    this.setupForm();

    const settings$ = settingsService.getSettings$();
    this.canImportPlans$ = userService
      .getPermissions$()
      .pipe(
        switchMap((permissions) =>
          of(permissions.CanImportPlansFromPreviousYear)
        )
      );

    settings$.subscribe((data) => this.copyDataToForm(data));

    combineLatest([settings$, this.canImportPlans$]).subscribe(() =>
      this.loading$.next(false)
    );
  }

  private setupForm() {
    this.form = new FormGroup({
      CurrentFinYearEnd: new FormControl(0, [
        Validators.required,
        Validators.min(2010),
      ]),
      CurrentQuarter: new FormControl(0, [
        Validators.required,
        Validators.min(1),
        Validators.max(4),
      ]),
      AllowCurrentFinYearPlanEdit: new FormControl(),
      AllowNextFinYearPlanEdit: new FormControl(),
      QuickFilterPlans: new FormControl(),
      AddNumbersToReviewStars: new FormControl(),
      UseIDPIndicators: new FormControl()
    });
  }

  private copyDataToForm(data: Settings): void {
    return this.form.patchValue({
      CurrentFinYearEnd: data.CurrentFinYearEnd,
      CurrentQuarter: data.CurrentQuarter,
      AllowCurrentFinYearPlanEdit: data.AllowCurrentFinYearPlanEdit,
      AllowNextFinYearPlanEdit: data.AllowNextFinYearPlanEdit,
      QuickFilterPlans: data.QuickFilterPlans,
      AddNumbersToReviewStars: data.AddNumbersToReviewStars,
      UseIDPIndicators: data.UseIDPIndicators,
    });
  }

  check(
    control: 'CurrentFinYearEnd' | 'CurrentQuarter',
    error: string
  ): boolean {
    return this.form.controls[control].errors?.[error];
  }

  importPlansFromPreviousYear() {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          text: 'Are you sure you want to proceed? This operation will populate ALL empty plans with the operational indicators and core competency requirements from the previous year. This process is not reversable.',
        },
      })
      .closed.subscribe((result) => {
        if (result as boolean) {
          this.loading$.next(true);
          this.settingsService.importPlansFromPreviousYear$().subscribe(
            (data) => {
              this.loading$.next(false);
              this.alert('Plans imported successfully.');
            },
            (error) => {
              this.alert('Plans failed to import.');
              this.loading$.next(false);
            }
          );
        }
      });
  }

  save() {
    this.loading$.next(true);
    console.log('Form value', this.form.value);
    this.settingsService.saveSettings$(this.form.value).subscribe((data) => {
      console.log('Data returned', data);
      this.alert('Settings were saved successfully.');
      this.loading$.next(false);
    });
  }

  alert(text: string, timeout = 2000) {
    this.alertText = text;
    this.showAlert = true;
    if (timeout !== 0) {
      setTimeout(() => {
        this.showAlert = false;
        this.changeDetectorRef.detectChanges();
      }, timeout);
    }
  }
}
