import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UserService } from '../../user/user.service';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'pms-notifications',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div style="padding-top: 10px; padding-right: 10px">
      <div
        class="alert alert-warning alert-dismissible fade show"
        role="alert"
        *ngFor="let notification of notifications$ | async"
      >
        {{ notification.message }}
        @if(notification.dismissable){
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        }
      </div>
    </div>
  `,
})
export class NotificationsComponent {
  notifications$ = this.userService.getNotifications$();

  constructor(private userService: UserService) {}
}
