import { DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { isNil } from 'lodash';
import {
  EMPTY,
  Observable,
  Subscription,
  filter,
  finalize,
  map,
  startWith,
  switchMap,
  take,
} from 'rxjs';
import { PageComponent } from '../shared/layout/page.component';
import { LoadingService } from '../shared/loading/loading.service';
import { isDefined } from '../util/common';
import { YearsService } from '../years/years.service';
import { PlanlessService } from './planless.service';
import { Planless } from './planless.state';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, PageComponent, DialogModule, ReactiveFormsModule],
  templateUrl: './planless.component.html',
})
export class PlanlessComponent implements OnDestroy {
  loading$ = this.loadingService.isLoading$(this);
  list$: Observable<Planless[]> = EMPTY;
  years$ = this.yearsService.getYears$();
  filterForm = new FormGroup({
    year: new FormControl(),
  });
  yearsSub: Subscription;
  loadingSub: Subscription;
  constructor(
    private service: PlanlessService,
    private yearsService: YearsService,
    private loadingService: LoadingService
  ) {
    this.loadingService.startLoading(this);

    this.filterForm.disable();

    this.yearsSub = this.yearsService.getCurrentYear$().subscribe((year) => {
      this.filterForm.patchValue({ year: year }, { emitEvent: true });
    });

    this.list$ = this.filterForm.valueChanges.pipe(
      startWith(this.filterForm.value),
      filter((values) => !isNil(values.year)),
      switchMap((m) => {
        return this.service.list$().pipe(
          map((items) => {
            if (!isDefined(items)) return [];
            return items.filter((itm) => {
              return m.year == 0 || itm.Years.includes(m.year);
            });
          })
        );
      })
    );

    this.service
      .list$()
      .pipe(
        filter((f) => isDefined(f)),
        take(1),
        finalize(() => this.loadingService.stopLoading(this))
      )
      .subscribe();

    this.loadingSub = this.loading$.subscribe((loading) => {
      if (loading) {
        this.filterForm.disable({ emitEvent: false });
      } else {
        this.filterForm.enable({ emitEvent: false });
      }
    });
  }
  ngOnDestroy() {
    this.yearsSub.unsubscribe();
    this.loadingSub.unsubscribe();
  }
}
